import {
    loadEmailTemplateVariables,
    loadLocales,
} from "api/deliverables/email-templates-api";
import ModalContainer from "components/Common/Modal/ModalContainer";
import constants from "utils/constants";
import React, { useEffect, useRef, useState } from "react";
import { getErrorMessage } from "api/defaults";
import {
    IEmailTemplate,
    IEmailTemplateDetails,
    IEmailTemplateVariable,
} from "redux/deliverables/email-templates/email-templates-types";
import {
    Button,
    ButtonToolbar,
    Form,
    FormInstance,
    InputPicker,
    Message,
    Schema,
    useToaster,
} from "rsuite";
import { useTranslation } from "react-i18next";
import { setErrorResponseMessage } from "redux/common/initialization/initialization-actions";
import css from "./ManageEmailTemplate.module.css";
import { template } from "lodash";
import Textarea from "components/Common/Textarea/Textarea";

interface ComponentProps {
    isOpened: boolean;
    templateDetails: IEmailTemplateDetails;
    existingTemplates: IEmailTemplate[];
    refreshTemplates: () => void;
    setManageTemplateOpened: (isOpened: boolean) => void;
    setModalLoaderVisibility: (visible: boolean) => void;
}

const ManageEmailTemplate: React.FC<ComponentProps> = ({
    isOpened,
    templateDetails,
    existingTemplates,
    refreshTemplates,
    setManageTemplateOpened,
    setModalLoaderVisibility,
}: ComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate>(
        templateDetails.emailTemplate
    );
    const [templateVariables, setTemplateVariables] =
        useState<IEmailTemplateVariable[]>();
    const [locales, setLocales] = useState<string[]>([]);
    const [isMetadataFetched, setMetadataFetched] = useState<boolean>(false);

    const fetchMetadata = () => {
        setModalLoaderVisibility(true);
        Promise.all([
            loadEmailTemplateVariables().then(([result, status]) => {
                if (result && status === constants.statusCode.OK) {
                    setTemplateVariables(result);
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(result), status)
                    );
                }
            }),
            loadLocales().then(([result, status]) => {
                if (result && status === constants.statusCode.OK) {
                    setLocales(result);
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(result), status)
                    );
                }
            }),
        ]).then(() => {
            setModalLoaderVisibility(false);
            setMetadataFetched(true);
        });
    };

    const handleSubmit = () => {
        if (!formRef.current?.check()) {
            return;
        }
        setModalLoaderVisibility(true);
        templateDetails.apiMethod(emailTemplate).then(([result, status]) => {
            if (status === constants.statusCode.NoContent) {
                handleModalClose();
                refreshTemplates();
                toaster.push(
                    <Message type="success" showIcon>
                        {template(
                            t(
                                "deliverables.emailTemplates.creationSuccessMessage"
                            )
                        )({
                            templateName: emailTemplate.name,
                            actionName: templateDetails.actionName,
                        })}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            } else {
                const errorMessage = getErrorMessage(String(result), status);
                toaster.push(
                    <Message type="error" showIcon>
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            }

            setModalLoaderVisibility(false);
        });
    };

    const handleModalClose = () => {
        setEmailTemplate(templateDetails.emailTemplate);
        setManageTemplateOpened(false);
        setMetadataFetched(false);
    };

    const handleFormChange = (formValue: IEmailTemplate) => {
        setEmailTemplate(formValue);
    };

    useEffect(() => {
        if (!isMetadataFetched && isOpened) {
            fetchMetadata();
        }
    }, [isMetadataFetched, isOpened]);

    useEffect(() => {
        setEmailTemplate(templateDetails.emailTemplate);
    }, [templateDetails.emailTemplate]);

    const variables =
        templateVariables &&
        templateVariables.map((item, index) => {
            return (
                <div key={index} className={css.variables}>
                    <p className={css.variableLabel}>{item.name}</p>
                    <Form.HelpText tooltip className={css.help}>
                        {item.description}
                    </Form.HelpText>
                </div>
            );
        });

    const header = <h1>{templateDetails.title}</h1>;
    const footer = (
        <ButtonToolbar className="custom-modal__button-toolbar">
            <Button
                type="submit"
                appearance="primary"
                onClick={handleSubmit}
                disabled={!isMetadataFetched}
            >
                {templateDetails.confirmButtonName}
            </Button>
            <Button
                appearance="ghost"
                onClick={handleModalClose}
                disabled={!isMetadataFetched}
            >
                {t("deliverables.emailTemplates.modal.cancel")}
            </Button>
        </ButtonToolbar>
    );

    const templatesLocalesData = locales.map((item) => {
        return { value: item, label: item };
    });

    const model = Schema.Model({
        name: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            )
            .maxLength(
                constants.maxLengthLimit,
                t("generalValidationMessages.maxLength5000")
            )
            .addRule((value) => {
                return existingTemplates
                    .filter((template) => template.id !== emailTemplate.id)
                    .every((template) => template.name !== value);
            }, t("generalValidationMessages.isUnique")),
        locale: Schema.Types.StringType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
        subject: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            ),
        body: Schema.Types.StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            ),
    });

    return (
        <ModalContainer
            className={css.modal}
            backdrop="static"
            open={isOpened}
            onClose={handleModalClose}
            header={header}
            footer={footer}
        >
            <>
                <div className={css.modalContainer}>
                    <div className={css.formSection}>
                        <Form
                            className={css.form}
                            ref={formRef}
                            model={model}
                            formValue={emailTemplate}
                            onChange={(data: any) => {
                                handleFormChange(data);
                            }}
                        >
                            <Form.Group className={css.formGroup}>
                                <Form.ControlLabel>
                                    {t(
                                        "deliverables.emailTemplates.modal.nameTitle"
                                    )}
                                </Form.ControlLabel>
                                <Form.Control
                                    placeholder={t(
                                        "deliverables.emailTemplates.modal.namePlaceholder"
                                    )}
                                    name={"name"}
                                />
                            </Form.Group>
                            <Form.Group className={css.formGroup}>
                                <Form.ControlLabel>
                                    {t(
                                        "deliverables.emailTemplates.modal.localeTitle"
                                    )}
                                </Form.ControlLabel>
                                <Form.Control
                                    accepter={InputPicker}
                                    data={templatesLocalesData}
                                    placeholder={t(
                                        "deliverables.emailTemplates.modal.localePlaceholder"
                                    )}
                                    name={"locale"}
                                />
                            </Form.Group>
                            <Form.Group className={css.formGroup}>
                                <Form.ControlLabel>
                                    {t(
                                        "deliverables.emailTemplates.modal.subjectTitle"
                                    )}
                                </Form.ControlLabel>
                                <Form.Control
                                    placeholder={t(
                                        "deliverables.emailTemplates.modal.subjectPlaceholder"
                                    )}
                                    name={"subject"}
                                />
                            </Form.Group>
                            <Form.Group className={css.formGroup}>
                                <Form.ControlLabel>
                                    {t(
                                        "deliverables.emailTemplates.modal.bodyTitle"
                                    )}
                                </Form.ControlLabel>
                                <Form.Control
                                    accepter={Textarea}
                                    name={"body"}
                                    rows={10}
                                    placeholder={t(
                                        "deliverables.emailTemplates.modal.bodyPlaceholder"
                                    )}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    <div className={css.helpSection}>
                        <div className={css.variablesHeader}>
                            {t(
                                "deliverables.emailTemplates.modal.variablesHeader"
                            )}
                        </div>
                        {variables}
                    </div>
                </div>
            </>
        </ModalContainer>
    );
};

export default ManageEmailTemplate;
