import React from "react";
import css from "./AppHeader.module.css";
import {
    Popover,
    Whisper,
    Button,
    FlexboxGrid,
    Tooltip,
    Message,
    toaster,
} from "rsuite";
import { useTranslation } from "react-i18next";
import Logo from "assets/images/logo.svg";
import { ReactComponent as Account } from "assets/images/account.svg";
import RefreshButtonContainer from "./RefreshButton/RefreshButtonContainer";
import constants from "utils/constants";
import { useMsal } from "@azure/msal-react";

import "./styles.less";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import { v4 as uuid } from "uuid";
import TopMenuContainer from "./TopMenu/TopMenuContainer";
import cn from "classnames";
import { logout } from "api/authentication";
import { getErrorMessage } from "api/defaults";

interface ComponentProps {
    userName: string;
    modalTitle: string;
    modalMessage: string;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}
const AppHeader: React.FC<ComponentProps> = ({
    modalTitle,
    modalMessage,
    setModalConfirmCallback,
    setRefreshActivated,
}: ComponentProps) => {
    const { t } = useTranslation();
    const { instance: msalInstance } = useMsal();
    const appVersion =
        typeof process.env.REACT_APP_MAJOR === "undefined" ||
        typeof process.env.REACT_APP_MINOR === "undefined" ||
        typeof process.env.REACT_APP_PATCH === "undefined" ||
        typeof process.env.REACT_APP_BUILD_NUMBER === "undefined"
            ? constants.appVersionDefault
            : `v.${process.env.REACT_APP_MAJOR}.${process.env.REACT_APP_MINOR}.${process.env.REACT_APP_PATCH}.${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_ENV_NAME})`;
    const branchName =
        typeof process.env.REACT_APP_BRANCH_NAME === "undefined"
            ? ""
            : `${process.env.REACT_APP_BRANCH_NAME}`;
    const logoutPopover = (
        <Popover title={modalTitle} className="LogoutPopover">
            <p>{modalMessage}</p>
            <div className="LogoutPopoverFooter">
                <Button
                    onClick={() => {
                        setModalConfirmCallback({
                            id: uuid(),
                            isAutoDeleteResistant: false,
                            isPageRedirect: true,
                            callback: () => {
                                // Clear "onbeforeunload" event handler to prevent showing the reload
                                // confirmation popup configured in "useBeforeUnload" hook.
                                onbeforeunload = null;
                                logout().then(([payload, status]) => {
                                    if (
                                        status !==
                                        constants.statusCode.NoContent
                                    ) {
                                        const errorMessage = getErrorMessage(
                                            String(payload),
                                            status
                                        );
                                        toaster.push(
                                            <Message
                                                showIcon
                                                type="info"
                                                closable
                                            >
                                                {errorMessage}
                                            </Message>,
                                            {
                                                duration:
                                                    constants.alertDurationMsec,
                                            }
                                        );
                                    }
                                    msalInstance.logout();
                                });
                            },
                        });
                        setRefreshActivated(true);
                    }}
                    appearance="primary"
                >
                    {t("controlAppHeader.profilePopup.logoutBtn")}
                </Button>
            </div>
        </Popover>
    );

    return (
        <>
            <div className={css.container}>
                <FlexboxGrid align={"middle"}>
                    <FlexboxGrid.Item>
                        <img src={Logo} alt={""} />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <TopMenuContainer />
                <div className={css.versionContainer}>
                    <div className={css.version}>{appVersion}</div>
                    <Whisper
                        trigger={"hover"}
                        placement={"bottom"}
                        speaker={<Tooltip>{branchName}</Tooltip>}
                    >
                        <div className={cn(css.version, css.branch)}>
                            {branchName}
                        </div>
                    </Whisper>
                </div>
                <RefreshButtonContainer />
                <Whisper
                    placement="bottomEnd"
                    trigger="click"
                    speaker={logoutPopover}
                    preventOverflow
                >
                    <div className={css.btn}>
                        <span>{modalTitle}</span>
                        <Account className={css.btn} />
                    </div>
                </Whisper>
            </div>
        </>
    );
};

export default AppHeader;
