import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, useToaster } from "rsuite";
import css from "components/BreadCrumb/BreadCrumb.module.css";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import {
    ITenantDeleteWorkflow,
    WorkflowModalType,
} from "utils/tenant-workflows/tenant-workflows-types";
import { ITenant } from "redux/environments/tenants/tenants-types";
import {
    getTenantDelete,
    startTenantDelete,
} from "api/environments/tenants/tenant-delete-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import SignalRTenantDelete from "components/WorkflowLog/TenantDelete/SignalRTenantDelete";
import { template } from "lodash";
import { isEveryWorkflowStepCompleted } from "utils/tenant-workflows/tenant-workflows-helper";
import { useHistory } from "react-router";
import DeleteConfirmModalContainer from "components/Common/DeleteConfirmModal/DeleteConfirmModalContainer";
import cn from "classnames";

interface ComponentProps {
    envMoniker: string;
    activeEnvName: string;
    tenantMoniker: string;
    activeTenant: ITenant;
    activeTenantDeleteWorkflow: ITenantDeleteWorkflow | null;
    isDeleteFetched: boolean;
    storeInvoiceVolumeEnabled: boolean;
    setLoaderVisibility: (visible: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setTenantDeleteWorkflow: (workflow: ITenantDeleteWorkflow | null) => void;
}

const DeleteTenantBar: React.FC<ComponentProps> = ({
    envMoniker,
    activeEnvName,
    tenantMoniker,
    activeTenant,
    activeTenantDeleteWorkflow,
    isDeleteFetched,
    setLoaderVisibility,
    setIsUnsavedChangeAvailable,
    setTenantDeleteWorkflow,
    storeInvoiceVolumeEnabled,
}: ComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [deleteWorkflowId, setDeleteWorkflowId] = useState<string>("");
    const [confirmationDialogMessage, setConfirmationDialogMessage] =
        useState("");
    const [deleteCompleted, setDeleteCompleted] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [invoiceVolumeStoringValue, setInvoiceVolumeStoringValue] =
        useState<boolean>(storeInvoiceVolumeEnabled);
    const routeHistory = useHistory();

    const handleCancel = () => {
        setConfirmVisible(false);
    };

    const handleConfirm = () => {
        setLoaderVisibility(true);
        setIsUnsavedChangeAvailable(false);
        startTenantDelete(envMoniker, tenantMoniker, {
            storeInvoiceDailyVolumes: invoiceVolumeStoringValue,
        }).then(([payload, status]) => {
            if (payload && status === constants.statusCode.OK) {
                getTenantDelete(envMoniker, tenantMoniker).then(
                    ([payload, status]) => {
                        setLoaderVisibility(false);
                        if (payload && status === constants.statusCode.OK) {
                            setTenantDeleteWorkflow(payload);
                        } else {
                            const errorMessage = getErrorMessage(
                                String(payload),
                                status
                            );
                            toaster.push(
                                <Message showIcon type="error">
                                    {errorMessage}
                                </Message>,
                                {
                                    duration: constants.errorAlertDurationMsec,
                                }
                            );
                        }
                    }
                );
                setDeleteWorkflowId(payload);
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                toaster.push(
                    <Message showIcon type="error">
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            }
        });
        setConfirmVisible(false);
    };

    const handleDelete = () => {
        setConfirmVisible(true);
    };

    function updateWorkflow(workflow: ITenantDeleteWorkflow) {
        setTenantDeleteWorkflow(workflow);
    }

    useEffect(() => {
        if (activeTenant) {
            setConfirmationDialogMessage(
                template(t("tenantDelete.areYouSure"))({
                    tenantName: activeTenant.versionInfo.name,
                })
            );
        }
    }, [activeTenant]);

    useEffect(() => {
        if (activeTenantDeleteWorkflow) {
            setDeleteCompleted(
                isEveryWorkflowStepCompleted(activeTenantDeleteWorkflow.steps)
            );
        }
    }, [activeTenantDeleteWorkflow]);

    useEffect(() => {
        if (deleteCompleted && activeTenantDeleteWorkflow) {
            routeHistory.replace(
                `/environments/${activeEnvName}/deleted-tenant/${tenantMoniker}`
            );
        }
    }, [deleteCompleted]);

    useEffect(() => {
        if (
            activeTenantDeleteWorkflow &&
            activeTenantDeleteWorkflow.tenantId === activeTenant.versionInfo.id
        ) {
            setDeleteWorkflowId(activeTenantDeleteWorkflow.id);
        }
    }, [activeTenantDeleteWorkflow]);

    useEffect(() => {
        setIsDeleting(
            !!(
                activeTenant &&
                activeTenantDeleteWorkflow &&
                activeTenant.versionInfo.id ===
                    activeTenantDeleteWorkflow.tenantId
            )
        );
    }, [activeTenant, activeTenantDeleteWorkflow]);

    return (
        <>
            <DeleteConfirmModalContainer
                handleCancelModal={handleCancel}
                confirmCallbacks={[handleConfirm]}
                isVisible={confirmVisible}
                message={confirmationDialogMessage}
                storeInvoiceVolumeEnabled={invoiceVolumeStoringValue}
                setStoreInvoiceVolumeEnabled={setInvoiceVolumeStoringValue}
            />
            <SignalRTenantDelete
                deleteWorkflowId={deleteWorkflowId}
                updateWorkflow={updateWorkflow}
            >
                {!deleteCompleted && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {isDeleting && activeTenantDeleteWorkflow && (
                            <ProcessModalButton
                                workflow={activeTenantDeleteWorkflow}
                                workflowType={WorkflowModalType.TenantDeletion}
                            />
                        )}
                        <Button
                            onClick={handleDelete}
                            disabled={isDeleting || !isDeleteFetched}
                            appearance="primary"
                            className={cn(css.addNewCustomerBtn, css.deleteBtn)}
                        >
                            {t("tenantDelete.deleteButton")}
                        </Button>
                    </div>
                )}
            </SignalRTenantDelete>
        </>
    );
};

export default DeleteTenantBar;
