export const SET_CONFLUENCE_DOCUMENT_LINKS =
    "tenants/GET_CONFLUENCE_DOCUMENT_LINKS";

export type TConfluenceDocumentLinks = [
    IConfluenceDocumentLinks | null,
    number,
];

export interface IConfluenceDocumentLinks {
    machineLearningArticleUri: string;
    customFieldsConfiguration: string;
    featuresAndSettingsOverview: string;
    bankAccountValidation: string;
    recurringInvoices: string;
}

export interface IGetConfluenceDocumentLinks {
    type: typeof SET_CONFLUENCE_DOCUMENT_LINKS;
    confluenceDocumentLinks: IConfluenceDocumentLinks;
}

export interface State {
    confluenceDocumentLinks: IConfluenceDocumentLinks;
    isConfluenceDocumentLinksFetched: boolean;
}

export type Actions = IGetConfluenceDocumentLinks;
