export const SET_ENVIRONMENT_HISTORY_OPENED =
    "action-history/SET_ENVIRONMENT_HISTORY_OPENED";

export interface IEnvironmentHistoryItem {
    dateTime: Date;
    description: string;
    name?: string;
}

export interface ISetEnvironmentHistoryOpened {
    type: typeof SET_ENVIRONMENT_HISTORY_OPENED;
    isOpened: boolean;
}

export interface State {
    isOpened: boolean;
}

export type Actions = ISetEnvironmentHistoryOpened;
