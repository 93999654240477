import React from "react";
import { FormControlProps } from "rsuite";
import { Input } from "rsuite";
interface TextareaProps
    extends Omit<
        FormControlProps,
        keyof React.HTMLAttributes<HTMLInputElement>
    > {
    rows?: number;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
    (props, ref) => <Input {...props} as="textarea" ref={ref} />
);
Textarea.displayName = "Textarea";

export default Textarea;
