import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, ButtonToolbar, Message, toaster } from "rsuite";
import cn from "classnames";
import {
    EnrollStatus,
    IAxIntegrationApproveData,
    IEnrollCache,
    IEnrollStep,
    IEnrollWorkflow,
    StepApproveDataTypes,
    StepStatus,
    StepType,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import { DeletionType } from "redux/environments/tenants/tenants-types";
import ProgressBar from "components/Environments/Tenants/EnrollTenant/ProgressBar/ProgressBar";
import StepsNavigation from "components/Environments/Tenants/EnrollTenant/StepsNavigation/StepsNavigation";
import SuccessMessageContainer from "components/Environments/Tenants/EnrollTenant/SuccessMessage/SuccessMessageContainer";
import StepFrameContainer from "components/Environments/Tenants/EnrollTenant/StepFrame/StepFrameContainer";
import constants from "utils/constants";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import useUrlToPathConversion from "hooks/useUrlToPathConvertation";
import {
    ChangeEntityFrameworkSettingToggleValue,
    ITenantDeleteWorkflow,
} from "utils/tenant-workflows/tenant-workflows-types";
import DeletingTenantPage from "components/Environments/Tenants/DeleteTenant/DeleteTenantPage/DeletingTenantPage";
import styles from "./EnrollTenant.module.css";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import { v4 as uuid } from "uuid";
import { IAxImportEntity } from "redux/environments/tenants/data-import/data-import-types";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";

interface IComponentProps {
    enrollWorkflow: IEnrollWorkflow | null;
    enrollSteps: IEnrollStep[] | null;
    runningStepIndex: number;
    activeStep: IEnrollStep | null;
    environmentMoniker: string | null;
    orchestratorInstanceId: string | null;
    enrollCache: IEnrollCache | null;
    errorMessage: string;
    errorEnrollManualStepResponseMessage: string;
    isUnsavedChangeAvailable: boolean;
    clearWorkflowData: () => void;
    setActiveStep: (activeStep: IEnrollStep | null) => void;
    setErrorMessageIEnrollStep: (message: string) => void;
    fetchEnrollWorkflow: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => void;
    startEnroll: (environmentMoniker: string) => void;
    terminateEnroll: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => void;
    setOrchestratorInstanceId: (orchestratorInstanceId: string | null) => void;
    approveEnrollStep: (
        environmentMoniker: string,
        orchestratorInstanceId: string,
        eventName: string,
        data: StepApproveDataTypes
    ) => void;
    retryEnrollStep: (
        environmentMoniker: string,
        orchestratorInstanceId: string,
        data: StepApproveDataTypes
    ) => void;
    continueEnrollStep: (
        environmentMoniker: string,
        orchestratorInstanceId: string,
        data: StepApproveDataTypes
    ) => void;
    fetchEnvironments: (envName?: string) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    clearSecretActiveStepData: (data: IAxIntegrationApproveData) => void;
    setErrorEnrollManualStepResponseMessage: (message: string) => void;
    setTenantDeleteWorkflow: (workflow: ITenantDeleteWorkflow | null) => void;
    updateTenantAxImportEntities: (
        envMoniker: string,
        tenantMoniker: string,
        axImportEntities: IAxImportEntity[]
    ) => void;
    updateTenantChangeEntityFrameworkSettings: (
        envMoniker: string,
        tenantMoniker: string,
        settings: ChangeEntityFrameworkSettingToggleValue[]
    ) => void;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
    setErrorMessageInactiveTenant: (message: string) => void;
}

const EnrollTenant: React.FC<IComponentProps> = ({
    enrollWorkflow,
    enrollSteps,
    runningStepIndex,
    activeStep,
    enrollCache,
    environmentMoniker,
    orchestratorInstanceId,
    errorMessage,
    errorEnrollManualStepResponseMessage,
    isUnsavedChangeAvailable,
    clearWorkflowData,
    setActiveStep,
    setErrorMessageIEnrollStep,
    fetchEnrollWorkflow,
    startEnroll,
    terminateEnroll,
    setOrchestratorInstanceId,
    approveEnrollStep,
    retryEnrollStep,
    continueEnrollStep,
    fetchEnvironments,
    setIsUnsavedChangeAvailable,
    clearSecretActiveStepData,
    setErrorEnrollManualStepResponseMessage,
    setTenantDeleteWorkflow,
    updateTenantAxImportEntities,
    updateTenantChangeEntityFrameworkSettings,
    setModalConfirmCallback,
    setRefreshActivated,
    setErrorMessageInactiveTenant,
}: IComponentProps) => {
    const { t } = useTranslation();
    const { environmentName } = useParams<{ environmentName: string }>();
    const history = useHistory();
    const location = useLocation();
    const [isStepFailed, setIsStepFailed] = useState<boolean>(false);
    const activeStepRef = useRef<IEnrollStep | null>();
    const [isActiveStepApproved, setIsActiveStepApproved] =
        useState<boolean>(false);
    const [isActiveStepRetried, setIsActiveStepRetried] =
        useState<boolean>(false);
    const [isStepFormValid, setIsStepFormValid] = useState(() => () => true);
    const [showTenantIsDeletingMessage, setShowTenantIsDeletingMessage] =
        useState(false);

    const urlParams = new URLSearchParams(location.search);
    const instanceIdParameterName = "instanceId";
    const deletedTenantPathSegment = "deleted-tenant";
    const { pathname } = useLocation();
    const { params } = useUrlToPathConversion(pathname);
    const instanceIdParameterValue =
        params.instanceId || urlParams.get(instanceIdParameterName);

    const currentStepIndex = activeStep?.order || 0;

    const isPendingAutomatedStep =
        activeStep !== null
            ? [constants.enroll.STEP_5].includes(activeStep.order)
            : false;

    const isManualOrPendingStep =
        activeStep?.type === StepType.Manual || isPendingAutomatedStep;

    const shouldEnablePrimaryButtonByActiveStepData = () => {
        if (errorEnrollManualStepResponseMessage.length > 0) {
            return false;
        }

        if (activeStep?.order !== constants.enroll.STEP_5) {
            return true;
        }

        switch (activeStep.status) {
            case StepStatus.NotStarted:
            case StepStatus.Running:
            case StepStatus.Success:
                return false;
            case StepStatus.Failed:
                return true;
            case StepStatus.Pending:
                return activeStep.isFinalized === true;
        }
    };

    const isButtonToolbarVisible =
        (isManualOrPendingStep && !(currentStepIndex < runningStepIndex)) ||
        isStepFailed;

    const isAutomatedStepInfoVisible =
        activeStep?.type === StepType.Automated &&
        !isPendingAutomatedStep &&
        !(currentStepIndex < runningStepIndex);

    const approveActiveStep = (
        environmentMoniker: string,
        orchestratorInstanceId: string,
        activeStep: IEnrollStep
    ) => {
        try {
            if (
                activeStep.order === constants.enroll.STEP_5 ||
                activeStep.order === constants.enroll.STEP_10 ||
                activeStep.order === constants.enroll.STEP_12
            ) {
                setIsActiveStepApproved(true);
                approveEnrollStep(
                    environmentMoniker,
                    orchestratorInstanceId,
                    activeStep.name,
                    {} as StepApproveDataTypes
                );
            } else if (
                isStepFormValid() ||
                activeStep.order === constants.enroll.STEP_2
            ) {
                const data = JSON.parse(
                    activeStep.data ?? ""
                ) as StepApproveDataTypes;

                if (data !== null) {
                    setIsActiveStepApproved(true);
                    approveEnrollStep(
                        environmentMoniker,
                        orchestratorInstanceId,
                        activeStep.name,
                        data
                    );
                    setIsUnsavedChangeAvailable(false);

                    if (activeStep.order === constants.enroll.STEP_3) {
                        clearSecretActiveStepData(
                            data as IAxIntegrationApproveData
                        );
                    }
                }
            }
        } catch {
            return;
        }
    };

    const retryActiveStep = (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => {
        retryEnrollStep(environmentMoniker, orchestratorInstanceId, true);
        setIsActiveStepRetried(true);
    };

    const continueCurrentStep = (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => {
        continueEnrollStep(environmentMoniker, orchestratorInstanceId, true);
    };

    useEffect(() => {
        if (
            enrollWorkflow &&
            enrollWorkflow.status === EnrollStatus.Terminating
        ) {
            setShowTenantIsDeletingMessage(true);
        }
    }, [enrollWorkflow?.status]);

    useEffect(() => {
        if (!activeStep) {
            setActiveStep(enrollSteps && enrollSteps[runningStepIndex]);
        }
    });

    useEffect(() => {
        const isFailed = activeStep?.status === StepStatus.Failed;
        setIsStepFailed(isFailed);
        if (isFailed) {
            setErrorMessageIEnrollStep(
                activeStep?.logs ?? t("error.defaultError")
            );
        } else {
            setErrorMessageIEnrollStep("");
        }
    }, [activeStep, setErrorMessageIEnrollStep, t]);

    useEffect(() => {
        if (instanceIdParameterValue) {
            setOrchestratorInstanceId(instanceIdParameterValue);
        } else if (orchestratorInstanceId) {
            urlParams.set(instanceIdParameterName, orchestratorInstanceId);
            history.replace({ search: urlParams.toString() });
        }
    }, [
        orchestratorInstanceId,
        history,
        urlParams,
        instanceIdParameterName,
        instanceIdParameterValue,
        setOrchestratorInstanceId,
    ]);

    useEffect(() => {
        if (
            environmentMoniker &&
            !orchestratorInstanceId &&
            !instanceIdParameterValue
        ) {
            startEnroll(environmentMoniker);
        }
    }, [
        environmentMoniker,
        startEnroll,
        instanceIdParameterValue,
        orchestratorInstanceId,
    ]);

    useEffect(() => {
        if (environmentMoniker && orchestratorInstanceId) {
            fetchEnrollWorkflow(environmentMoniker, orchestratorInstanceId);
        }

        return () => {
            setErrorEnrollManualStepResponseMessage("");
            clearWorkflowData();
        };
    }, [
        environmentMoniker,
        orchestratorInstanceId,
        fetchEnrollWorkflow,
        clearWorkflowData,
    ]);

    useEffect(() => {
        return () => {
            if (instanceIdParameterValue !== orchestratorInstanceId) {
                setOrchestratorInstanceId(null);
            }
        };
    }, [setOrchestratorInstanceId]);

    useEffect(() => {
        activeStepRef.current = activeStep;
    }, [activeStep]);

    useEffect(() => {
        return () => {
            if (
                environmentMoniker &&
                orchestratorInstanceId &&
                activeStepRef.current?.order === constants.enroll.STEP_1 &&
                activeStepRef.current.status === StepStatus.Running
            ) {
                terminateEnroll(environmentMoniker, orchestratorInstanceId);
            }
        };
    }, [environmentMoniker, orchestratorInstanceId]);

    useEffect(() => {
        if (!environmentMoniker) {
            fetchEnvironments(environmentName);
        }
    }, [environmentMoniker, environmentName, fetchEnvironments]);

    useEffect(() => {
        if (runningStepIndex >= 0) {
            setIsActiveStepApproved(false);
            setIsActiveStepRetried(false);
        }
    }, [runningStepIndex]);

    useEffect(() => {
        setIsActiveStepApproved(false);
        setIsActiveStepRetried(false);
    }, [activeStep?.status]);

    useEffect(() => {
        return () => {
            toaster.clear();
        };
    }, []);

    useEffect(() => {
        setErrorMessageInactiveTenant("");
    }, []);

    const handleStepSubmitClick = () => {
        if (
            enrollSteps &&
            environmentMoniker &&
            orchestratorInstanceId &&
            activeStep
        ) {
            if (isStepFailed) {
                setIsActiveStepApproved(false);
                retryActiveStep(environmentMoniker, orchestratorInstanceId);
                return;
            }

            if (isPendingAutomatedStep) {
                setIsActiveStepApproved(true);
                continueCurrentStep(environmentMoniker, orchestratorInstanceId);
                return;
            }

            if (
                activeStep.order === constants.enroll.STEP_12 &&
                activeStep.data &&
                enrollCache?.tenantMoniker
            ) {
                const axImportEntities = JSON.parse(activeStep.data);
                if (axImportEntities.length) {
                    updateTenantAxImportEntities(
                        environmentMoniker,
                        enrollCache.tenantMoniker,
                        axImportEntities
                    );
                    const cefEntities: ChangeEntityFrameworkSettingToggleValue[] =
                        [];
                    axImportEntities.forEach((entity: IAxImportEntity) => {
                        if (entity.isCefTurnedOn) {
                            cefEntities.push({
                                settingName: entity.importEntityName,
                                settingToggleValue: entity.isCefTurnedOn,
                                axImportToggleValue:
                                    entity.isAxImportTurnedOn ?? false,
                            });
                        }
                    });
                    if (cefEntities.length !== 0) {
                        updateTenantChangeEntityFrameworkSettings(
                            environmentMoniker,
                            enrollCache.tenantMoniker,
                            cefEntities
                        );
                    }
                }
                setIsActiveStepApproved(true);
                continueCurrentStep(environmentMoniker, orchestratorInstanceId);
                setIsUnsavedChangeAvailable(false);
                return;
            }

            approveActiveStep(
                environmentMoniker,
                orchestratorInstanceId,
                activeStep
            );
        }
    };

    useEffect(() => {
        return () => {
            if (!location.pathname.includes(deletedTenantPathSegment)) {
                setTenantDeleteWorkflow(null);
            }
        };
    }, [setTenantDeleteWorkflow, history]);

    const handleStepClick = (stepIndex: number) => {
        if (enrollWorkflow?.status === EnrollStatus.Terminating) {
            return;
        }

        const isIndexLessThanActive = stepIndex <= runningStepIndex;

        if (isIndexLessThanActive) {
            if (isUnsavedChangeAvailable) {
                setModalConfirmCallback({
                    id: uuid(),
                    isAutoDeleteResistant: false,
                    isPageRedirect: true,
                    callback: () => {
                        setIsUnsavedChangeAvailable(false);
                        setActiveStep(enrollSteps && enrollSteps[stepIndex]);
                    },
                });
                setRefreshActivated(true);
                return;
            }
            setActiveStep(enrollSteps && enrollSteps[stepIndex]);
        }
    };

    const handleCloseClick = () => {
        history.push(`/environments/${environmentName}/tenants`);
    };

    const getButtonName = () => {
        if (isStepFailed) {
            return t("enrollTenant.retryBtn");
        } else if (activeStep?.order === constants.enroll.STEP_5) {
            return t("enrollTenant.confirmBtn");
        } else if (activeStep?.order === constants.enroll.STEP_12) {
            return t("enrollTenant.completeBtn");
        } else {
            return t("enrollTenant.saveBtn");
        }
    };

    if (
        enrollSteps &&
        enrollSteps[enrollSteps.length - 1].status === StepStatus.Success
    ) {
        return <SuccessMessageContainer />;
    }

    if (errorMessage.length > 0) {
        return (
            <div className={styles.errorMessageContainer}>
                <ErrorSectionContainer />
            </div>
        );
    }

    return (
        <>
            {enrollSteps && (
                <>
                    <h1 className={styles.pageTitle}>
                        {t("enrollTenant.pageTitle")}
                    </h1>
                    <ProgressBar
                        enrollSteps={enrollSteps}
                        runningStepIndex={runningStepIndex}
                    />
                    <div className={styles.stepsContainer}>
                        <StepsNavigation
                            enrollWorkflow={enrollWorkflow}
                            enrollSteps={enrollSteps}
                            currentStepIndex={currentStepIndex}
                            onStepClick={(index) => handleStepClick(index)}
                        />
                        {showTenantIsDeletingMessage && (
                            <div className={styles.rightSection}>
                                <DeletingTenantPage
                                    deletionType={DeletionType.EnrollDeletion}
                                />
                            </div>
                        )}
                        {!showTenantIsDeletingMessage && (
                            <div className={styles.rightSection}>
                                <StepFrameContainer
                                    activeStep={activeStep}
                                    setIsStepFormValid={setIsStepFormValid}
                                    isActiveStepApproved={isActiveStepApproved}
                                    isActiveStepRetried={isActiveStepRetried}
                                    runningStepIndex={runningStepIndex}
                                />
                                {isButtonToolbarVisible && (
                                    <ButtonToolbar
                                        hidden={isButtonToolbarVisible}
                                        className={styles.buttonsSection}
                                    >
                                        <Button
                                            type="submit"
                                            onClick={() =>
                                                handleStepSubmitClick()
                                            }
                                            className={cn(
                                                styles.generalBtn,
                                                styles.saveBtn
                                            )}
                                            disabled={
                                                !shouldEnablePrimaryButtonByActiveStepData()
                                            }
                                            loading={
                                                isActiveStepRetried ||
                                                isActiveStepApproved
                                            }
                                        >
                                            {getButtonName()}
                                        </Button>
                                        <Button
                                            onClick={() => handleCloseClick()}
                                            className={cn(
                                                styles.generalBtn,
                                                styles.cancelBtn
                                            )}
                                        >
                                            {activeStep?.order ===
                                            constants.enroll.STEP_1
                                                ? t("enrollTenant.cancelBtn")
                                                : t("enrollTenant.closeBtn")}
                                        </Button>
                                        {currentStepIndex !== 0 && (
                                            <div
                                                className={
                                                    styles.infoAboutCancelBtn
                                                }
                                            >
                                                <InfoOutlineIcon
                                                    className={styles.infoIcon}
                                                />
                                                {t(
                                                    "enrollTenant.closeBtnInformation"
                                                )}
                                            </div>
                                        )}
                                    </ButtonToolbar>
                                )}
                                {isAutomatedStepInfoVisible && (
                                    <Message
                                        showIcon
                                        type="info"
                                        className={styles.infoMessage}
                                    >
                                        {t("enrollTenant.automaticStepInfo")}
                                    </Message>
                                )}
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default EnrollTenant;
