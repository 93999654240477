import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    CheckPicker,
    DatePicker,
    Pagination,
    Popover,
    Table,
    Whisper,
} from "rsuite";
import { getErrorMessage } from "api/defaults";
import { loadActivityLog } from "api/environments/tenants/tenant-activity-log";
import {
    ActionType,
    IActivityLog,
} from "redux/environments/tenants/tenant-activity-log/tenant-activity-log-types";
import { formatDateWithNameOfMonth, timeSince } from "utils/date-helper";
import constants from "utils/constants";
import useRequestCancellation from "hooks/useRequestCancellation";
import "./ActivityLog.less";
import ActivityLogDetails from "./ActivityLogDetails/ActivityLogDetails";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    showError: (errorMessage: string) => void;
}

const ActivityLog: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    showError,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();
    const containerRef = useRef<HTMLDivElement>(null);
    const [activityLogs, setActivityLogs] = useState<IActivityLog[]>();
    const [pageIndex, setPageindex] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageTotalCount, setPageTotalCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tableHeight, setTableHeight] = useState<number>();
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [selectedActionTypes, setSelectedActionTypes] = useState<string[]>(
        []
    );
    const [isActivityLogDetailsOpen, setActivityLogDetailsOpen] =
        useState<boolean>(false);
    const [selectedActivityLog, setSelectedActivityLog] =
        useState<IActivityLog | null>(null);

    const updateTableHeight = () => {
        if (containerRef.current) {
            const tableHeight = containerRef.current.clientHeight * 0.65;
            setTableHeight(tableHeight);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        loadActivityLog(
            envMoniker,
            tenantMoniker,
            pageIndex,
            pageSize,
            startDate,
            endDate,
            selectedActionTypes.join(","),
            cancelTokenSource
        )
            .then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setActivityLogs(payload.items);
                    setPageTotalCount(payload.totalCount);
                    setPageindex(payload.pageIndex);
                } else {
                    showError(getErrorMessage(String(payload), status));
                }
                setIsLoading(false);
            })
            .catch((error: Error) => {
                const axiosError = error;
                if (!Axios.isCancel(axiosError)) {
                    showError(error.message);
                }
            });
    }, [
        envMoniker,
        tenantMoniker,
        pageIndex,
        pageSize,
        startDate,
        endDate,
        selectedActionTypes,
    ]);

    useEffect(() => {
        window.addEventListener("resize", updateTableHeight);
    }, []);

    useEffect(() => {
        if (!isLoading) {
            updateTableHeight();
        }
    }, [isLoading]);

    const handleChangePage = (page: number): void => {
        setPageindex(page);
    };

    const handleChangeLength = (size: number): void => {
        resetPage();
        setPageSize(size);
    };

    const handleActivityLogSelect =
        (activityLog: IActivityLog, name: string) => () => {
            setSelectedActivityLog({
                ...activityLog,
                actionTypeName: name,
            });
            setActivityLogDetailsOpen(true);
        };

    const resetPage = (): void => {
        setPageindex(1);
    };

    const getActionTypeName = (name: string, index: number | null) => {
        if (
            index !== null &&
            index in [ActionType.Create, ActionType.Update, ActionType.Delete]
        ) {
            return "Dml" + name;
        }
        return name;
    };

    const actionTypes = [
        { label: "DmlCreate", value: "Create" },
        { label: "DmlUpdate", value: "Update" },
        { label: "DmlDelete", value: "Delete" },
        { label: "SwitchingTenantFeature", value: "SwitchingTenantFeature" },
        { label: "RetryFailedStep", value: "RetryFailedStep" },
        { label: "RenewSearchIndex", value: "RenewSearchIndex" },
        { label: "CreatingMailbox", value: "CreatingMailbox" },
        { label: "Unknown", value: "null" },
    ];

    return (
        <>
            <ActivityLogDetails
                activityLog={selectedActivityLog}
                isActivityLogDetailsOpen={isActivityLogDetailsOpen}
                setActivityLogDetailsOpen={setActivityLogDetailsOpen}
            />
            <div ref={containerRef} className="activity-log__container">
                <h1>{t("tenantConfig.activityLog.header")}</h1>
                <div className="activity-log__date-filter">
                    <CheckPicker
                        data={actionTypes}
                        value={selectedActionTypes}
                        onChange={(actionType) => {
                            resetPage();
                            setSelectedActionTypes(actionType);
                        }}
                        placeholder={t(
                            "tenantConfig.activityLog.actionTypesFilter"
                        )}
                        disabled={isLoading}
                        disabledItemValues={
                            isLoading ? actionTypes.map((a) => a.value) : []
                        }
                    />
                    <DatePicker
                        editable={false}
                        onChange={(date: Date | null) => {
                            resetPage();
                            setStartDate(
                                date ? date.toISOString().split("T")[0] : date
                            );
                        }}
                        placeholder={t(
                            "tenantConfig.activityLog.startDateFilter"
                        )}
                        disabled={isLoading}
                    />
                    <DatePicker
                        editable={false}
                        onChange={(date: Date | null) => {
                            resetPage();
                            setEndDate(
                                date ? date.toISOString().split("T")[0] : date
                            );
                        }}
                        placeholder={t(
                            "tenantConfig.activityLog.endDateFilter"
                        )}
                        disabled={isLoading}
                    />
                </div>
                <Table
                    data={activityLogs}
                    loading={isLoading}
                    className="activity-log__table"
                    height={tableHeight}
                >
                    <Table.Column flexGrow={3}>
                        <Table.HeaderCell>
                            {t("tenantConfig.activityLog.table.actionTypeName")}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="actionTypeName">
                            {(rowData: IActivityLog) => {
                                const name = getActionTypeName(
                                    rowData.actionTypeName,
                                    rowData.actionType
                                );
                                return (
                                    <div
                                        className="activity-log__list-name"
                                        onClick={handleActivityLogSelect(
                                            rowData,
                                            name
                                        )}
                                    >
                                        {name ??
                                            t(
                                                "tenantConfig.activityLog.unknown"
                                            )}
                                    </div>
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column>
                        <Table.HeaderCell>
                            {t("tenantConfig.activityLog.table.status")}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="failureReason">
                            {(rowData: IActivityLog) => {
                                return (
                                    <>
                                        {rowData.failureReason ? (
                                            <Whisper
                                                enterable
                                                trigger={"hover"}
                                                placement={"top"}
                                                speaker={
                                                    <Popover className="activity-log__tooltip">
                                                        {rowData.failureReason}
                                                    </Popover>
                                                }
                                            >
                                                <div
                                                    className={
                                                        "activity-log__status-block--error"
                                                    }
                                                >
                                                    {t(
                                                        "tenantConfig.activityLog.table.statusFailed"
                                                    )}
                                                </div>
                                            </Whisper>
                                        ) : (
                                            <div
                                                className={
                                                    "activity-log__status-block--success"
                                                }
                                            >
                                                {t(
                                                    "tenantConfig.activityLog.table.statusSuccess"
                                                )}
                                            </div>
                                        )}
                                    </>
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={4}>
                        <Table.HeaderCell>
                            {t("tenantConfig.activityLog.table.username")}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="userName">
                            {(rowData: IActivityLog) => {
                                return <div>{rowData.userName ?? "-"}</div>;
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={2}>
                        <Table.HeaderCell>
                            {t("tenantConfig.activityLog.table.timeAgo")}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="timeStamp">
                            {(rowData: IActivityLog) => {
                                return (
                                    <div>
                                        {timeSince(new Date(rowData.timeStamp))}
                                    </div>
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column flexGrow={4}>
                        <Table.HeaderCell>
                            {t("tenantConfig.activityLog.table.timestamp")}
                        </Table.HeaderCell>
                        <Table.Cell dataKey="timeStamp">
                            {(rowData: IActivityLog) => {
                                return (
                                    <div>
                                        {formatDateWithNameOfMonth(
                                            new Date(rowData.timeStamp)
                                        )}
                                    </div>
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                </Table>
                <Pagination
                    layout={["limit", "|", "total", "-", "pager"]}
                    limitOptions={[10, 20]}
                    maxButtons={5}
                    activePage={pageIndex}
                    limit={pageSize}
                    total={pageTotalCount}
                    onChangePage={handleChangePage}
                    onChangeLimit={handleChangeLength}
                    disabled={isLoading}
                    ellipsis={true}
                    first
                    last
                    prev
                    next
                    boundaryLinks
                />
            </div>
        </>
    );
};

export default ActivityLog;
