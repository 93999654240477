import React, { useEffect, useRef, useState } from "react";
import {
    Checkbox,
    Schema,
    Form,
    Button,
    Message,
    Divider,
    FormInstance,
    useToaster,
} from "rsuite";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import {
    ISearchService,
    ISftpUserWithIpAddresses,
    ITenantFeatureAppConfigurations,
    ITenantFeatureConfigurations,
    TenantFeatureGroup,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import "./FeatureConfigurations.less";
import constants from "utils/constants";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowToggleValue,
    TenantFeatureSettingWorkFlowType,
    WorkflowModalType,
    WorkflowStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import { CustomerType } from "redux/customers/add-new-customer/add-new-customer-types";
import { useParams } from "react-router-dom";
import { IAppSettings } from "redux/app-settings/app-settings-types";
import { IConfluenceDocumentLinks } from "redux/information-links/information-links-types";
import ConfirmModalContainer from "components/Common/ConfirmModal/ConfirmModalContainer";
import { tenantFeatureSettingDefinitions } from "utils/tenant-workflows/tenant-workflows-definitions";
import {
    getTenantFeatureSettingWorkFlows,
    toggleTenantFeatureSetting,
    toggleTenantFeatureSettings,
} from "api/environments/tenants/tenant-feature-settings-api";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import { cloneDeep, template } from "lodash";
import usePreviousState from "hooks/usePreviousState";
import useDisabledFeatureSettingNames from "hooks/useDisabledFeatureSettingNames";
import {
    loadFeatureAppConfigurationSettings,
    loadFeatureConfigurations,
    updateFeatureAppConfigurationSettings,
    updateFeatureConfigurations,
    checkTenantDatabaseAlertRuleExistence,
    refreshSearchIndex,
    updateSearchIndex,
    loadSearchServiceLinked,
    loadSearchServices,
} from "api/environments/tenants/tenant-feature-configurations-api";
import {
    isEverySettingWorkFlowUpdated,
    isEveryWorkflowStepCompleted,
    isTenantFeatureSettingWithTypeEnabled,
    updateTenantSettingWorkFlow,
} from "utils/tenant-workflows/tenant-workflows-helper";
import SignalRTenantFeatureSetting from "components/WorkflowLog/TenantFeature/SignalRTenantFeatureSetting";
import LogoCustomizationContainer from "./LogoCustomization/LogoCustomizationContainer";
import {
    checkIfTenantLogoExists,
    uploadLogoToBlobContainer,
} from "api/environments/tenants/tenant-logo-api";
import { getErrorMessage } from "api/defaults";
import { loadConfluenceDocumentLinks } from "api/information-links-api";
import useRefreshPage from "hooks/useRefreshPage";
import ErrorSection from "components/Common/ErrorSection/ErrorSection";
import useRequestCancellation from "hooks/useRequestCancellation";
import Axios from "axios";
import FeatureGroups from "./FeatureGroups/FeatureGroups";
import {
    loadSftpUserIpAddresses,
    updateSftpUserIpAddresses,
} from "api/environments/tenants/tenant-sftp-settings-api";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    tenantName: string;
    customerType: CustomerType;
    isPowerBiWorkspaceDeploymentAllowed: boolean;
    appSettings: IAppSettings;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    showLoader: (isVisible: boolean) => void;
}

const defaultSftpUser: ISftpUserWithIpAddresses = {
    id: 0,
    allowedIpAddresses: [],
};

const FeatureConfigurations: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    tenantName,
    customerType,
    isPowerBiWorkspaceDeploymentAllowed,
    appSettings,
    setIsUnsavedChangeAvailable,
    showLoader,
}: ComponentProps) => {
    const { t } = useTranslation();
    const cancelTokenSource = useRequestCancellation();
    const toaster = useToaster();
    const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const { tenantId } = useParams<{ tenantId: string }>();
    const [
        previousFeatureSettingWorkFlows,
        featureSettingWorkFlows,
        setFeatureSettingWorkFlows,
    ] = usePreviousState<ITenantFeatureSettingWorkFlow[] | null>(null);
    const [
        disabledSettingNames,
        addDisabledSettingNames,
        removeDisabledSettingName,
    ] = useDisabledFeatureSettingNames();
    const [isDataFetched, setDataFetched] = useState<boolean>(false);
    const [isSettingsFormValid, setSettingsFormValid] =
        useState<boolean>(false);
    const [features, setFeatures] = useState<ITenantFeatureConfigurations>();
    const [originalFeatures, setOriginalFeatures] =
        useState<ITenantFeatureConfigurations | null>(null);
    const [feturesErrorMessage, setFeturesErrorMessage] = useState<string>("");
    const [featureAppConfigSettings, setFeaturesAppConfigSettings] =
        useState<ITenantFeatureAppConfigurations>();
    const [confluenceDocumentLinks, setConfluenceDocumentLinks] =
        useState<IConfluenceDocumentLinks | null>(null);
    const [alertRuleExists, setAlertRuleExists] = useState<boolean | null>(
        null
    );
    const [isModifySearchDisabled, setModifySearchDisabled] =
        useState<boolean>(false);
    const [searchConfirmVisible, setSearchConfirmVisible] = useState(false);
    const [logoConfirmVisible, setLogoConfirmVisible] =
        useState<boolean>(false);
    const [logoName, setLogoName] = useState<string>("");
    const [logoFile, setLogoFile] = useState<number[] | null>(null);
    const [isLogoChanged, setIsLogoChanged] = useState<boolean>(false);
    const [isTenantLogoValid, setIsTenantLogoValid] = useState<boolean>(true);
    const codingPredictionWorkflow = featureSettingWorkFlows?.find(
        (workflow) =>
            workflow.type === TenantFeatureSettingWorkFlowType.CodingPrediction
    );
    const [sftpUserWithIpAddresses, setSftpUserWithIpAddresses] =
        useState<ISftpUserWithIpAddresses>(defaultSftpUser);
    const [areSftpAddressesValid, setSftpAddressesValid] =
        useState<boolean>(true);
    const [searchServices, setSearchSerices] = useState<ISearchService[]>([]);
    const [linkedSearchService, setLinkedSearchService] = useState<
        string | null
    >(null);
    const [originalSearchService, setOriginalSearchService] = useState<
        string | null
    >(null);

    const isMlSalesTaxDisabled =
        !features?.codingPredictionEnabled ||
        !codingPredictionWorkflow ||
        !(
            codingPredictionWorkflow?.status === WorkflowStatus.Success &&
            codingPredictionWorkflow.toggleValue
        );

    const powerBiWorkspaceDeploymentWorkflow = featureSettingWorkFlows?.find(
        (workflow) =>
            workflow.type ===
            TenantFeatureSettingWorkFlowType.PowerBiWorkspaceDeployment
    );

    const searchServiceSelectionWorkflow = featureSettingWorkFlows?.find(
        (workflow) =>
            workflow.type ===
            TenantFeatureSettingWorkFlowType.SearchServiceSelection
    );

    const alertRuleDeploymentWorkflow = featureSettingWorkFlows?.find(
        (workflow) =>
            workflow.type ===
            TenantFeatureSettingWorkFlowType.DatabaseAlertRuleCreation
    );

    const enableSftpWorkflow = featureSettingWorkFlows?.find(
        (workflow) =>
            workflow.type === TenantFeatureSettingWorkFlowType.EnableSftp
    );

    const enableSftpToSettingMapping = {
        workflowType: TenantFeatureSettingWorkFlowType.EnableSftp,
        getFeature: (feature: ITenantFeatureConfigurations) =>
            feature.sftpEnabled,
        text: t("tenantConfig.featureConfigurations.sftpEnabled.title"),
        helpText: t(
            "tenantConfig.featureConfigurations.sftpEnabled.description"
        ),
        value: "sftpEnabled",
        checked: features?.sftpEnabled,
        isDisabled:
            (!sftpUserWithIpAddresses.allowedIpAddresses.length &&
                !features?.sftpEnabled) ||
            (!areSftpAddressesValid && !features?.sftpEnabled),
        isAvailableForAx: true,
        group: TenantFeatureGroup.sftp,
    };

    const workFlowTypeToSettingMapping = [
        {
            workflowType:
                TenantFeatureSettingWorkFlowType.D365RoleDooapRequiredToAccessDooap,
            getFeature: (feature: ITenantFeatureConfigurations) =>
                feature.d365RoleDooapRequiredToAccessDooap,
            text: t(
                "tenantConfig.featureConfigurations.d365RoleDooapRequiredToAccessDooap.title"
            ),
            helpText: t(
                "tenantConfig.featureConfigurations.d365RoleDooapRequiredToAccessDooap.description"
            ),
            value: "d365RoleDooapRequiredToAccessDooap",
            checked: features?.d365RoleDooapRequiredToAccessDooap,
            isDisabled: false,
            isAvailableForAx: false,
            group: TenantFeatureGroup.other,
        },
        {
            workflowType: TenantFeatureSettingWorkFlowType.DocumentsTransfer,
            getFeature: (feature: ITenantFeatureConfigurations) =>
                feature.transferInvoiceDocumentsToAxDuringTransferToPayment,
            text: t(
                "tenantConfig.featureConfigurations.transferInvoiceDocuments.title"
            ),
            helpText: t(
                "tenantConfig.featureConfigurations.transferInvoiceDocuments.description"
            ),
            value: "transferInvoiceDocumentsToAxDuringTransferToPayment",
            checked:
                features?.transferInvoiceDocumentsToAxDuringTransferToPayment,
            isDisabled: false,
            isAvailableForAx: false,
            group: TenantFeatureGroup.other,
        },
        {
            workflowType: TenantFeatureSettingWorkFlowType.CodingPrediction,
            getFeature: (feature: ITenantFeatureConfigurations) =>
                feature.codingPredictionEnabled,
            text: t(
                "tenantConfig.featureConfigurations.codingPrediction.title"
            ),
            helpText: t(
                "tenantConfig.featureConfigurations.codingPrediction.description"
            ),
            value: "codingPredictionEnabled",
            checked: features?.codingPredictionEnabled,
            isDisabled: false,
            isAvailableForAx: true,
            group: TenantFeatureGroup.mlCoding,
        },
        {
            workflowType: TenantFeatureSettingWorkFlowType.WorkflowPrediction,
            getFeature: (feature: ITenantFeatureConfigurations) =>
                feature.workflowPredictionEnabled,
            text: t(
                "tenantConfig.featureConfigurations.workflowPrediction.title"
            ),
            helpText: t(
                "tenantConfig.featureConfigurations.workflowPrediction.description"
            ),
            value: "workflowPredictionEnabled",
            checked: features?.workflowPredictionEnabled,
            isDisabled: false,
            isAvailableForAx: true,
            group: TenantFeatureGroup.mlWorkflow,
        },
        {
            workflowType: TenantFeatureSettingWorkFlowType.SalesTaxPrediction,
            getFeature: (feature: ITenantFeatureConfigurations) =>
                feature.salesTaxPredictionEnabled,
            text: t(
                "tenantConfig.featureConfigurations.salesTaxPrediction.title"
            ),
            helpText: t(
                "tenantConfig.featureConfigurations.salesTaxPrediction.description"
            ),
            value: "salesTaxPredictionEnabled",
            checked: features?.salesTaxPredictionEnabled,
            isDisabled: isMlSalesTaxDisabled,
            isAvailableForAx: true,
            group: TenantFeatureGroup.mlCoding,
        },
    ];

    const allWorkflows = [
        enableSftpToSettingMapping,
        ...workFlowTypeToSettingMapping,
    ];

    const handleTenantFeatureChange = (
        name: keyof ITenantFeatureConfigurations,
        value: string | number | boolean
    ) => {
        if (features) {
            setFeatures({
                ...features,
                [name]: value,
            });
        }
        setIsUnsavedChangeAvailable(true);
    };

    const handleTenantFeatureAppConfigSettingChange = (
        name: keyof ITenantFeatureAppConfigurations,
        value: boolean
    ) => {
        if (featureAppConfigSettings) {
            setFeaturesAppConfigSettings({
                ...featureAppConfigSettings,
                [name]: value,
            });
        }
        setIsUnsavedChangeAvailable(true);
    };

    const fetchData = () => {
        setDataFetched(false);
        showLoader(true);
        const promises: any[] = [];
        promises.push(
            getTenantFeatureSettingWorkFlows(
                envMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(([workFlows]) => {
                    setFeatureSettingWorkFlows(workFlows || []);
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setFeturesErrorMessage(error.message);
                    }
                }),
            loadFeatureConfigurations(
                envMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(([payload, status]) => {
                    if (status === constants.statusCode.OK && payload) {
                        setOriginalFeatures(payload);
                        setFeatures(payload);
                    } else {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            status
                        );
                        setFeturesErrorMessage(errorMessage);
                    }
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setFeturesErrorMessage(error.message);
                    }
                }),
            loadFeatureAppConfigurationSettings(
                envMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(([payload, status]) => {
                    if (status === constants.statusCode.OK && payload) {
                        setFeaturesAppConfigSettings(payload);
                    } else {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            status
                        );
                        setFeturesErrorMessage(errorMessage);
                    }
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setFeturesErrorMessage(error.message);
                    }
                }),
            loadConfluenceDocumentLinks(cancelTokenSource)
                .then(([payload, status]) => {
                    if (status === constants.statusCode.OK && payload) {
                        setConfluenceDocumentLinks(payload);
                    } else {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            status
                        );
                        toaster.push(
                            <Message type="error" showIcon>
                                {errorMessage}
                            </Message>,
                            { duration: constants.errorAlertDurationMsec }
                        );
                    }
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setFeturesErrorMessage(error.message);
                    }
                }),
            loadSftpUserIpAddresses(
                envMoniker,
                tenantId ?? "",
                cancelTokenSource
            )
                .then(([payload, status]) => {
                    if (status === constants.statusCode.OK) {
                        setSftpUserWithIpAddresses(payload);
                    } else if (status === constants.statusCode.NoContent) {
                        setSftpUserWithIpAddresses(defaultSftpUser);
                    } else {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            status
                        );
                        toaster.push(
                            <Message type="error" showIcon>
                                {errorMessage}
                            </Message>,
                            { duration: constants.errorAlertDurationMsec }
                        );
                    }
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setFeturesErrorMessage(error.message);
                    }
                }),
            appSettings.isAlertRuleEstablishmentEnabled &&
                checkTenantDatabaseAlertRuleExistence(
                    envMoniker,
                    tenantMoniker,
                    cancelTokenSource
                )
                    .then(([payload, status]) => {
                        if (status === constants.statusCode.OK) {
                            setAlertRuleExists(payload);
                        } else {
                            const errorMessage = getErrorMessage(
                                String(payload),
                                status
                            );
                            setFeturesErrorMessage(errorMessage);
                        }
                    })
                    .catch((error: Error) => {
                        const axiosError = error;
                        if (!Axios.isCancel(axiosError)) {
                            setFeturesErrorMessage(error.message);
                        }
                    }),
            loadSearchServices(
                envMoniker,
                tenantId ?? "",
                cancelTokenSource
            ).then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setSearchSerices(payload);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    setFeturesErrorMessage(errorMessage);
                }
            }),
            loadSearchServiceLinked(
                envMoniker,
                tenantId ?? "",
                cancelTokenSource
            ).then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setLinkedSearchService(payload.name);
                    setOriginalSearchService(payload.name);
                } else if (status === constants.statusCode.NotFound) {
                    setLinkedSearchService(null);
                    setOriginalSearchService(null);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    setFeturesErrorMessage(errorMessage);
                }
            })
        );
        Promise.all(promises).then(() => {
            showLoader(false);
            setDataFetched(true);
        });
    };

    const isPowerBiWorkspaceDeploymentEnabled =
        appSettings.isPowerBiWorkspaceDeploymentEnabled &&
        isPowerBiWorkspaceDeploymentAllowed;

    const alertRuleDeploymentEnabled =
        appSettings.isAlertRuleEstablishmentEnabled && !alertRuleExists;

    const getNumberValidation = (min: number, max: number) => {
        return Schema.Types.NumberType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .isInteger(t("generalValidationMessages.notInteger"))
            .range(
                min,
                max,
                template(t("generalValidationMessages.numberRange"))({
                    min,
                    max,
                })
            );
    };

    const formModel = Schema.Model({
        autoTTPMaxBatchSize: getNumberValidation(
            constants.ranges.minRangeValue1,
            constants.ranges.dotnetInt32MaxValue
        ),
        userSessionIdleTimeoutInMinutes: getNumberValidation(
            constants.ranges.minRangeValue1,
            constants.ranges.dotnetInt32MaxValue
        ),
        workflowPredictionImportMinScore: getNumberValidation(
            constants.ranges.minRangeValue0,
            constants.ranges.maxRangeValue100
        ),
        companyPredictionImportMinScore: getNumberValidation(
            constants.ranges.minRangeValue0,
            constants.ranges.maxRangeValue100
        ),
        codingPredictionImportMinScore: getNumberValidation(
            constants.ranges.minRangeValue0,
            constants.ranges.maxRangeValue100
        ),
        vendorPredictionVendorAddressMinScore: getNumberValidation(
            constants.ranges.minRangeValue0,
            constants.ranges.maxRangeValue100
        ),
        vendorPredictionVendorNameMinScore: getNumberValidation(
            constants.ranges.minRangeValue0,
            constants.ranges.maxRangeValue100
        ),
    });

    const handleResourceDeployClick = (
        workflowType: TenantFeatureSettingWorkFlowType
    ) => {
        const settingDefinition = tenantFeatureSettingDefinitions[workflowType];

        toggleTenantFeatureSetting(
            envMoniker,
            tenantMoniker,
            settingDefinition.urlParameterValue,
            {
                settingName: settingDefinition.urlParameterValue,
                toggleValue: true,
                data: null,
            }
        ).then((response) => {
            const [, status] = response;
            const isResponseStatusCodeExpected =
                status === constants.statusCode.NoContent ||
                status === constants.statusCode.OK;

            if (isResponseStatusCodeExpected) {
                toaster.push(
                    <Message type="success" showIcon>
                        {template(
                            t(
                                "tenantConfig.featureConfigurations.featureActivationMessages.startedSuccessfully"
                            )
                        )({
                            featureName: settingDefinition.displayName,
                        })}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            } else {
                toaster.push(
                    <Message type="error" showIcon>
                        {template(
                            t(
                                "tenantConfig.featureConfigurations.featureActivationMessages.startedFailure"
                            )
                        )({
                            featureName: settingDefinition.displayName,
                        })}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            }
        });
    };

    const handleRecreateModalConfirm = () => {
        setModifySearchDisabled(true);

        refreshSearchIndex(envMoniker, tenantId ?? "").then((response) => {
            const [, status] = response;
            const isResponseStatusCodeExpected =
                status === constants.statusCode.NoContent ||
                status === constants.statusCode.OK;

            if (isResponseStatusCodeExpected) {
                toaster.push(
                    <Message type="success" showIcon>
                        {t(
                            "tenantConfig.featureConfigurations.recreateSearchIndexResult.success"
                        )}
                    </Message>,
                    { duration: constants.errorAlertDurationMsec }
                );
            } else {
                toaster.push(
                    <Message type="error" showIcon>
                        {t(
                            "tenantConfig.featureConfigurations.recreateSearchIndexResult.failure"
                        )}
                    </Message>,
                    { duration: constants.errorAlertDurationMsec }
                );
            }
            setModifySearchDisabled(false);
        });
        setSearchConfirmVisible(false);
    };

    const updateTenantFeatureSettings = async () => {
        if (
            featureSettingWorkFlows !== null &&
            features &&
            envMoniker &&
            tenantMoniker
        ) {
            const settingsToUpdate: TenantFeatureSettingWorkFlowToggleValue[] =
                [];
            workFlowTypeToSettingMapping.forEach(
                ({ workflowType, getFeature }) => {
                    const newSettingValue = getFeature(features);
                    if (
                        originalFeatures === null ||
                        getFeature(originalFeatures) !== newSettingValue
                    ) {
                        const settingDefinition =
                            tenantFeatureSettingDefinitions[workflowType] ??
                            null;
                        if (
                            settingDefinition !== null &&
                            isTenantFeatureSettingWithTypeEnabled(
                                featureSettingWorkFlows,
                                workflowType
                            )
                        ) {
                            settingsToUpdate.push({
                                settingName:
                                    settingDefinition.urlParameterValue,
                                toggleValue: newSettingValue,
                                data: null,
                            });
                        }
                    }
                }
            );
            if (settingsToUpdate.length !== 0) {
                addDisabledSettingNames(
                    settingsToUpdate.map((setting) => setting.settingName)
                );
                await toggleTenantFeatureSettings(
                    envMoniker,
                    tenantMoniker,
                    settingsToUpdate
                );
                setOriginalFeatures(cloneDeep(features));
            }
        }
    };

    const updateTenantEnableSftp = async () => {
        if (
            enableSftpWorkflow !== null &&
            features &&
            envMoniker &&
            tenantMoniker
        ) {
            const newSettingValue =
                enableSftpToSettingMapping.getFeature(features);

            if (
                originalFeatures === null ||
                enableSftpToSettingMapping.getFeature(originalFeatures) !==
                    newSettingValue
            ) {
                const settingDefinition =
                    tenantFeatureSettingDefinitions[
                        enableSftpToSettingMapping.workflowType
                    ] ?? null;
                if (
                    settingDefinition !== null &&
                    isTenantFeatureSettingWithTypeEnabled(
                        featureSettingWorkFlows,
                        enableSftpToSettingMapping.workflowType
                    )
                ) {
                    const settingToUpdate = {
                        settingName: settingDefinition.urlParameterValue,
                        toggleValue: newSettingValue,
                        data: sftpUserWithIpAddresses?.allowedIpAddresses || [],
                    };

                    addDisabledSettingNames([settingToUpdate.settingName]);

                    await toggleTenantFeatureSetting(
                        envMoniker,
                        tenantMoniker,
                        settingToUpdate.settingName,
                        settingToUpdate
                    );

                    setOriginalFeatures(cloneDeep(features));
                }
            }
        }
    };

    const updateSearchServiceSelection = async () => {
        if (
            searchServiceSelectionWorkflow !== null &&
            envMoniker &&
            tenantMoniker
        ) {
            if (linkedSearchService !== originalSearchService) {
                const settingDefinition =
                    tenantFeatureSettingDefinitions[
                        TenantFeatureSettingWorkFlowType.SearchServiceSelection
                    ] ?? null;
                if (
                    settingDefinition !== null &&
                    isTenantFeatureSettingWithTypeEnabled(
                        featureSettingWorkFlows,
                        TenantFeatureSettingWorkFlowType.SearchServiceSelection
                    )
                ) {
                    const searchServiceToSelect = searchServices.find(
                        (s) => s.name === linkedSearchService
                    );
                    if (searchServiceToSelect) {
                        const settingToUpdate = {
                            settingName: settingDefinition.urlParameterValue,
                            toggleValue: true,
                            data: searchServiceToSelect,
                        };

                        await toggleTenantFeatureSetting(
                            envMoniker,
                            tenantMoniker,
                            settingToUpdate.settingName,
                            settingToUpdate
                        );
                    }
                }
            }
        }
    };

    const handleUpdateClick = () => {
        setModifySearchDisabled(true);

        updateSearchIndex(envMoniker, tenantId ?? "").then((response) => {
            const [, status] = response;
            const isResponseStatusCodeExpected =
                status === constants.statusCode.NoContent ||
                status === constants.statusCode.OK;

            if (isResponseStatusCodeExpected) {
                toaster.push(
                    <Message type="success" showIcon>
                        {t(
                            "tenantConfig.featureConfigurations.updateSearchIndexResult.success"
                        )}
                    </Message>,
                    { duration: constants.errorAlertDurationMsec }
                );
            } else {
                toaster.push(
                    <Message type="error" showIcon>
                        {t(
                            "tenantConfig.featureConfigurations.updateSearchIndexResult.failure"
                        )}
                    </Message>,
                    { duration: constants.errorAlertDurationMsec }
                );
            }
            setModifySearchDisabled(false);
        });
    };

    const checkWorkflowProgressAndUpdateWorkflow = (
        settingWorkflow: ITenantFeatureSettingWorkFlow
    ) => {
        setFeatureSettingWorkFlows((featureSettingWorkFlows) => {
            return updateTenantSettingWorkFlow(
                featureSettingWorkFlows,
                settingWorkflow
            );
        });
        if (
            settingWorkflow.type ===
                TenantFeatureSettingWorkFlowType.DocumentsTransfer ||
            settingWorkflow.type ===
                TenantFeatureSettingWorkFlowType.CodingPrediction ||
            settingWorkflow.type ===
                TenantFeatureSettingWorkFlowType.SalesTaxPrediction ||
            settingWorkflow.type ===
                TenantFeatureSettingWorkFlowType.WorkflowPrediction ||
            settingWorkflow.type ===
                TenantFeatureSettingWorkFlowType.D365RoleDooapRequiredToAccessDooap ||
            settingWorkflow.type ===
                TenantFeatureSettingWorkFlowType.EnableSftp ||
            settingWorkflow.type ===
                TenantFeatureSettingWorkFlowType.SearchServiceSelection
        ) {
            if (isEveryWorkflowStepCompleted(settingWorkflow.steps)) {
                fetchData();
            }
        }
    };

    const updateTenantConfigurations = async () => {
        if (features) {
            const [payload, status] = await updateFeatureConfigurations(
                envMoniker,
                tenantMoniker,
                features
            );
            if (status !== constants.statusCode.OK) {
                const errorMessage = getErrorMessage(String(payload), status);
                toaster.push(
                    <Message showIcon type="error">
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            }
        }
    };

    const updateTenantFeatureAppConfigSettings = async () => {
        if (featureAppConfigSettings) {
            const [payload, status] =
                await updateFeatureAppConfigurationSettings(
                    envMoniker,
                    tenantMoniker,
                    featureAppConfigSettings
                );
            if (status !== constants.statusCode.NoContent) {
                const errorMessage = getErrorMessage(String(payload), status);
                toaster.push(
                    <Message showIcon type="error">
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            }
        }
    };

    const updateTenantSftpUserIpAddresses = async () => {
        const [payload, status] = await updateSftpUserIpAddresses(
            envMoniker,
            tenantId ?? "",
            sftpUserWithIpAddresses
        );
        if (status !== constants.statusCode.NoContent) {
            const errorMessage = getErrorMessage(String(payload), status);
            toaster.push(
                <Message showIcon type="error">
                    {errorMessage}
                </Message>,
                {
                    duration: constants.errorAlertDurationMsec,
                }
            );
        }
    };

    const handleRecreateModalCancel = () => {
        setSearchConfirmVisible(false);
    };

    const handleRecreateClick = () => {
        setSearchConfirmVisible(true);
    };

    const checkTenantLogoExisting = () => {
        checkIfTenantLogoExists(envMoniker, tenantMoniker, logoName).then(
            ([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setLogoConfirmVisible(true);
                } else if (!payload && status === constants.statusCode.OK) {
                    handleLogoModalConfirm();
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        status
                    );
                    toaster.push(
                        <Message showIcon type="error">
                            {errorMessage}
                        </Message>,
                        {
                            duration: constants.errorAlertDurationMsec,
                        }
                    );
                }
            }
        );
    };

    const updateCompanyLogoUrl = () => {
        const currentLogoName = features?.companyLogoUrl.split("/").pop();
        if (currentLogoName && features) {
            features.companyLogoUrl = features.companyLogoUrl.replace(
                currentLogoName,
                logoName
            );
        }
    };

    const uploadTenantLogoToBlobContainer = async () => {
        const [payload, status] = await uploadLogoToBlobContainer(
            envMoniker,
            tenantMoniker,
            logoFile,
            logoName
        );
        if (status !== constants.statusCode.NoContent) {
            const errorMessage = getErrorMessage(String(payload), status);
            toaster.push(
                <Message showIcon type="error">
                    {errorMessage}
                </Message>,
                {
                    duration: constants.errorAlertDurationMsec,
                }
            );
        }
    };

    const handleFormSubmit = async () => {
        if (
            !isSettingsFormValid ||
            !areSftpAddressesValid ||
            !isTenantLogoValid
        ) {
            toaster.push(
                <Message showIcon type="error">
                    {t("tenantConfig.generalConfigurationPage.warning")}
                </Message>,
                {
                    duration: constants.errorAlertDurationMsec,
                }
            );

            return;
        }
        showLoader(true);

        if (isLogoChanged) {
            checkTenantLogoExisting();
        } else {
            await updateAllTenantConfigurations();
            setIsUnsavedChangeAvailable(false);
        }
    };

    const updateAllTenantConfigurations = async () => {
        const promises: any[] = [];
        promises.push(
            updateTenantConfigurations(),
            updateTenantFeatureSettings(),
            updateTenantEnableSftp(),
            updateTenantFeatureAppConfigSettings(),
            updateSearchServiceSelection()
        );

        if (originalFeatures?.sftpEnabled && features?.sftpEnabled) {
            promises.push(updateTenantSftpUserIpAddresses());
        }

        Promise.all(promises).then(() => {
            showLoader(false);
            toaster.push(
                <Message showIcon type="success">
                    {t("tenantConfig.common.successfulUpdateAlertMessage")}
                </Message>,
                {
                    duration: constants.errorAlertDurationMsec,
                }
            );
        });
    };

    const handleLogoModalConfirm = async () => {
        await uploadTenantLogoToBlobContainer();
        updateCompanyLogoUrl();
        await updateAllTenantConfigurations();
        setIsLogoChanged(false);
        setIsUnsavedChangeAvailable(false);
        setLogoConfirmVisible(false);
    };

    const handleLogoModalCancel = async () => {
        setLogoConfirmVisible(false);
        await updateAllTenantConfigurations();
    };

    useRefreshPage(() => {
        if (envMoniker && tenantMoniker) {
            setDataFetched(!isDataFetched);
        }
    });

    useEffect(() => {
        if (!isDataFetched) {
            fetchData();
        }
    }, [isDataFetched]);

    useEffect(() => {
        if (
            isEverySettingWorkFlowUpdated(
                previousFeatureSettingWorkFlows,
                featureSettingWorkFlows
            )
        ) {
            toaster.push(
                <Message showIcon type="success">
                    {t("tenantConfig.common.featureSettingsUpdateAlertMessage")}
                </Message>,
                {
                    duration: constants.errorAlertDurationMsec,
                }
            );
        }
    }, [previousFeatureSettingWorkFlows, featureSettingWorkFlows]);

    useEffect(() => {
        if (formRef && formRef.current) {
            if (formRef.current.check()) {
                setSettingsFormValid(true);
                formRef.current.cleanErrors();
            } else {
                setSettingsFormValid(false);
            }
        }
    }, [formRef, features, setSettingsFormValid, fetchData]);

    // According to the requirement we need to turn off sales tax prediction when coding prediction is off
    useEffect(() => {
        if (
            !features?.codingPredictionEnabled &&
            features?.salesTaxPredictionEnabled
        ) {
            handleTenantFeatureChange("salesTaxPredictionEnabled", false);
        }
    }, [features?.codingPredictionEnabled]);

    if (feturesErrorMessage) {
        return (
            <div className={"pageContainer"}>
                <ErrorSection errorMessage={feturesErrorMessage} />
            </div>
        );
    }

    return (
        (isDataFetched && features && featureAppConfigSettings && (
            <div className="feature-configurations__container">
                <div className="feature-configurations__content">
                    <SignalRTenantFeatureSetting
                        tenantId={tenantId ?? ""}
                        updateTenantFeatureSettingWorkFlows={(
                            settingWorkflow: ITenantFeatureSettingWorkFlow
                        ) => {
                            removeDisabledSettingName(
                                settingWorkflow.featureName
                            );
                            checkWorkflowProgressAndUpdateWorkflow(
                                settingWorkflow
                            );
                        }}
                    >
                        <ConfirmModalContainer
                            handleCancelModal={handleLogoModalCancel}
                            confirmCallbacks={[handleLogoModalConfirm]}
                            isVisible={logoConfirmVisible}
                            message={t(
                                "tenantConfig.featureConfigurations.logoSection.confirmationMessage"
                            )}
                        />
                        <ConfirmModalContainer
                            handleCancelModal={handleRecreateModalCancel}
                            confirmCallbacks={[handleRecreateModalConfirm]}
                            isVisible={searchConfirmVisible}
                            message={t(
                                "tenantConfig.featureConfigurations.recreateSearchIndexConfirmMessage"
                            )}
                        />
                        <h1 className={"feature-configurations__page-header"}>
                            {t("tenantConfig.featureConfigurations.header")}
                        </h1>
                        <h2
                            className={
                                "feature-configurations__page-sub-header"
                            }
                        >
                            {t(
                                "tenantConfig.featureConfigurations.settingsHeader"
                            )}
                        </h2>
                        <h3
                            className={
                                "feature-configurations__header-description"
                            }
                        >
                            {t(
                                "tenantConfig.generalConfigurationPage.settingsSubHeaderText"
                            )}
                            <a
                                className={
                                    "feature-configurations__header-link"
                                }
                                href={
                                    confluenceDocumentLinks?.featuresAndSettingsOverview
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                {t(
                                    "tenantConfig.generalConfigurationPage.settingsSubHeaderLink"
                                )}
                            </a>
                        </h3>
                        {alertRuleDeploymentEnabled && (
                            <Message
                                className={
                                    "feature-configurations__warning-message"
                                }
                                showIcon
                                type="warning"
                            >
                                {t(
                                    "tenantConfig.featureConfigurations.databaseAlertRule.warningMessage"
                                )}
                            </Message>
                        )}
                        <Form
                            ref={formRef}
                            model={formModel}
                            formValue={features}
                        >
                            <FeatureGroups
                                features={features}
                                featureAppConfigSettings={
                                    featureAppConfigSettings
                                }
                                confluenceDocumentLinks={
                                    confluenceDocumentLinks
                                }
                                customerType={customerType}
                                featureSettingWorkFlows={
                                    featureSettingWorkFlows
                                }
                                disabledSettingNames={disabledSettingNames}
                                allWorkflows={allWorkflows}
                                sftpUserWithIpAddresses={
                                    sftpUserWithIpAddresses
                                }
                                areSftpAddressesValid={areSftpAddressesValid}
                                isModifySearchDisabled={isModifySearchDisabled}
                                searchServices={searchServices}
                                linkedSearchService={linkedSearchService}
                                searchServiceSelectionWorkflow={
                                    searchServiceSelectionWorkflow || null
                                }
                                setSftpUserWithIpAddresses={
                                    setSftpUserWithIpAddresses
                                }
                                setSftpAddressesValid={setSftpAddressesValid}
                                handleTenantFeatureChange={
                                    handleTenantFeatureChange
                                }
                                handleTenantFeatureAppConfigSettingChange={
                                    handleTenantFeatureAppConfigSettingChange
                                }
                                handleUpdateClick={handleUpdateClick}
                                handleRecreateClick={handleRecreateClick}
                                setLinkedSearchService={setLinkedSearchService}
                                setIsUnsavedChangeAvailable={
                                    setIsUnsavedChangeAvailable
                                }
                                setDataFetched={setDataFetched}
                            />
                            {isPowerBiWorkspaceDeploymentEnabled && (
                                <>
                                    <Divider />
                                    <Form.Group
                                        className={cn(
                                            "general-configurations__grid-item"
                                        )}
                                    >
                                        <Form.ControlLabel
                                            className={
                                                "general-configurations__label"
                                            }
                                        >
                                            {t(
                                                "tenantConfig.featureConfigurations.powerBiWorkspace.title"
                                            )}
                                            <Form.HelpText
                                                className={
                                                    "general-configurations__help"
                                                }
                                                tooltip
                                            >
                                                {t(
                                                    "tenantConfig.featureConfigurations.powerBiWorkspace.tooltip"
                                                )}
                                            </Form.HelpText>
                                        </Form.ControlLabel>
                                        <div
                                            className={
                                                "feature-configurations__search-index-section"
                                            }
                                        >
                                            <div
                                                className={
                                                    "feature-configurations__modify-search-index-section"
                                                }
                                            >
                                                <Form.Control
                                                    name={"power-bi"}
                                                    disabled={
                                                        !!powerBiWorkspaceDeploymentWorkflow?.id
                                                    }
                                                    accepter={Button}
                                                    className={cn(
                                                        "feature-configurations__general-btn",
                                                        "feature-configurations__power-bi-btn"
                                                    )}
                                                    onClick={() => {
                                                        handleResourceDeployClick(
                                                            TenantFeatureSettingWorkFlowType.PowerBiWorkspaceDeployment
                                                        );
                                                    }}
                                                >
                                                    {t(
                                                        "tenantConfig.featureConfigurations.powerBiWorkspace.createButton"
                                                    )}
                                                </Form.Control>
                                                {powerBiWorkspaceDeploymentWorkflow && (
                                                    <ProcessModalButton
                                                        className={
                                                            "feature-configurations__less-height"
                                                        }
                                                        workflow={
                                                            powerBiWorkspaceDeploymentWorkflow
                                                        }
                                                        workflowType={
                                                            WorkflowModalType.Feature
                                                        }
                                                        triggerRefresh={() => {
                                                            setDataFetched(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </>
                            )}
                            {alertRuleDeploymentEnabled && (
                                <>
                                    <Divider />
                                    <Form.Group
                                        className={cn(
                                            "general-configurations__grid-item"
                                        )}
                                    >
                                        <Form.ControlLabel
                                            className={
                                                "general-configurations__label"
                                            }
                                        >
                                            {t(
                                                "tenantConfig.featureConfigurations.databaseAlertRule.title"
                                            )}
                                            <Form.HelpText
                                                className={
                                                    "general-configurations__help"
                                                }
                                                tooltip
                                            >
                                                {t(
                                                    "tenantConfig.featureConfigurations.databaseAlertRule.tooltip"
                                                )}
                                            </Form.HelpText>
                                        </Form.ControlLabel>
                                        <div
                                            className={
                                                "feature-configurations__search-index-section"
                                            }
                                        >
                                            <div
                                                className={
                                                    "feature-configurations__modify-search-index-section"
                                                }
                                            >
                                                <Form.Control
                                                    name={"power-bi"}
                                                    disabled={
                                                        !!alertRuleDeploymentWorkflow?.id
                                                    }
                                                    accepter={Button}
                                                    className={cn(
                                                        "feature-configurations__general-btn",
                                                        "feature-configurations__power-bi-btn"
                                                    )}
                                                    onClick={() => {
                                                        handleResourceDeployClick(
                                                            TenantFeatureSettingWorkFlowType.DatabaseAlertRuleCreation
                                                        );
                                                    }}
                                                >
                                                    {t(
                                                        "tenantConfig.featureConfigurations.databaseAlertRule.createButton"
                                                    )}
                                                </Form.Control>
                                                {alertRuleDeploymentWorkflow && (
                                                    <ProcessModalButton
                                                        className={
                                                            "feature-configurations__less-height"
                                                        }
                                                        workflow={
                                                            alertRuleDeploymentWorkflow
                                                        }
                                                        workflowType={
                                                            WorkflowModalType.Feature
                                                        }
                                                        triggerRefresh={() => {
                                                            setDataFetched(
                                                                false
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </>
                            )}
                            <div
                                className={cn(
                                    "feature-configurations__subsection-header",
                                    "general-configurations__info-msg"
                                )}
                            >
                                {t(
                                    "tenantConfig.featureConfigurations.tenantFeatureSettings.label"
                                )}
                            </div>
                            <Form.Group
                                className={cn(
                                    "general-configurations__grid-item",
                                    "feature-configurations__less-height"
                                )}
                            >
                                <Checkbox
                                    className={
                                        "feature-configurations__checkbox"
                                    }
                                    value={"vendorPrepaymentRegisterCheck"}
                                    checked={
                                        features.vendorPrepaymentRegisterCheck
                                    }
                                    disabled={featureSettingWorkFlows === null}
                                    onChange={(_, cheked) =>
                                        handleTenantFeatureChange(
                                            "vendorPrepaymentRegisterCheck" as keyof ITenantFeatureConfigurations,
                                            cheked
                                        )
                                    }
                                >
                                    {t(
                                        "tenantConfig.featureConfigurations.vendorPrepaymentRegisterCheck.title"
                                    )}
                                    <Form.HelpText
                                        tooltip
                                        className={
                                            "feature-configurations__help-block"
                                        }
                                    >
                                        {t(
                                            "tenantConfig.featureConfigurations.vendorPrepaymentRegisterCheck.description"
                                        )}
                                    </Form.HelpText>
                                </Checkbox>
                            </Form.Group>
                        </Form>
                        <LogoCustomizationContainer
                            tenantName={tenantName}
                            currentlogoUrl={features.companyLogoUrl}
                            isTenantLogoValid={isTenantLogoValid}
                            logoName={logoName}
                            isPageLoading={!isDataFetched}
                            setIsTenantLogoValid={setIsTenantLogoValid}
                            setIsLogoChanged={setIsLogoChanged}
                            setLogoFileToUpdate={setLogoFile}
                            setLogoName={setLogoName}
                        />
                    </SignalRTenantFeatureSetting>
                </div>
                <SettingsFooterContainer handleFormSubmit={handleFormSubmit} />
            </div>
        )) ||
        null
    );
};

export default FeatureConfigurations;
