import constants from "./../utils/constants";

const useUrlToPathConversion = (url: string) => {
    let parameters: Record<string, string> = {};
    if (url.endsWith("/")) {
        url = url.slice(0, -1);
    }

    const isArraysEqual = (arr1: string[], arr2: string[]) => {
        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                if (arr1[i].charAt(0) !== ":") {
                    return false;
                }

                const routeKey = arr1[i].replace(":", "");

                parameters = {
                    ...parameters,
                    [routeKey]: arr2[i],
                };
            }
        }

        return true;
    };

    const urlArr: string[] = url.split("/");
    const valuesArr = [...Object.values(constants.routePath)];

    const searchedRoute = valuesArr.find((route) => {
        const routeArr = route.split("/");

        return isArraysEqual(routeArr, urlArr);
    });

    return {
        route: searchedRoute,
        params: parameters,
    };
};

export default useUrlToPathConversion;
