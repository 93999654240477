import constants from "../utils/constants";
import i18n from "i18next";

type TErrorResponse = [null, number];
export const defaultErrorResponse: TErrorResponse = [
    null,
    constants.statusCode.InternalServerError,
];

export const getErrorMessage = (errorMessage: string, statusCode: number) => {
    const errMsg = i18n.t("error.defaultError");
    switch (statusCode) {
        case constants.statusCode.UnauthenticatedError:
            // eslint-disable-next-line no-case-declarations
            const msg401 =
                i18n.t("error.error401") + ". " + (errorMessage ?? "");
            console.error(msg401);
            return i18n.t("error.error401");
        case constants.statusCode.BadRequest:
        case constants.statusCode.AccessDenied:
        case constants.statusCode.NotFound:
        case constants.statusCode.InternalServerError:
            return errorMessage !== "null" && errorMessage != ""
                ? String(errorMessage)
                : i18n.t(`error.error${statusCode}`);
        default:
            return errMsg;
    }
};
