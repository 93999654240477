import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ButtonToolbar,
    Form,
    Schema,
    CheckPicker,
    Message,
    FormInstance,
} from "rsuite";
import ModalContainer from "components/Common/Modal/ModalContainer";
import constants from "utils/constants";
import { loadCustomers } from "api/partners/partners-api";
import { ICustomer } from "redux/partners/partners-types";
import { getErrorMessage } from "api/defaults";
import "./AddCustomer.less";

const defaultFormValue = {
    customerIds: [],
};

interface ComponentProps {
    isOpened: boolean;
    partnerCustomers: ICustomer[];
    setIsOpened: (isOpened: boolean) => void;
    addCustomers: (customers: ICustomer[]) => void;
    setModalLoaderVisibility: (visible: boolean) => void;
    setErrorResponseMessageForModal: (message: string) => void;
}

const AddCustomer: React.FC<ComponentProps> = ({
    isOpened,
    partnerCustomers,
    setIsOpened,
    addCustomers,
    setModalLoaderVisibility,
    setErrorResponseMessageForModal,
}: ComponentProps) => {
    const { t } = useTranslation();
    const formRef = useRef<FormInstance>(null);
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [customersCheckPickerItems, setCustomersCheckPickerItems] = useState<
        Record<string, string>[]
    >([]);
    const [isCustomersFetched, setIsCustomersFetched] = useState(false);
    const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>(
        defaultFormValue.customerIds
    );

    const fetchCustomers = () => {
        setModalLoaderVisibility(true);
        setIsCustomersFetched(false);
        loadCustomers().then(([payload, status]) => {
            setModalLoaderVisibility(false);
            setIsCustomersFetched(true);
            if (payload && status === constants.statusCode.OK) {
                const customers = payload.filter(
                    (customer) =>
                        !partnerCustomers.some(
                            (partnerCustomer) =>
                                partnerCustomer.id === customer.id
                        )
                );
                setCustomers(customers);
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                setErrorResponseMessageForModal(errorMessage);
            }
        });
    };

    useEffect(() => {
        if (!isCustomersFetched && isOpened) {
            fetchCustomers();
        }
    }, [isCustomersFetched, setIsCustomersFetched, fetchCustomers]);

    useEffect(() => {
        setCustomersCheckPickerItems(
            customers.sort(compareCustomers).map((value) => {
                return {
                    label: value.name,
                    value: value.id,
                };
            })
        );
    }, [customers, setCustomersCheckPickerItems]);

    const compareCustomers = (c1: ICustomer, c2: ICustomer): number => {
        const customerName1 = c1.name.toLocaleLowerCase() || "";
        const customerName2 = c2.name.toLocaleLowerCase() || "";

        if (customerName1 < customerName2) {
            return -1;
        }

        if (customerName1 > customerName2) {
            return 1;
        }

        return 0;
    };

    const handleAddCustomers = () => {
        if (!formRef.current?.check()) {
            return;
        }

        addCustomers(
            customers.filter((customer) =>
                selectedCustomerIds.some(
                    (customerId) => customerId === customer.id
                )
            )
        );
        handleCloseModal();
    };

    const handleChangeForm = (value: any) => {
        setSelectedCustomerIds(value.customerIds);
        setFormValue(value);
    };

    const handleCloseModal = () => {
        setFormValue(defaultFormValue);
        setIsOpened(false);
        setIsCustomersFetched(false);
        setCustomers([]);
    };

    const renderCustomerMenuItem = (label: ReactNode) => {
        return <div className="add-modal__selector">{label}</div>;
    };

    const formKey = {
        customerIds: "customerIds",
    };

    const model = Schema.Model({
        customerIds: Schema.Types.ArrayType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
    });

    const header = <h1>{t("partnerDetails.addCustomerDialog.title")}</h1>;

    const footer = (
        <ButtonToolbar className="custom-modal__button-toolbar">
            <Button
                type="submit"
                appearance="primary"
                disabled={!isCustomersFetched}
                onClick={handleAddCustomers}
            >
                {t("partnerDetails.addCustomerDialog.add")}
            </Button>
            <Button
                appearance="ghost"
                disabled={!isCustomersFetched}
                onClick={handleCloseModal}
            >
                {t("partnerDetails.addCustomerDialog.cancel")}
            </Button>
        </ButtonToolbar>
    );

    return (
        <ModalContainer
            open={isOpened}
            onClose={handleCloseModal}
            header={header}
            footer={footer}
            className="add-modal__container"
            backdrop="static"
        >
            <>
                <Message showIcon type="info">
                    {
                        <>
                            {t("partnerDetails.addCustomerDialog.infoMessage")}
                            <a
                                href={constants.routePath.customers}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                {t(
                                    "partnerDetails.addCustomerDialog.customersPageLinkTitle"
                                )}
                            </a>
                        </>
                    }
                </Message>
                <Form
                    className="add-modal__form"
                    ref={formRef}
                    model={model}
                    formValue={formValue}
                    onChange={handleChangeForm}
                >
                    <Form.Group className="add-modal__form-group">
                        <Form.ControlLabel>
                            {t(
                                "partnerDetails.addCustomerDialog.customerSelectTitle"
                            )}
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={CheckPicker}
                            data={customersCheckPickerItems}
                            virtualized={false}
                            block
                            placeholder={t(
                                "partnerDetails.addCustomerDialog.customerSelectPlaceholder"
                            )}
                            name={formKey.customerIds}
                            disabled={!isCustomersFetched}
                            renderMenuItem={renderCustomerMenuItem}
                        />
                    </Form.Group>
                </Form>
            </>
        </ModalContainer>
    );
};

export default AddCustomer;
