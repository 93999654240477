import React, { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, Form, FormInstance, InputGroup, Schema } from "rsuite";
import {
    IAxIntegrationApproveData,
    IntegrationFormType,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import { setAutofillFormValues } from "utils/enroll-integration-helper";
import cn from "classnames";
import constants from "utils/constants";
import { validate } from "uuid";
import styles from "./IntegrationInformation.module.css";
import { isPreviousDate } from "utils/date-helper";
import { getExampleImage } from "api/environments/tenants/tenant-enrollments-api";
import Modal from "components/Common/Modal/Modal";
import EyeRoundIcon from "@rsuite/icons/EyeRound";

interface IComponentProps {
    envMoniker: string;
    enrollId: string;
    d365IntegrationInfo: IAxIntegrationApproveData;
    secretFormRef: MutableRefObject<FormInstance<Record<string, any>> | null>;
    isFormDisabled: boolean;
    onSecretFormChange: (data: Record<string, string>) => void;
}

const SecretIntegrationForm: React.FC<IComponentProps> = ({
    envMoniker,
    enrollId,
    d365IntegrationInfo,
    secretFormRef,
    isFormDisabled,
    onSecretFormChange,
}: IComponentProps) => {
    const { t } = useTranslation();

    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [image, setImage] = useState<string>();
    const [isSecretShown, setIsSecretShown] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleModalOpen = (fileName: string) => {
        setModalOpen(true);
        setShowLoader(true);
        getExampleImage(envMoniker, enrollId, fileName).then(
            ([data, statusCode]) => {
                if (statusCode === constants.statusCode.OK && data) {
                    setImage(data);
                } else {
                    if (data) setErrorMessage(data);
                }
                setShowLoader(false);
            }
        );
    };

    const handleModalClose = () => setModalOpen(false);

    const onToggleSecretHandler = () =>
        setIsSecretShown((isSecretShown) => !isSecretShown);

    const onMouseDownSecretHandler = () => {
        setIsSecretShown(true);
    };

    const onTogglePasswordHandle = () =>
        setIsPasswordShown((isPasswordShown) => !isPasswordShown);

    const onMouseDownPasswordHandler = () => {
        setIsPasswordShown(true);
    };

    const onChangeFormHandler = (data: any) => {
        setAutofillFormValues(
            IntegrationFormType.Secret,
            d365IntegrationInfo,
            data,
            onSecretFormChange
        );
    };

    const model = Schema.Model({
        username: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .isEmail(
                t(
                    "enrollTenant.stepFormCommon.validationMessages.isCorrectEmail"
                )
            ),
        password: Schema.Types.StringType().isRequired(
            t("enrollTenant.stepFormCommon.validationMessages.isRequired")
        ),
        axClientId: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .addRule(
                (enteredValue: string) => validate(enteredValue),
                t(
                    "enrollTenant.enrollForm.secretIntegrationForm.appRegistrationIdValidation"
                )
            ),
        axClientSecret: Schema.Types.StringType().isRequired(
            t("enrollTenant.stepFormCommon.validationMessages.isRequired")
        ),
        axUrl: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .pattern(
                constants.urlRegex,
                t("enrollTenant.stepFormCommon.validationMessages.isCorrectUrl")
            ),
        azureAdResource: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .pattern(
                constants.urlRegex,
                t("enrollTenant.stepFormCommon.validationMessages.isCorrectUrl")
            ),
        secretExpirationDate: Schema.Types.DateType().isRequired(
            t("enrollTenant.stepFormCommon.validationMessages.isRequired")
        ),
        axAuthority: Schema.Types.StringType()
            .isRequired(
                t("enrollTenant.stepFormCommon.validationMessages.isRequired")
            )
            .addRule((d365AuthorityValue) => {
                return d365AuthorityValue.includes(".");
            }, t("enrollTenant.stepFormCommon.validationMessages.doesContainDot")),
    });

    return (
        <>
            <Form
                ref={secretFormRef}
                model={model}
                formValue={d365IntegrationInfo}
                className={styles.formContainer}
                onChange={(data) => {
                    onChangeFormHandler(data);
                }}
            >
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.enrollForm.usernameTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"username"}
                        placeholder={t(
                            "enrollTenant.enrollForm.usernamePlaceholder"
                        )}
                        className={styles.formControl}
                    />
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.enrollForm.passwordTitle")}
                    </Form.ControlLabel>
                    <InputGroup disabled={isFormDisabled} inside>
                        <Form.Control
                            name={"password"}
                            type={isPasswordShown ? "text" : "password"}
                            placeholder={t(
                                "enrollTenant.enrollForm.passwordPlaceholder"
                            )}
                            className={styles.formControl}
                        />
                        <InputGroup.Addon
                            onClick={onTogglePasswordHandle}
                            onMouseDown={onMouseDownPasswordHandler}
                            className={styles.imageSection}
                        >
                            <EyeRoundIcon />
                        </InputGroup.Addon>
                    </InputGroup>
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t(
                            "enrollTenant.enrollForm.secretIntegrationForm.appRegistrationIdTitle"
                        )}
                        <a
                            onClick={() => {
                                handleModalOpen(constants.applicationIdImage);
                            }}
                        >
                            {t(
                                "enrollTenant.enrollForm.secretIntegrationForm.exampleText"
                            )}
                        </a>
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"axClientId"}
                        placeholder={t(
                            "enrollTenant.enrollForm.secretIntegrationForm.appRegistrationIdPlaceholder"
                        )}
                        className={cn(
                            styles.formControl,
                            styles.formFieldWider
                        )}
                    />
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t(
                            "enrollTenant.enrollForm.secretIntegrationForm.appRegistrationSecretTitle"
                        )}
                        <a
                            onClick={() => {
                                handleModalOpen(constants.secretValueImage);
                            }}
                        >
                            {t(
                                "enrollTenant.enrollForm.secretIntegrationForm.exampleText"
                            )}
                        </a>
                    </Form.ControlLabel>
                    <InputGroup disabled={isFormDisabled} inside>
                        <Form.Control
                            name={"axClientSecret"}
                            type={isSecretShown ? "text" : "password"}
                            placeholder={t(
                                "enrollTenant.enrollForm.secretIntegrationForm.appRegistrationSecretPlaceholder"
                            )}
                            className={styles.formControl}
                        />
                        <InputGroup.Addon
                            onClick={onToggleSecretHandler}
                            onMouseDown={onMouseDownSecretHandler}
                            className={styles.imageSection}
                        >
                            <EyeRoundIcon />
                        </InputGroup.Addon>
                    </InputGroup>
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.enrollForm.d365UrlTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"axUrl"}
                        placeholder={t(
                            "enrollTenant.enrollForm.d365Placeholder"
                        )}
                        className={cn(
                            styles.formControl,
                            styles.formFieldWider
                        )}
                    />
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.enrollForm.azureAdResourceTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"azureAdResource"}
                        placeholder={t(
                            "enrollTenant.enrollForm.azureAdResourcePlaceholder"
                        )}
                        className={cn(
                            styles.formControl,
                            styles.formFieldWider
                        )}
                    />
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t(
                            "enrollTenant.enrollForm.secretIntegrationForm.axSecretExpirationTitle"
                        )}
                    </Form.ControlLabel>
                    <Form.Control
                        accepter={DatePicker}
                        disabled={isFormDisabled}
                        name={"secretExpirationDate"}
                        className={styles.formControl}
                        cleanable={false}
                        ranges={[]}
                        placement={"topStart"}
                        disabledDate={(date: Date | undefined) =>
                            date ? isPreviousDate(date) : false
                        }
                    />
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.enrollForm.tokenUrlTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        readOnly
                        name={"tokenUrl"}
                        className={cn(
                            styles.formControl,
                            styles.formFieldDisabled,
                            styles.formFieldWider
                        )}
                    />
                </Form.Group>
                <Form.Group className={styles.formFieldSection}>
                    <Form.ControlLabel className={styles.label}>
                        {t("enrollTenant.enrollForm.d365AuthorityTitle")}
                    </Form.ControlLabel>
                    <Form.Control
                        disabled={isFormDisabled}
                        name={"axAuthority"}
                        placeholder={t(
                            "enrollTenant.enrollForm.d365AuthorityPlaceholder"
                        )}
                        className={cn(styles.formControl)}
                    />
                </Form.Group>
            </Form>
            <Modal
                className={styles.modal}
                isModalLoaderVisible={showLoader}
                isModalOpen={modalOpen}
                onClose={handleModalClose}
                closeButton={true}
                errorMessage={errorMessage}
                footer={null}
            >
                <img src={`data:image/png;base64, ${image}`}></img>
            </Modal>
        </>
    );
};

export default SecretIntegrationForm;
