import { AppThunk } from "../store";
import { setConfluenceDocumentLinks } from "./information-links-actions";
import { loadConfluenceDocumentLinks } from "../../api/information-links-api";
import { setErrorResponseMessage } from "redux/common/initialization/initialization-actions";
import constants from "utils/constants";
import { getErrorMessage } from "../../api/defaults";
import { CancelTokenSource } from "axios";

export const fetchConfluenceDocumentLinks =
    (cancelTokenSource?: CancelTokenSource): AppThunk =>
    async (dispatch) => {
        dispatch(setErrorResponseMessage(""));

        const [payload, status] =
            await loadConfluenceDocumentLinks(cancelTokenSource);

        if (payload && status === constants.statusCode.OK) {
            dispatch(setConfluenceDocumentLinks(payload));
        } else {
            const errorMessage = getErrorMessage(String(payload), status);

            dispatch(setErrorResponseMessage(errorMessage));
        }
    };
