import {
    Actions,
    ITenant,
    SET_ACTIVE_TENANT,
    SET_ACTIVE_TENANT_BY_ID,
    SET_IS_TENANT_FETCHED,
    SET_TENANTS,
    State,
    TenantStatus,
    UPDATE_ACTIVE_TENANT_DISPLAY_NAME,
    UPDATE_TENANT_VERSION_INFO,
} from "./tenants-types";

const initialState: State = {
    tenants: [],
    activeTenant: {
        enrollWorkflowId: "",
        moniker: "",
        customerName: "",
        customerType: null,
        partners: [],
        packageDeliveryGroup: null,
        versionInfo: {
            name: "",
            id: "",
            d365ResponseTime: 0,
            fetchStatus: 0,
            fetchErrorMessage: null,
            refreshParametersStatus: 0,
            refreshParametersErrorMessage: null,
            applicationVersion: null,
            dooapVersion: null,
            platformVersion: null,
            lastSyncTime: null,
            authenticationMethod: null,
            d365ThrottlingPriority: null,
            d365ThrottlingPriorityErrorMessage: null,
            d365ThrottlingPriorityStatus: null,
        },
        dooapVersionStatus: TenantStatus.Normal,
        dooapStatusMessage: "",
    },
    isActiveTenantSet: false,
    isTenantFetched: false,
};
const tenantsReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_TENANTS:
            return {
                ...state,
                tenants: [...action.tenants],
                activeTenant: initialState.activeTenant,
                isActiveTenantSet: false,
            };

        case SET_ACTIVE_TENANT:
            return {
                ...state,
                activeTenant: action.activeTenant,
                isActiveTenantSet: true,
            };

        case UPDATE_ACTIVE_TENANT_DISPLAY_NAME:
            let activeTenantTmp: ITenant = state.activeTenant;
            const updatedTenantList = state.tenants.map((tenant) => {
                if (
                    tenant.versionInfo.id === state.activeTenant.versionInfo.id
                ) {
                    activeTenantTmp = {
                        ...tenant,
                        versionInfo: {
                            ...tenant.versionInfo,
                            name: action.name.trim(),
                        },
                    };
                    return activeTenantTmp;
                }
                return { ...tenant };
            });

            return {
                ...state,
                tenants: [...updatedTenantList],
                activeTenant: {
                    ...activeTenantTmp,
                    versionInfo: {
                        ...activeTenantTmp.versionInfo,
                        name: action.name,
                    },
                },
            };

        case SET_ACTIVE_TENANT_BY_ID:
            const activeTenant = state.tenants.find((tenant) => {
                return tenant.versionInfo.id === action.id;
            });

            if (!activeTenant) {
                return {
                    ...state,
                    activeTenant: initialState.activeTenant,
                    isActiveTenantSet: false,
                };
            }

            return {
                ...state,
                activeTenant: activeTenant,
                isActiveTenantSet: true,
            };

        case SET_IS_TENANT_FETCHED:
            return { ...state, isTenantFetched: action.isFetched };

        case UPDATE_TENANT_VERSION_INFO:
            return {
                ...state,
                tenants: state.tenants.map((tenant) =>
                    tenant.moniker === action.moniker
                        ? { ...tenant, versionInfo: action.versionInfo }
                        : tenant
                ),
            };

        default:
            return state;
    }
};

export default tenantsReducer;
