import React, { SyntheticEvent, useEffect, useState } from "react";
import {
    D365RefreshParametersStatus,
    ITenant,
    ITenantPingInfo,
    IVersionInfo,
    TenantStatus,
} from "redux/environments/tenants/tenants-types";
import "./ListView.less";
import {
    Button,
    Loader,
    Message,
    Popover,
    Table,
    Tooltip,
    useToaster,
    Whisper,
} from "rsuite";
import { useTranslation } from "react-i18next";
import { getComplexStatusString, getPingStatus } from "utils/status-helper";
import cn from "classnames";
import constants from "utils/constants";
import { formatDateWithTime } from "utils/date-helper";
import { useHistory } from "react-router-dom";
import ModalContainer from "components/Common/Modal/ModalContainer";
import classNames from "classnames";
import RefreshButton from "components/Common/RefreshButton/RefreshButton";
import { formatString } from "utils/regex-helper";
import { ITenantVersionInfo } from "redux/environments/tenants/tenant-status/tenant-status-types";
import useRequestCancellation from "hooks/useRequestCancellation";
import { fetchTenantInfo } from "api/environments/tenants/tenant-erp-info-api";
import { getErrorMessage as getErrorResponseMessage } from "api/defaults";
import Axios from "axios";
import CopyIcon from "@rsuite/icons/Copy";
import GlobalIcon from "@rsuite/icons/Global";

interface ErrorMessage {
    message: string | null | undefined;
    title: string;
}

interface ComponentProps {
    modifiedTenants: ITenant[];
    activeEnvMoniker: string;
    activeEnvironmentName: string;
    tenantsPingInfo: ITenantPingInfo[];
    isD365ParametersRefreshAllowed: boolean;
    containerRef: React.RefObject<HTMLDivElement>;
    isLoaderVisible: boolean;
    seqLogsLink: string;
    isUserPartner: boolean;
    isDeliverablesEnabled: boolean;
    routeToTenant: (tenant: ITenant) => void;
    getErpVersion: (tenant: ITenant, value: string) => JSX.Element;
    getVersionStatusMessage: (tenant: ITenant) => string;
    getFetchStatusText: (fetchStatus: TenantStatus) => string;
    getPingStatusText: (pingInfo: ITenantPingInfo | undefined) => string;
    setTenantStatusOpened: (isOpened: boolean) => void;
    setTenantStatusInfo: (versionInfo: ITenantVersionInfo) => void;
    setTenantMoniker: (tenantMoniker: string) => void;
    getRefreshParametersText: (
        status: number,
        message: string | null
    ) => string;
    updateTenantVersionInfo: (
        tenantMoniker: string,
        versionInfo: IVersionInfo
    ) => void;
}
const ListView: React.FC<ComponentProps> = ({
    modifiedTenants,
    activeEnvMoniker,
    activeEnvironmentName,
    tenantsPingInfo,
    isD365ParametersRefreshAllowed,
    containerRef,
    isLoaderVisible,
    seqLogsLink,
    isUserPartner,
    isDeliverablesEnabled,
    routeToTenant,
    getErpVersion,
    getVersionStatusMessage,
    getFetchStatusText,
    getPingStatusText,
    getRefreshParametersText,
    setTenantStatusOpened,
    setTenantStatusInfo,
    setTenantMoniker,
    updateTenantVersionInfo,
}: ComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const [sortColumn, setSortColumn] = useState<string>();
    const [sortType, setSortType] = useState();
    const routeHistory = useHistory();
    const [isOpen, setOpen] = useState(false);
    const [tableHeight, setTableHeight] = useState<number>();
    const [tenantName, setTenantName] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
        message: "",
        title: "",
    });
    const [isErrorStatusClicked, setIsErrorStatusClicked] = useState(false);
    const [modalContent, setModalContent] = useState<JSX.Element>();
    const [copyFieldValueMessage, setCopyFieldValueMessage] = useState(
        t(
            "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
        )
    );
    const [fetchLoadingForMonikers, setFetchLoadingForMonikers] = useState<
        string[]
    >([]);

    const cancelTokenSource = useRequestCancellation();

    const getColumnValue = (tenant: ITenant) => {
        const status = getComplexStatusString(
            tenant.versionInfo.fetchStatus,
            getTenantPingStatus(tenant.moniker)
        );
        const isEnroll = isTenantEnroll(tenant.versionInfo.fetchStatus);

        switch (sortColumn) {
            case "status":
                return status;
            case "pingStatus":
                return isEnroll
                    ? "-"
                    : getPingStatusText(
                          tenantsPingInfo.find(
                              (item) => item.tenantId === tenant.versionInfo.id
                          )
                      );
            case "fetchStatus":
                return isEnroll
                    ? "-"
                    : getFetchStatusText(tenant.versionInfo.fetchStatus);
            case "refreshParametersStatus":
                return isEnroll
                    ? "-"
                    : getRefreshParametersText(
                          tenant.versionInfo.refreshParametersStatus,
                          tenant.versionInfo.refreshParametersErrorMessage
                      );
            default:
                return (
                    tenant[sortColumn as keyof ITenant] ||
                    tenant.versionInfo[sortColumn as keyof IVersionInfo]
                );
        }
    };

    const getData = () => {
        if (sortColumn && sortType) {
            const unknown = t("unknown");
            return modifiedTenants.sort((a, b) => {
                let x = getColumnValue(a) || unknown;
                let y = getColumnValue(b) || unknown;
                x = typeof x === "string" ? x.toLowerCase() : x;
                y = typeof y === "string" ? y.toLowerCase() : y;

                const comparison =
                    (x === y ? 0 : x < y ? -1 : 1) *
                    (sortType === "asc" ? 1 : -1);

                if (comparison === 0) {
                    return (a.moniker ?? unknown).localeCompare(
                        b.moniker ?? unknown
                    );
                }

                return comparison;
            });
        }

        return modifiedTenants;
    };

    const handleSortColumn = (sortColumn: string, sortType: any) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
    };

    const getTenantPingStatus = (id: string) => {
        const pingInfo = tenantsPingInfo.find((item) => item.tenantId === id);
        return getPingStatus(pingInfo);
    };

    const getStatusInfo = (tenant: ITenant) => {
        if (fetchLoadingForMonikers.includes(tenant.moniker)) {
            return { className: "list__status-block--disabled", title: "" };
        }
        const status = getComplexStatusString(
            tenant.versionInfo.fetchStatus,
            getTenantPingStatus(tenant.versionInfo.id)
        );
        let className: string;
        let title: string;
        switch (status) {
            case "error":
                className = "list__status-block--error";
                title = t("status.error");
                break;
            case "enroll":
                className = "list__status-block--enroll";
                title = t("status.enroll");
                break;
            case "deleting":
                className = "list__status-block--deleting";
                title = t("status.deleting");
                break;
            case "normal":
            default:
                className = "list__status-block--normal";
                title = t("status.normal");
                break;
        }
        return { className, title };
    };

    const getCellValueWithTooltip = (value: string, length: number) => {
        return value.length > length ? (
            <Whisper
                trigger="hover"
                placement={"bottomStart"}
                delay={200}
                delayClose={0}
                speaker={<Popover className="list__popover">{value}</Popover>}
            >
                <div>{value}</div>
            </Whisper>
        ) : (
            <div>{value}</div>
        );
    };

    const isTenantEnroll = (fetchStatus: number) => {
        return fetchStatus === TenantStatus.Enroll;
    };

    const getVersionValue = (fetchStatus: number, version: string | null) => {
        return !isTenantEnroll(fetchStatus) && version
            ? getCellValueWithTooltip(
                  version ?? "",
                  constants.tenantListLengths.short
              )
            : "-";
    };

    const routeToEnrollGuestTenants = (
        event: SyntheticEvent,
        enrollId: string
    ) => {
        event.stopPropagation();

        routeHistory.push(
            `/environments/${activeEnvironmentName}/enroll-tenant/${enrollId}/guest-tenants`
        );
    };

    const routeToGuestTenants = (event: SyntheticEvent, tenantId: string) => {
        event.stopPropagation();

        routeHistory.push(
            `/environments/${activeEnvironmentName}/tenants/${tenantId}/guest-tenants/list`
        );
    };

    const getTenantLink = (tenant: ITenant) => {
        return (
            <a className="list__link" onClick={() => routeToTenant(tenant)}>
                {tenant.versionInfo.name}
            </a>
        );
    };

    const getFieldCopyElement = (value: string) => {
        return (
            <Whisper
                placement="right"
                trigger="hover"
                speaker={<Tooltip>{copyFieldValueMessage}</Tooltip>}
            >
                <CopyIcon
                    className="list__copy-button"
                    onClick={(event: SyntheticEvent) => {
                        event.stopPropagation();
                        setCopyFieldValueMessage(
                            t(
                                "tenantConfig.generalConfigurationPage.copyFieldValue.copied"
                            )
                        );
                        navigator.clipboard.writeText(value);
                    }}
                    onMouseLeave={() =>
                        setCopyFieldValueMessage(
                            t(
                                "tenantConfig.generalConfigurationPage.copyFieldValue.copyToClipboard"
                            )
                        )
                    }
                />
            </Whisper>
        );
    };

    const getErrorModalContent = (
        errorMessage: JSX.Element,
        tenantName: string
    ) => {
        return (
            <div className={"tenant__modal__content"}>
                <span>
                    {`${t(
                        "tenantDashboard.statusPopup.tenantName"
                    )}: ${tenantName}`}
                </span>
                <div className="tenant__modal__error-details__container">
                    {errorMessage}
                </div>
            </div>
        );
    };

    const getErrorMessage = (
        errorMessage: string | null | undefined,
        errorTitle: string
    ) => {
        return errorMessage ? (
            <div>
                <span>{`${errorTitle}: `}</span>
                <span className="tenant__modal__error-details">
                    {errorMessage}
                </span>
            </div>
        ) : (
            <></>
        );
    };

    const hideStatusPopup = (event: SyntheticEvent<Element, Event>) => {
        event.stopPropagation();
        setOpen(false);
        setIsErrorStatusClicked(false);
    };

    const fetch = async (moniker: string, tenantId: string) => {
        setFetchLoadingForMonikers([...fetchLoadingForMonikers, moniker]);
        fetchTenantInfo(activeEnvMoniker, tenantId, cancelTokenSource)
            .then(([payload, statusCode]) => {
                if (payload && statusCode === constants.statusCode.OK) {
                    updateTenantVersionInfo(moniker, payload);
                } else {
                    const errorMessage = getErrorResponseMessage(
                        String(payload),
                        statusCode
                    );
                    toaster.push(
                        <Message showIcon type="info" closable>
                            {errorMessage}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                }
                setFetchLoadingForMonikers(
                    fetchLoadingForMonikers.filter((m) => m !== moniker)
                );
            })
            .catch((error: Error) => {
                if (!Axios.isCancel(error)) {
                    toaster.push(
                        <Message showIcon type="info" closable>
                            {error}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                }
            });
    };

    const updateTableHeight = () => {
        if (containerRef.current) {
            const tableHeight = containerRef.current.clientHeight * 0.9;
            setTableHeight(tableHeight);
        }
    };

    useEffect(() => {
        if (!isLoaderVisible) {
            updateTableHeight();
        }
    }, [isLoaderVisible]);

    useEffect(() => {
        window.addEventListener("resize", updateTableHeight);
    }, []);

    useEffect(() => {
        if (isErrorStatusClicked) {
            setOpen(true);
            setModalContent(
                getErrorModalContent(
                    getErrorMessage(errorMessage.message, errorMessage.title),
                    tenantName
                )
            );
        }
    }, [isErrorStatusClicked]);

    return (
        <>
            {!isLoaderVisible && (
                <div ref={containerRef} className={"list__layout"}>
                    <ModalContainer
                        className="tenant__modal"
                        enforceFocus={true}
                        onClose={(event: any) => hideStatusPopup(event)}
                        open={isOpen}
                        footer={
                            <Button
                                className="tenant__modal__ok-btn"
                                onClick={(event) => hideStatusPopup(event)}
                            >
                                {t("tenantDashboard.statusPopup.buttonText")}
                            </Button>
                        }
                        header={
                            <span className="tenant__modal__title">
                                {t("tenantDashboard.statusPopup.statusInfo")}
                            </span>
                        }
                    >
                        {modalContent}
                    </ModalContainer>
                    <Table
                        height={tableHeight}
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        shouldUpdateScroll={false}
                        virtualized={true}
                    >
                        <Table.Column width={120} fixed sortable>
                            <Table.HeaderCell>
                                {t("tenantDashboard.listView.statusHeader")}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="status">
                                {(rowData: ITenant) => {
                                    const { className, title } =
                                        getStatusInfo(rowData);
                                    return (
                                        <div className="list__status-container">
                                            {!isTenantEnroll(
                                                rowData.versionInfo.fetchStatus
                                            ) && (
                                                <RefreshButton
                                                    onClick={() =>
                                                        fetch(
                                                            rowData.moniker,
                                                            rowData.versionInfo
                                                                .id
                                                        )
                                                    }
                                                    disabled={fetchLoadingForMonikers.includes(
                                                        rowData.moniker
                                                    )}
                                                    tooltip={t(
                                                        "tenantDashboard.listView.fetchTooltip"
                                                    )}
                                                    tooltipPlacement={
                                                        "topStart"
                                                    }
                                                />
                                            )}
                                            <div
                                                className={cn(
                                                    "list__status-block",
                                                    className
                                                )}
                                                onClick={() => {
                                                    if (
                                                        isTenantEnroll(
                                                            rowData.versionInfo
                                                                .fetchStatus
                                                        )
                                                    ) {
                                                        return;
                                                    }
                                                    setTenantMoniker(
                                                        rowData.moniker
                                                    );
                                                    setTenantStatusInfo(
                                                        rowData.versionInfo
                                                    );
                                                    setTenantStatusOpened(true);
                                                }}
                                            >
                                                {fetchLoadingForMonikers.includes(
                                                    rowData.moniker
                                                ) ? (
                                                    <Loader size="xs" />
                                                ) : (
                                                    <>{title}</>
                                                )}
                                            </div>
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={250} fixed sortable>
                            <Table.HeaderCell>
                                {t("tenantDashboard.listView.tenantNameHeader")}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="name">
                                {(rowData: ITenant) => {
                                    const tenantName = rowData.versionInfo.name;
                                    return (
                                        <div className="list__field--justify">
                                            {tenantName.length >
                                            constants.tenantListLengths.long ? (
                                                <Whisper
                                                    trigger="hover"
                                                    placement={"bottomStart"}
                                                    delay={200}
                                                    delayClose={0}
                                                    speaker={
                                                        <Popover className="list__popover">
                                                            {tenantName}
                                                        </Popover>
                                                    }
                                                >
                                                    {getTenantLink(rowData)}
                                                </Whisper>
                                            ) : (
                                                getTenantLink(rowData)
                                            )}
                                            {getFieldCopyElement(
                                                rowData.versionInfo.name
                                            )}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={200} sortable>
                            <Table.HeaderCell>
                                {t("tenantDashboard.listView.customerHeader")}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="customerName">
                                {(rowData: ITenant) => {
                                    return getCellValueWithTooltip(
                                        rowData.customerName ?? "",
                                        constants.tenantListLengths.middle
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={100} sortable>
                            <Table.HeaderCell>
                                {t("tenantDashboard.listView.monikerHeader")}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="moniker">
                                {(rowData: ITenant) => {
                                    return (
                                        <div className="list__field--justify">
                                            {rowData.moniker}
                                            {getFieldCopyElement(
                                                rowData.moniker
                                            )}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={200}>
                            <Table.HeaderCell>
                                {t("tenantDashboard.listView.partnersHeader")}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="partners">
                                {(rowData: ITenant) => {
                                    return rowData.partners?.length
                                        ? getCellValueWithTooltip(
                                              rowData.partners.join(", "),
                                              constants.tenantListLengths.middle
                                          )
                                        : "-";
                                }}
                            </Table.Cell>
                        </Table.Column>
                        {isD365ParametersRefreshAllowed && (
                            <Table.Column width={150} sortable>
                                <Table.HeaderCell>
                                    {t(
                                        "tenantDashboard.listView.refreshParametersHeader"
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="refreshParametersStatus">
                                    {(rowData: ITenant) => {
                                        const message =
                                            rowData.versionInfo
                                                .refreshParametersErrorMessage;
                                        const status =
                                            rowData.versionInfo
                                                .refreshParametersStatus;
                                        return !isTenantEnroll(
                                            rowData.versionInfo.fetchStatus
                                        ) ? (
                                            status ===
                                                D365RefreshParametersStatus.Failed &&
                                            message ? (
                                                <p
                                                    className={classNames(
                                                        "tenant__status-button",
                                                        "tenant__status-button--enabled"
                                                    )}
                                                    onClick={() => {
                                                        setTenantName(
                                                            rowData.versionInfo
                                                                .name
                                                        );
                                                        setErrorMessage({
                                                            message: message,
                                                            title: t(
                                                                "tenantDashboard.statusPopup.refreshParametersErrorMessage"
                                                            ),
                                                        });
                                                        setIsErrorStatusClicked(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    {getRefreshParametersText(
                                                        status,
                                                        message
                                                    )}
                                                </p>
                                            ) : (
                                                <span>
                                                    <b>
                                                        {getRefreshParametersText(
                                                            status,
                                                            message
                                                        )}
                                                    </b>
                                                </span>
                                            )
                                        ) : (
                                            "-"
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        )}
                        <Table.Column width={100} sortable>
                            <Table.HeaderCell>
                                {t(
                                    "tenantDashboard.listView.dooapVersionHeader"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="dooapVersion">
                                {(rowData: ITenant) => {
                                    const message =
                                        getVersionStatusMessage(rowData);
                                    const dooapVersion =
                                        rowData.versionInfo.dooapVersion;
                                    return !isTenantEnroll(
                                        rowData.versionInfo.fetchStatus
                                    ) && dooapVersion ? (
                                        dooapVersion.length >
                                            constants.tenantListLengths.short ||
                                        message.length >
                                            constants.tenantListLengths
                                                .short ? (
                                            <Whisper
                                                trigger="hover"
                                                placement={"bottomStart"}
                                                delay={200}
                                                delayClose={0}
                                                speaker={
                                                    <Popover className="list__popover">
                                                        {message}
                                                    </Popover>
                                                }
                                            >
                                                {getErpVersion(
                                                    rowData,
                                                    dooapVersion
                                                )}
                                            </Whisper>
                                        ) : (
                                            getErpVersion(rowData, dooapVersion)
                                        )
                                    ) : (
                                        "-"
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={150} sortable>
                            <Table.HeaderCell>
                                {t(
                                    "tenantDashboard.listView.applicationVersionHeader"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="applicationVersion">
                                {(rowData: ITenant) => {
                                    return getVersionValue(
                                        rowData.versionInfo.fetchStatus,
                                        rowData.versionInfo.applicationVersion
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={150} sortable>
                            <Table.HeaderCell>
                                {t(
                                    "tenantDashboard.listView.platformVersionHeader"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="platformVersion">
                                {(rowData: ITenant) => {
                                    return getVersionValue(
                                        rowData.versionInfo.fetchStatus,
                                        rowData.versionInfo.platformVersion
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={370}>
                            <Table.HeaderCell>
                                {t("tenantDashboard.listView.idHeader")}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="id">
                                {(rowData: ITenant) => {
                                    return (
                                        <div className="list__field--justify">
                                            {rowData.versionInfo.id}
                                            {getFieldCopyElement(
                                                rowData.versionInfo.id
                                            )}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        {!isUserPartner && (
                            <Table.Column width={100}>
                                <Table.HeaderCell>
                                    {t("tenantDashboard.listView.linkHeader")}
                                </Table.HeaderCell>
                                <Table.Cell
                                    dataKey="seqLogsLink"
                                    className="list_cell--centralized"
                                >
                                    {(rowData: ITenant) => {
                                        return rowData.versionInfo.id ? (
                                            <a
                                                href={formatString(
                                                    constants.seqLogsFilter,
                                                    seqLogsLink,
                                                    rowData.versionInfo.id
                                                )}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="list__link"
                                            >
                                                <GlobalIcon />
                                            </a>
                                        ) : (
                                            "-"
                                        );
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        )}
                        {isDeliverablesEnabled && (
                            <Table.Column width={300} sortable>
                                <Table.HeaderCell>
                                    {t(
                                        "tenantDashboard.listView.deliveryGroupHeader"
                                    )}
                                </Table.HeaderCell>
                                <Table.Cell dataKey="packageDeliveryGroup">
                                    {(rowData: ITenant) => {
                                        return !isTenantEnroll(
                                            rowData.versionInfo.fetchStatus
                                        ) && rowData.packageDeliveryGroup
                                            ? rowData.packageDeliveryGroup
                                            : "-";
                                    }}
                                </Table.Cell>
                            </Table.Column>
                        )}

                        <Table.Column width={200} sortable>
                            <Table.HeaderCell>
                                {t("tenantDashboard.listView.lastUpdateHeader")}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="lastSyncTime">
                                {(rowData: ITenant) => {
                                    return !isTenantEnroll(
                                        rowData.versionInfo.fetchStatus
                                    ) && rowData.versionInfo.lastSyncTime
                                        ? formatDateWithTime(
                                              new Date(
                                                  rowData.versionInfo.lastSyncTime
                                              )
                                          )
                                        : "-";
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={130}>
                            <Table.HeaderCell>
                                {t(
                                    "tenantDashboard.listView.guestTenantsHeader"
                                )}
                            </Table.HeaderCell>
                            <Table.Cell dataKey="guestTenant">
                                {(rowData: ITenant) => {
                                    return rowData.moniker ? (
                                        <a
                                            onClick={(event) =>
                                                rowData.enrollWorkflowId
                                                    ? routeToEnrollGuestTenants(
                                                          event,
                                                          rowData.enrollWorkflowId
                                                      )
                                                    : routeToGuestTenants(
                                                          event,
                                                          rowData.versionInfo.id
                                                      )
                                            }
                                            className="list__link"
                                        >
                                            {t(
                                                "tenantDashboard.listView.showGuestTenantBtn"
                                            )}
                                        </a>
                                    ) : (
                                        "-"
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
            )}
        </>
    );
};

export default ListView;
