import { RootState } from "redux/store";
import { connect } from "react-redux";
import Partners from "./Partners";
import {
    setErrorResponseMessage,
    setLoaderVisibility,
} from "redux/common/initialization/initialization-actions";
import {
    setAddNewPartner,
    setActivePartner,
    setPartners,
} from "redux/partners/partners-actions";
import { getPartnerFilterSearchText } from "redux/filters/filters-selectors";
import { getPartners } from "redux/partners/partners-selectors";
import { fetchPartners } from "redux/partners/partners-thunks";

const mapStateToProps = (state: RootState) => {
    return {
        searchText: getPartnerFilterSearchText(state),
        isAddNewPartnerOpened: state.partnersReducer.isAddNewPartnerOpened,
        errorMessage: state.initializationReducer.errorResponseMessage,
        partners: getPartners(state),
    };
};
const PartnersContainer = connect(mapStateToProps, {
    fetchPartners,
    setLoaderVisibility,
    setAddNewPartner,
    setActivePartner,
    setPartners,
    setErrorResponseMessage,
})(Partners) as React.FC;

export default PartnersContainer;
