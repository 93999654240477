import { template } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, useToaster } from "rsuite";
import css from "components/BreadCrumb/BreadCrumb.module.css";
import ConfirmModalContainer from "components/Common/ConfirmModal/ConfirmModalContainer";
import { deletePartner } from "api/partners/partners-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import { useHistory } from "react-router-dom";
import cn from "classnames";

interface ComponentProps {
    partnerName: string;
    partnerId: string;
    setLoaderVisibility: (visible: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
}

const DeletePartnerButton: React.FC<ComponentProps> = ({
    partnerName,
    partnerId,
    setLoaderVisibility,
    setIsUnsavedChangeAvailable,
}: ComponentProps) => {
    const { t } = useTranslation();
    const routeHistory = useHistory();
    const toaster = useToaster();
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmationDialogMessage, setConfirmationDialogMessage] =
        useState("");

    const handleCancel = () => {
        setConfirmVisible(false);
    };

    const handleConfirm = async () => {
        setLoaderVisibility(true);
        deletePartner(partnerId).then(([payload, status]) => {
            if (status === constants.statusCode.OK) {
                toaster.push(
                    <Message showIcon type="success">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: template(
                                    t("partners.deleteSuccessMessage")
                                )({
                                    partnerName: partnerName,
                                }),
                            }}
                        />
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
                routeHistory.push(`/partners`);
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                toaster.push(
                    <Message showIcon type="error">
                        {errorMessage}
                    </Message>
                );
            }
            setLoaderVisibility(false);
        });
        setConfirmVisible(false);
        setIsUnsavedChangeAvailable(false);
    };

    const handleDelete = () => {
        setConfirmVisible(true);
    };

    useEffect(() => {
        if (partnerName) {
            setConfirmationDialogMessage(
                template(t("partners.deleteConfirmationMessage"))({
                    partnerName: partnerName,
                })
            );
        }
    }, [partnerName]);

    return (
        <>
            <ConfirmModalContainer
                handleCancelModal={handleCancel}
                confirmCallbacks={[handleConfirm]}
                isVisible={confirmVisible}
                message={confirmationDialogMessage}
            />

            <Button
                onClick={handleDelete}
                appearance="primary"
                className={cn(css.addNewCustomerBtn, css.deleteBtn)}
            >
                {t("partners.deleteButton")}
            </Button>
        </>
    );
};

export default DeletePartnerButton;
