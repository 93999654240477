import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import FolderIcon from "@rsuite/icons/Folder";
import PageIcon from "@rsuite/icons/Page";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";

import leftMenu from "./left-menu-structure";

import { IConvertedItem, useRoutesConvertor } from "hooks/useRoutesConvertor";

import { ITenant } from "redux/environments/tenants/tenants-types";
import { CustomerType } from "redux/customers/add-new-customer/add-new-customer-types";
import { IAppSettings } from "redux/app-settings/app-settings-types";
import { ITenantDeleteWorkflow } from "utils/tenant-workflows/tenant-workflows-types";
import constants from "utils/constants";

import { fetchAppSettings } from "redux/app-settings/app-settings-thunks";

import css from "./LeftMenu.module.css";
import { template } from "lodash";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    appSettings: IAppSettings;
    activeTenant: ITenant;
    customerType: CustomerType | null;
    activeTenantDeleteWorkflow: ITenantDeleteWorkflow | null;
    fetchEnvironments: (envName: string) => void;
    fetchTenants: (envMoniker: string, activeTenantId?: string) => void;
    fetchAppSettings: () => void;
}

type IPageParameters = {
    environmentName: string;
    tenantId: string;
};

const LeftMenu: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    appSettings,
    activeTenant,
    customerType,
    activeTenantDeleteWorkflow,
    fetchEnvironments,
    fetchTenants,
}: ComponentProps) => {
    const { t } = useTranslation();
    const params = useParams<IPageParameters>();
    const [isDeleting, setIsDeleting] = useState(false);
    const [isDropdownOpened, setDropdownOpened] = useState(true);
    const convertedMenu = useRoutesConvertor(leftMenu, params);

    const { environmentName, tenantId } = params;

    const customerTypeName =
        customerType === CustomerType.AX2012
            ? t("tenantConfig.d365InformationPage.customerTypeName.ax")
            : t("tenantConfig.d365InformationPage.customerTypeName.d365");

    useEffect(() => {
        setIsDeleting(
            !!(
                activeTenant &&
                activeTenantDeleteWorkflow &&
                activeTenant.versionInfo.id ===
                    activeTenantDeleteWorkflow.tenantId
            )
        );
    }, [activeTenant, activeTenantDeleteWorkflow]);

    useEffect(() => {
        if (!envMoniker && environmentName) {
            fetchEnvironments(environmentName);
            return;
        }
    }, [envMoniker, environmentName, fetchEnvironments]);

    useEffect(() => {
        if (!appSettings) {
            fetchAppSettings();
            return;
        }
    }, [appSettings, fetchAppSettings]);

    // TODO SK: review in future to check whether it is needed
    useEffect(() => {
        if (envMoniker && !tenantMoniker) {
            fetchTenants(envMoniker, tenantId);
        }
    }, [envMoniker, fetchTenants, tenantId, tenantMoniker]);

    const filterDataRefresh = (child: IConvertedItem): boolean => {
        switch (customerType) {
            case CustomerType.D365:
                return !(
                    child.path === constants.routePath.dataRefreshHistory &&
                    !appSettings.isDataRefreshEnabled
                );
            case CustomerType.AX2012:
                return !(child.path === constants.routePath.dataRefreshHistory);
            default:
                return true;
        }
    };
    const onClickHandler = () => {
        setDropdownOpened(!isDropdownOpened);
    };

    const buildItemChildren = (children: IConvertedItem[]) => {
        if (!isDropdownOpened) {
            return null;
        }

        return (
            <ul className={css.menu}>
                {children.map((child: IConvertedItem, num: number) => {
                    return (
                        <li
                            key={String(num)}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <NavLink
                                exact
                                className={cn(
                                    css.kid,
                                    isDeleting && css.itemDisabled
                                )}
                                to={child.url}
                                activeClassName={css.selected}
                                onClick={(event) => {
                                    if (isDeleting) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }
                                }}
                            >
                                <PageIcon className={css.icon} />
                                <span className={css.itemName}>
                                    {t(child.name)}
                                </span>
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <ul className={cn(css.menu)}>
            {convertedMenu
                .filter((child: IConvertedItem) => filterDataRefresh(child))
                .map((item, index: number) => {
                    return !item.children || item.children.length === 0 ? (
                        <li key={String(index)}>
                            <NavLink
                                className={cn(
                                    css.item,
                                    isDeleting && css.itemDisabled
                                )}
                                to={item.url}
                                activeClassName={css.active}
                                onClick={(event) => {
                                    if (isDeleting) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }
                                }}
                            >
                                <FolderIcon className={css.icon} />
                                <span className={css.itemName}>
                                    {template(t(item.name))({
                                        customerType: customerTypeName,
                                    })}
                                </span>
                            </NavLink>
                        </li>
                    ) : (
                        <li key={String(index)} onClick={onClickHandler}>
                            <NavLink
                                to={item.url}
                                className={cn(
                                    css.item,
                                    isDeleting && css.itemDisabled
                                )}
                                activeClassName={css.active}
                                onClick={(event) => {
                                    if (isDeleting) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }
                                }}
                            >
                                <FolderIcon />
                                <span className={css.itemName}>
                                    {template(t(item.name))({
                                        customerType: customerTypeName,
                                    })}
                                </span>
                                {isDropdownOpened ? (
                                    <ArrowDownLineIcon />
                                ) : (
                                    <ArrowUpLineIcon />
                                )}
                            </NavLink>
                            {buildItemChildren(item.children)}
                        </li>
                    );
                })}
        </ul>
    );
};

export default LeftMenu;
