/* eslint-disable no-case-declarations */
import {
    SET_CULTURE_SETTINGS,
    SET_CULTURE,
    SET_NUMBER_AND_DATE_CULTURE,
    SET_CULTURES,
    SET_PRECISE_SCALE,
    SET_DEFAULT_CURRENCY,
    SET_CURRENCIES,
    SET_SUPPORTED_CULTURES,
    Actions,
    State,
    ICultureSettings,
    ICurrency,
    SET_CULTURE_SETTINGS_SAVED,
} from "./tenant-culture-settings-types";

const cultureSettingsInitialState: ICultureSettings = {
    culture: null,
    numberAndDateCulture: null,
    preciseScale: 2,
    defaultCurrency: null,
};
const initialState = {
    ...cultureSettingsInitialState,
    ...{
        currencies: [] as ICurrency[],
        supportedCultures: [] as string[],
        cultures: [] as string[],
        isFetched: false,
        isCultureSettingsSaved: false,
    },
};
const tenantCultureSettingsReducer = (
    state: State = initialState,
    action: Actions
): State => {
    switch (action.type) {
        case SET_CULTURE_SETTINGS:
            const newCultureSettings =
                action.cultureSettings || cultureSettingsInitialState;

            return {
                ...state,
                ...newCultureSettings,
            };

        case SET_CULTURE:
            return {
                ...state,
                culture: action.culture,
            };

        case SET_NUMBER_AND_DATE_CULTURE:
            return {
                ...state,
                numberAndDateCulture: action.numberAndDateCulture,
            };

        case SET_PRECISE_SCALE:
            return {
                ...state,
                preciseScale: action.preciseScale,
            };

        case SET_DEFAULT_CURRENCY:
            return {
                ...state,
                defaultCurrency: action.defaultCurrency,
            };

        case SET_CURRENCIES:
            return {
                ...state,
                currencies: action.currencies,
            };

        case SET_CULTURE_SETTINGS_SAVED:
            return {
                ...state,
                isCultureSettingsSaved: action.isSaved,
            };

        case SET_SUPPORTED_CULTURES:
            return {
                ...state,
                supportedCultures: action.supportedCultures,
            };

        case SET_CULTURES:
            return {
                ...state,
                cultures: action.cultures,
            };

        default:
            return state;
    }
};

export default tenantCultureSettingsReducer;
