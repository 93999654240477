import { RootState } from "../../redux/store";
import Customers from "../Customers/Customers";
import { connect } from "react-redux";
import { fetchCustomers } from "../../redux/customers/customers-thunks";
import { setActiveCustomerById } from "../../redux/customers/customers-actions";
import { compose } from "redux";
import { getCustomersFilteredSelector } from "../../redux/customers/customers-selectors";
import { getAppSettings } from "redux/app-settings/app-settings-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        filteredCustomers: getCustomersFilteredSelector(state),
        appSettings: getAppSettings(state),
        errorMessage: state.initializationReducer.errorResponseMessage,
    };
};
const CustomersContainer = compose(
    connect(mapStateToProps, {
        fetchCustomers,
        setActiveCustomerById,
    })
)(Customers) as React.FC;

export default CustomersContainer;
