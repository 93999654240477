import React, { useEffect, useState } from "react";
import { Table, TableProps } from "rsuite";
import ExpandableCell from "./ExpandableCell";

interface ExpandedTableProps extends TableProps {
    rowData?: any;
    children: any;
    isTableCollapsed: boolean;
    setExpandableAreaHeight: (height: number) => void;
}

const ExpandableTable: React.FC<ExpandedTableProps> = (
    props: ExpandedTableProps
) => {
    const [expandedRowKey, setExpandedRowKey] = useState<string>("");
    const headerHeight = 70;
    const defaultRowHeight = 50;
    const emptyTableHeight = 150;

    const handleRowExpanding = (rowData: any) => {
        props.setExpandableAreaHeight(
            rowData.tenants.length
                ? rowData.tenants.length * defaultRowHeight + headerHeight
                : emptyTableHeight
        );
        type RowDataObjectKey = keyof typeof props.rowData;
        const rowDataIdKey = props.rowKey as RowDataObjectKey;

        let open = false;
        if (expandedRowKey === rowData[rowDataIdKey]) {
            open = true;
        }
        open ? setExpandedRowKey("") : setExpandedRowKey(rowData[rowDataIdKey]);
    };

    useEffect(() => {
        if (props.isTableCollapsed) {
            setExpandedRowKey("");
        }
    }, [props.isTableCollapsed]);

    return (
        <Table expandedRowKeys={[expandedRowKey]} {...props}>
            <Table.Column width={52}>
                <Table.HeaderCell>{""}</Table.HeaderCell>
                <ExpandableCell
                    rowDataKey={props.rowKey}
                    expandedRowKey={expandedRowKey}
                    onChange={handleRowExpanding}
                />
            </Table.Column>
            {props.children}
        </Table>
    );
};

export default ExpandableTable;
