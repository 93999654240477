import React from "react";
import PopoverCell from "components/Common/PopoverCell/PopoverCell";
import {
    IMailboxCreation,
    MailboxCreationType,
    ValidationErrors,
    mailboxFields,
} from "redux/environments/tenants/tenant-capture-mailboxes/tenant-capture-mailboxes-types";
import { Input, Popover, Whisper } from "rsuite";
import { useTranslation } from "react-i18next";
import constants from "utils/constants";
import { TableInstance } from "rsuite/esm/Table";

type MailboxFieldType = keyof typeof mailboxFields;

interface EditableCellProps {
    mailboxes: IMailboxCreation[];
    dataKey: MailboxFieldType;
    rowData: IMailboxCreation;
    tableBodyRef: React.MutableRefObject<TableInstance<any, any> | null>;
    validationErrors: ValidationErrors;
    creationType: MailboxCreationType;
    setMailboxes: React.Dispatch<React.SetStateAction<IMailboxCreation[]>>;
    setValidationErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
}

const EditableCell: React.FC<EditableCellProps> = ({
    mailboxes,
    dataKey,
    rowData,
    validationErrors,
    creationType,
    setMailboxes,
    setValidationErrors,
}: EditableCellProps) => {
    const { t } = useTranslation();

    const handleEditChange = (
        value: string,
        rowData: IMailboxCreation,
        field: MailboxFieldType
    ) => {
        const updatedMailboxes = mailboxes.map((mailbox) =>
            mailbox.companyId === rowData.companyId
                ? { ...mailbox, [field]: value }
                : mailbox
        );
        setMailboxes(updatedMailboxes);

        const validateField = (field: string, value: string) => {
            if (field === mailboxFields.mailboxName && !value.trim()) {
                return t("generalValidationMessages.isRequired");
            }
            if (field === mailboxFields.mailboxAddress) {
                if (!value.trim()) {
                    return t("generalValidationMessages.isRequired");
                } else if (!constants.emailRegex.test(value)) {
                    return t("generalValidationMessages.isEmail");
                } else if (
                    mailboxes.some(
                        (mailbox) =>
                            mailbox.mailboxAddress === value &&
                            mailbox.companyId !== rowData.companyId
                    )
                ) {
                    return t("generalValidationMessages.isUnique");
                }
            }
            return "";
        };

        const errorMessage = validateField(field, value);

        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [rowData.companyId!]: {
                ...prevErrors[rowData.companyId!],
                [field]: errorMessage,
            },
        }));
    };

    const isLastElement = mailboxes[mailboxes.length - 1] === rowData;

    return (
        <>
            {rowData.editing && rowData.companyId ? (
                <Whisper
                    // container={tableBodyRef.current}
                    trigger="none"
                    open={
                        creationType !== MailboxCreationType.Generic &&
                        !!validationErrors[rowData.companyId]?.[dataKey]
                    }
                    placement={isLastElement ? "topEnd" : "bottomStart"}
                    speaker={
                        <Popover className="mailboxes-creation__error-popover">
                            {validationErrors[rowData.companyId]?.[dataKey]}
                        </Popover>
                    }
                >
                    <Input
                        type="text"
                        value={rowData[dataKey]}
                        onChange={(value: string) =>
                            handleEditChange(value, rowData, dataKey)
                        }
                    />
                </Whisper>
            ) : (
                <PopoverCell dataKey={dataKey} rowData={rowData} />
            )}
        </>
    );
};

export default EditableCell;
