import React, { useEffect, useState } from "react";
import { Button, Form, Message, useToaster } from "rsuite";
import { useTranslation } from "react-i18next";
import { SecretExpirationStatus } from "redux/environments/tenants/tenants-types";
import { template } from "lodash";
import cn from "classnames";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import {
    ITenantFeatureSettingWorkFlow,
    TenantFeatureSettingWorkFlowType,
    WorkflowModalType,
    WorkflowStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import constants from "utils/constants";
import css from "./ClientSecretFormGroup.module.css";
import { toggleTenantFeatureSetting } from "api/environments/tenants/tenant-feature-settings-api";
import { tenantFeatureSettingDefinitions } from "utils/tenant-workflows/tenant-workflows-definitions";
import {
    IErpInfo,
    ISecretExpiration,
} from "redux/environments/tenants/tenant-configurations/tenant-configurations-types";
import { TypeAttributes } from "rsuite/esm/internals/types";

interface ComponentProps {
    environmentMoniker: string;
    tenantMoniker: string;
    keyVaultAppRegistrationSecretRotationWorkflow: ITenantFeatureSettingWorkFlow | null;
    erpInfo: IErpInfo;
    setIsTenantFetched: (isFethced: boolean) => void;
}
const ClientSecretFormGroup: React.FC<ComponentProps> = ({
    environmentMoniker,
    tenantMoniker,
    keyVaultAppRegistrationSecretRotationWorkflow,
    erpInfo,
    setIsTenantFetched,
}: ComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const [isRefreshSecretDisabled, setRefreshSecretDisabled] =
        useState<boolean>(false);

    const handleRefreshClick = () => {
        setRefreshSecretDisabled(true);

        const settingDefinition =
            tenantFeatureSettingDefinitions[
                TenantFeatureSettingWorkFlowType
                    .KeyVaultAppRegistrationSecretRotation
            ];

        toggleTenantFeatureSetting(
            environmentMoniker,
            tenantMoniker,
            settingDefinition.urlParameterValue,
            {
                settingName: settingDefinition.urlParameterValue,
                toggleValue: true,
                data: null,
            }
        ).then((response) => {
            const [, status] = response;
            const isResponseStatusCodeExpected =
                status === constants.statusCode.NoContent ||
                status === constants.statusCode.OK;

            if (isResponseStatusCodeExpected) {
                toaster.push(
                    <Message type="success" showIcon>
                        {t(
                            "tenantConfig.d365InformationPage.clientSecret.startedSuccessfully"
                        )}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            } else {
                toaster.push(
                    <Message type="error" showIcon>
                        {t(
                            "tenantConfig.d365InformationPage.clientSecret.startedFailure"
                        )}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            }
            setRefreshSecretDisabled(false);
        });
    };

    const handleRefreshAfterTerminate = () => {
        setIsTenantFetched(false);
    };

    const getExpirationMessageProps = (
        secretExpiration: ISecretExpiration,
        message: string
    ): {
        type: TypeAttributes.Status;
        description: string;
        show: boolean;
    } => {
        switch (secretExpiration.secretExpirationStatus) {
            case SecretExpirationStatus.Normal:
                return {
                    type: "info",
                    description: template(message)({
                        expirationDays:
                            secretExpiration.secretExpirationTimeInDays,
                        expirationDateOffset:
                            secretExpiration.secretRotationDate,
                    }),
                    show: false,
                };
            case SecretExpirationStatus.Warning:
                return {
                    type: "warning",
                    description: template(message)({
                        expirationDays:
                            secretExpiration.secretExpirationTimeInDays,
                        expirationDateOffset:
                            secretExpiration.secretRotationDate,
                    }),
                    show: true,
                };
            case SecretExpirationStatus.Error:
                return {
                    type: "error",
                    description: template(message)({
                        expirationDays:
                            secretExpiration.secretExpirationTimeInDays,
                        expirationDateOffset:
                            secretExpiration.secretRotationDate,
                    }),
                    show: true,
                };
            default:
                return {
                    type: "info",
                    description: "",
                    show: false,
                };
        }
    };

    const appRegExpirationMessageProps = getExpirationMessageProps(
        erpInfo.appRegSecretExpiration,
        t("tenantConfig.d365InformationPage.clientSecret.message")
    );

    const erpSecretExpirationMessageProps = getExpirationMessageProps(
        erpInfo.axSecretExpiration,
        erpInfo.axSecretExpiration.secretExpirationStatus &&
            erpInfo.axSecretExpiration.secretExpirationStatus ===
                SecretExpirationStatus.Error
            ? t("tenantConfig.d365InformationPage.erpSecretExpiredMessage")
            : t("tenantConfig.d365InformationPage.erpSecretExpirationMessage")
    );

    const appRegSecretExpirationShown =
        erpInfo.appRegSecretExpiration.secretExpirationTimeInDays !== null &&
        erpInfo.appRegSecretExpiration.secretExpirationStatus !== null;
    const axSecretExpirationShown =
        erpInfo.axSecretExpiration.secretExpirationTimeInDays !== null &&
        erpInfo.axSecretExpiration.secretExpirationStatus !== null;

    const isClientSecretFormGroupShown =
        appRegSecretExpirationShown || axSecretExpirationShown;

    useEffect(() => {
        if (
            keyVaultAppRegistrationSecretRotationWorkflow &&
            (keyVaultAppRegistrationSecretRotationWorkflow.status ===
                WorkflowStatus.Pending ||
                keyVaultAppRegistrationSecretRotationWorkflow.status ===
                    WorkflowStatus.Running ||
                keyVaultAppRegistrationSecretRotationWorkflow.status ===
                    WorkflowStatus.NotStarted)
        ) {
            setRefreshSecretDisabled(true);
        } else {
            setRefreshSecretDisabled(false);
        }
    }, [keyVaultAppRegistrationSecretRotationWorkflow?.status]);

    return isClientSecretFormGroupShown ? (
        <div className={"general-configurations__client-secret-section"}>
            {appRegSecretExpirationShown && (
                <Form.Group
                    className={cn(
                        "general-configurations__grid-item",
                        css.gridItemClientSecret
                    )}
                >
                    <Form.ControlLabel
                        className={"general-configurations__label"}
                    >
                        {t(
                            "tenantConfig.d365InformationPage.clientSecret.title"
                        )}
                        <Form.HelpText
                            className={"general-configurations__help"}
                            tooltip
                        >
                            {t(
                                "tenantConfig.d365InformationPage.clientSecret.tooltip"
                            )}
                        </Form.HelpText>
                    </Form.ControlLabel>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Form.Control
                            name="clientSecretRotate"
                            accepter={Button}
                            onClick={() => handleRefreshClick()}
                            className={"general-configurations__button"}
                            disabled={isRefreshSecretDisabled}
                        >
                            {t(
                                "tenantConfig.d365InformationPage.clientSecret.rotateBtn"
                            )}
                        </Form.Control>
                        {keyVaultAppRegistrationSecretRotationWorkflow && (
                            <ProcessModalButton
                                workflow={
                                    keyVaultAppRegistrationSecretRotationWorkflow
                                }
                                workflowType={WorkflowModalType.Feature}
                                triggerRefresh={handleRefreshAfterTerminate}
                            />
                        )}
                    </div>
                    {appRegExpirationMessageProps.show && (
                        <Message
                            className={css.clientSecretMessage}
                            showIcon
                            {...appRegExpirationMessageProps}
                        />
                    )}
                </Form.Group>
            )}
            {axSecretExpirationShown && (
                <Form.Group
                    className={cn(
                        "general-configurations__grid-item",
                        css.gridItemClientSecret
                    )}
                >
                    {erpSecretExpirationMessageProps.show && (
                        <Message
                            className={css.clientSecretMessage}
                            showIcon
                            {...erpSecretExpirationMessageProps}
                        />
                    )}
                </Form.Group>
            )}
        </div>
    ) : null;
};

export default ClientSecretFormGroup;
