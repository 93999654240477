import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    deleteErpPackage,
    loadErpPackages,
    loadErpPackageTargets,
    loadErpPackageTypes,
} from "api/deliverables/erp-packages-api";
import {
    IErpPackage,
    IErpPackageTarget,
    IErpPackageType,
} from "redux/deliverables/erp-packages/erp-packages-types";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import useRefreshPage from "hooks/useRefreshPage";
import css from "./ErpPackages.module.css";
import { compareDates, isPreviousDate } from "utils/date-helper";
import { Checkbox, CheckPicker, Message, useToaster } from "rsuite";
import ErrorSection from "components/Common/ErrorSection/ErrorSection";
import ManageErpPackageContainer from "./ManageErpPackage/ManageErpPackageContainer";
import ErpPackageCardContainer from "./ErpPackageCard/ErpPackageCardContainer";
import EmailsSendingContainer from "./EmailsSending/EmailsSendingContainer";
import { template } from "lodash";
import FunnelIcon from "@rsuite/icons/Funnel";
import SendIcon from "@rsuite/icons/Send";
import CopyIcon from "@rsuite/icons/Copy";

interface ComponentProps {
    errorMessage: string;
    setErrorResponseMessage: (msg: string) => void;
    showLoader: (isVisible: boolean) => void;
}

const ErpPackages: React.FC<ComponentProps> = ({
    errorMessage,
    setErrorResponseMessage,
    showLoader,
}: ComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const containerRef = useRef<HTMLDivElement>(null);
    const [packages, setPackages] = useState<IErpPackage[]>([]);
    const [filteredPackages, setFilteredPackages] = useState<IErpPackage[]>([]);
    const [isDataFetched, setDataFetched] = useState<boolean>(false);
    const [showExpired, setShowExpired] = useState<boolean>(true);
    const [packageTypes, setPackageTypes] = useState<IErpPackageType[]>([]);
    const [packageTargets, setPackageTargets] = useState<IErpPackageTarget[]>(
        []
    );
    const [checkedTypes, setCheckedTypes] = useState<string[]>([]);
    const [checkedTargets, setCheckedTargets] = useState<string[]>([]);
    const [checkedVersions, setCheckedVersions] = useState<string[]>([]);
    const [erpPackage, setErpPackage] = useState<IErpPackage | null>(null);

    const fetchData = () => {
        setPackages([]);
        setErrorResponseMessage("");
        showLoader(true);
        Promise.all([
            loadErpPackageTypes().then(([payload, statusCode]) => {
                if (payload && statusCode === constants.statusCode.OK) {
                    setPackageTypes(payload);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        statusCode
                    );
                    toaster.push(
                        <Message showIcon type="error">
                            {errorMessage}
                        </Message>,
                        {
                            duration: constants.errorAlertDurationMsec,
                        }
                    );
                }
            }),
            loadErpPackageTargets().then(([payload, statusCode]) => {
                if (payload && statusCode === constants.statusCode.OK) {
                    setPackageTargets(payload);
                } else {
                    const errorMessage = getErrorMessage(
                        String(payload),
                        statusCode
                    );
                    toaster.push(
                        <Message showIcon type="error">
                            {errorMessage}
                        </Message>,
                        {
                            duration: constants.errorAlertDurationMsec,
                        }
                    );
                }
            }),
            loadErpPackages().then(([payload, status]) => {
                if (payload && status === constants.statusCode.OK) {
                    setPackages(payload);
                    setFilteredPackages(payload);
                } else {
                    setErrorResponseMessage(
                        getErrorMessage(String(payload), status)
                    );
                }
            }),
        ]).then(() => {
            showLoader(false);
            setDataFetched(true);
        });
    };

    const deletePackage = (erpPackage: IErpPackage) => {
        showLoader(true);
        deleteErpPackage(erpPackage.id).then(([payload, status]) => {
            showLoader(false);
            setDataFetched(false);
            if (status === constants.statusCode.OK) {
                toaster.push(
                    <Message showIcon type="success">
                        {template(
                            t("deliverables.packages.delete.successMessage")
                        )({
                            packageVersion: erpPackage.version,
                        })}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            } else {
                const errorMessage = getErrorMessage(String(payload), status);
                toaster.push(
                    <Message showIcon type="error">
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.errorAlertDurationMsec,
                    }
                );
            }
        });
    };

    const renderFilterMenuItem = (label: ReactNode) => {
        return <div className={css.filterItem}>{label}</div>;
    };

    useEffect(() => {
        if (!isDataFetched) {
            fetchData();
        }
    }, [isDataFetched]);

    useEffect(() => {
        setFilteredPackages(
            packages.filter(
                (p) =>
                    (!checkedTypes.length ||
                        checkedTypes.includes(p.packageType.id)) &&
                    (!checkedTargets.length ||
                        checkedTargets.includes(p.packageTarget.id)) &&
                    (!checkedVersions.length ||
                        checkedVersions.includes(p.version)) &&
                    (showExpired || !isPreviousDate(new Date(p.expirationDate)))
            )
        );
    }, [checkedTypes, checkedTargets, checkedVersions, showExpired, packages]);

    useEffect(() => {
        if (packages.length && checkedVersions.length) {
            setCheckedVersions(
                checkedVersions.filter((checked) =>
                    packages.map((checked) => checked.version).includes(checked)
                )
            );
        }
    }, [packages]);

    useRefreshPage(() => {
        containerRef.current?.scrollTo(0, 0);
        fetchData();
    });

    if (errorMessage.length > 0) {
        return (
            <div className={css.error}>
                <ErrorSection errorMessage={errorMessage} />
            </div>
        );
    }

    return (
        <>
            <ManageErpPackageContainer
                existingPackages={filteredPackages}
                packageTypes={packageTypes}
                packageTargets={packageTargets}
                refreshPackages={fetchData}
            />
            <EmailsSendingContainer
                erpPackage={erpPackage}
                refreshPackages={fetchData}
            />
            {isDataFetched && (
                <>
                    <h3>{t("deliverables.packages.title")}</h3>
                    <div className={css.filters}>
                        <FunnelIcon />
                        <CheckPicker
                            value={checkedTypes}
                            data={packageTypes.map((value) => {
                                return {
                                    label: value.type,
                                    value: value.id,
                                };
                            })}
                            placeholder={t("deliverables.packages.typeTitle")}
                            searchable={false}
                            renderMenuItem={renderFilterMenuItem}
                            onChange={setCheckedTypes}
                        />
                        <CheckPicker
                            value={checkedTargets}
                            data={packageTargets.map((value) => {
                                return {
                                    label: value.target,
                                    value: value.id,
                                };
                            })}
                            placeholder={t("deliverables.packages.targetTitle")}
                            searchable={false}
                            renderMenuItem={renderFilterMenuItem}
                            className={css.targetFilter}
                            onChange={setCheckedTargets}
                        />
                        <CheckPicker
                            value={checkedVersions}
                            data={Array.from(
                                new Set(packages.map((p) => p.version))
                            ).map((version) => ({
                                value: version,
                                label: version,
                            }))}
                            placeholder={t(
                                "deliverables.packages.versionTitle"
                            )}
                            searchable={false}
                            renderMenuItem={renderFilterMenuItem}
                            onChange={setCheckedVersions}
                        />
                        <Checkbox
                            defaultChecked
                            onChange={(_, checked) => {
                                setShowExpired(checked);
                            }}
                        >
                            {t("deliverables.packages.filterExpiredTitle")}
                        </Checkbox>
                    </div>
                    <div>
                        {t("deliverables.packages.explanationStarting")}
                        <SendIcon className={css.icon} />
                        {t("deliverables.packages.sendIconExplanation")}
                    </div>
                    <div>
                        {t("deliverables.packages.explanationStarting")}
                        <CopyIcon className={css.icon} />
                        {t("deliverables.packages.copyIconExplanation")}
                    </div>
                    {filteredPackages.length ? (
                        <div ref={containerRef} className={css.listContainer}>
                            {filteredPackages
                                .sort((p1, p2) => {
                                    return compareDates(
                                        new Date(p1.creationDate),
                                        new Date(p2.creationDate)
                                    );
                                })
                                .map(
                                    (
                                        erpPackage: IErpPackage,
                                        index: number
                                    ) => (
                                        <ErpPackageCardContainer
                                            erpPackage={erpPackage}
                                            key={String(index)}
                                            deletePackage={deletePackage}
                                            setErpPackage={setErpPackage}
                                        />
                                    )
                                )}
                        </div>
                    ) : (
                        <div className={css.emptyContainer}>
                            {t("deliverables.packages.noDataFound")}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ErpPackages;
