import { IAxImportEntity } from "redux/environments/tenants/data-import/data-import-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, List, Radio, RadioGroup } from "rsuite";
import cn from "classnames";
import {
    ITenantFeatureSettingWorkFlow,
    ToggleValueType,
    WorkflowModalType,
} from "utils/tenant-workflows/tenant-workflows-types";
import {
    getTenantFeatureSettingByType,
    isTenantFeatureSettingWithTypeEnabled,
    isToggleValueEnabled,
} from "utils/tenant-workflows/tenant-workflows-helper";
import ProcessModalButton from "components/WorkflowLog/ProcessModalButton/ProcessModalButton";
import { axImportNameToWorkflowTypeMapping } from "utils/tenant-workflows/tenant-workflows-definitions";
import "./AxImportEntitiesTable.less";

interface ComponentProps {
    axImportEntities: IAxImportEntity[];
    isD365Customer: boolean;
    isDataTransferAllowed: boolean;
    disabledSettingNames: string[];
    cefOriginalEntities: IAxImportEntity[];
    settingWorkFlows: ITenantFeatureSettingWorkFlow[];
    setAxImportEntities: (axImportEntities: IAxImportEntity[]) => void;
    handleTransferData: ((entityName: string) => void) | null;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
}
type AxImportState =
    | "isAxImportTurnedOn"
    | "isCefTurnedOn"
    | "isDocImportDisabled";

const AxImportEntitiesTable: React.FC<ComponentProps> = ({
    axImportEntities,
    isD365Customer,
    isDataTransferAllowed,
    disabledSettingNames,
    cefOriginalEntities,
    settingWorkFlows,
    setAxImportEntities,
    handleTransferData,
    setIsUnsavedChangeAvailable,
}: ComponentProps) => {
    const { t } = useTranslation();

    const handleAxImportIsEnabled = (value: boolean, itemIndex: number) => {
        axImportEntities[itemIndex].isAxImportTurnedOn =
            axImportEntities[itemIndex].isAxImportTurnedOn === null
                ? axImportEntities[itemIndex].isAxImportTurnedOn
                : value;
        axImportEntities[itemIndex].isCefTurnedOn =
            axImportEntities[itemIndex].isCefTurnedOn === null
                ? axImportEntities[itemIndex].isCefTurnedOn
                : !value;

        setAxImportEntities([...axImportEntities]);
    };

    const handleCefIsEnabled = (value: boolean, itemIndex: number) => {
        axImportEntities[itemIndex].isCefTurnedOn =
            axImportEntities[itemIndex].isCefTurnedOn === null
                ? axImportEntities[itemIndex].isCefTurnedOn
                : value;
        axImportEntities[itemIndex].isAxImportTurnedOn =
            axImportEntities[itemIndex].isAxImportTurnedOn === null
                ? axImportEntities[itemIndex].isAxImportTurnedOn
                : !value;

        setAxImportEntities([...axImportEntities]);
    };

    const handleDisableDocImport = (value: boolean, itemIndex: number) => {
        axImportEntities[itemIndex].isCefTurnedOn =
            axImportEntities[itemIndex].isCefTurnedOn === null
                ? axImportEntities[itemIndex].isCefTurnedOn
                : !value;

        axImportEntities[itemIndex].isAxImportTurnedOn =
            axImportEntities[itemIndex].isAxImportTurnedOn === null
                ? axImportEntities[itemIndex].isAxImportTurnedOn
                : !value;

        setAxImportEntities([...axImportEntities]);
    };

    const getInitialValue = (
        isAxImportTurnedOn: boolean | null,
        isCefTurnedOn: boolean | null
    ): string => {
        if (isAxImportTurnedOn) {
            return "isAxImportTurnedOn";
        } else if (isCefTurnedOn) {
            return "isCefTurnedOn";
        } else {
            return "isDocImportDisabled";
        }
    };

    const axImportEntityStateSwitcher: {
        [index: string]: (index: number) => void;
    } = {
        isDocImportDisabled: (index: number) =>
            handleDisableDocImport(true, index),
        isAxImportTurnedOn: (index: number) =>
            handleAxImportIsEnabled(true, index),
        isCefTurnedOn: (index: number) => handleCefIsEnabled(true, index),
    };

    const handleSwitchClick = (value: AxImportState, itemIndex: number) => {
        axImportEntityStateSwitcher[value](itemIndex);
        setIsUnsavedChangeAvailable(true);
    };

    const getSettingWorkFlowsByImportEntityName = (name: string) => {
        const workflowType = axImportNameToWorkflowTypeMapping[name] || null;
        return workflowType !== null
            ? getTenantFeatureSettingByType(settingWorkFlows, workflowType)
            : null;
    };

    return (
        <>
            {axImportEntities.length && (
                <>
                    <div
                        className={cn(
                            "ax-import-entities-table__header",
                            !isD365Customer
                                ? "ax-import-entities-table__grid--ax"
                                : isDataTransferAllowed
                                  ? "ax-import-entities-table__grid--full"
                                  : "ax-import-entities-table__grid--cef"
                        )}
                    >
                        <span>
                            {t("tenantConfig.axImportControl.tableHeader.name")}
                        </span>
                        <span className="ax-import-entities-table__header-cell">
                            {t(
                                "tenantConfig.axImportControl.tableHeader.disabled"
                            )}
                        </span>
                        <span className="ax-import-entities-table__header-cell">
                            {t(
                                "tenantConfig.axImportControl.tableHeader.axImport"
                            )}
                        </span>
                        {isD365Customer && (
                            <>
                                <span className="ax-import-entities-table__header-cell">
                                    {t(
                                        "tenantConfig.axImportControl.tableHeader.cef"
                                    )}
                                </span>
                                {isDataTransferAllowed && (
                                    <div className="ax-import-entities-table__header-cell">
                                        <span>
                                            {t(
                                                "tenantConfig.axImportControl.tableHeader.triggerDataTransfer"
                                            )}
                                        </span>
                                        <Form.HelpText
                                            tooltip
                                            className="ax-import-entities-table__help"
                                        >
                                            {t(
                                                "tenantConfig.axImportControl.dataTransfer.tooltip"
                                            )}
                                        </Form.HelpText>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <List size="sm">
                        {axImportEntities.map((axEntity, index) => {
                            const settingWorkFlow =
                                axEntity.isCefTurnedOn !== null
                                    ? getSettingWorkFlowsByImportEntityName(
                                          axEntity.importEntityName
                                      )
                                    : null;
                            const isRadioGroupEnabled =
                                settingWorkFlow !== null
                                    ? !disabledSettingNames.includes(
                                          settingWorkFlow.featureName
                                      ) &&
                                      isTenantFeatureSettingWithTypeEnabled(
                                          settingWorkFlows,
                                          settingWorkFlow.type
                                      )
                                    : true;
                            if (
                                axEntity.isCefTurnedOn !== null &&
                                !isRadioGroupEnabled &&
                                settingWorkFlow !== null
                            ) {
                                const isCefEnabled = isToggleValueEnabled(
                                    settingWorkFlow.toggleValue,
                                    ToggleValueType.Setting
                                );
                                const isAxImportEnabled = isToggleValueEnabled(
                                    settingWorkFlow.toggleValue,
                                    ToggleValueType.AxImport
                                );
                                if (
                                    isCefEnabled !== axEntity.isCefTurnedOn ||
                                    (axEntity.isAxImportTurnedOn !== null &&
                                        isAxImportEnabled !==
                                            axEntity.isAxImportTurnedOn)
                                ) {
                                    axImportEntityStateSwitcher[
                                        getInitialValue(
                                            isAxImportEnabled,
                                            isCefEnabled
                                        )
                                    ](index);
                                }
                            }
                            return (
                                <List.Item key={index} index={index}>
                                    <RadioGroup
                                        className={cn(
                                            "ax-import-entities-table__row",
                                            !isD365Customer
                                                ? "ax-import-entities-table__grid--ax"
                                                : isDataTransferAllowed
                                                  ? "ax-import-entities-table__grid--full"
                                                  : "ax-import-entities-table__grid--cef"
                                        )}
                                        value={getInitialValue(
                                            axEntity.isAxImportTurnedOn,
                                            axEntity.isCefTurnedOn
                                        )}
                                        onChange={(value) => {
                                            handleSwitchClick(
                                                value as AxImportState,
                                                index
                                            );
                                        }}
                                    >
                                        <div className="ax-import-entities-table__cell">
                                            {axEntity.importEntityName}
                                            {settingWorkFlow !== null && (
                                                <ProcessModalButton
                                                    workflow={settingWorkFlow}
                                                    workflowType={
                                                        WorkflowModalType.Feature
                                                    }
                                                />
                                            )}
                                        </div>
                                        <Radio
                                            className="ax-import-entities-table__row-checker"
                                            value={"isDocImportDisabled"}
                                            disabled={!isRadioGroupEnabled}
                                        />
                                        {axEntity.isAxImportTurnedOn !==
                                        null ? (
                                            <Radio
                                                className="ax-import-entities-table__row-checker"
                                                value={"isAxImportTurnedOn"}
                                                disabled={!isRadioGroupEnabled}
                                            />
                                        ) : (
                                            <div></div>
                                        )}

                                        {axEntity.isCefTurnedOn !== null &&
                                            isD365Customer && (
                                                <Radio
                                                    className="ax-import-entities-table__row-checker"
                                                    value={"isCefTurnedOn"}
                                                    disabled={
                                                        !isRadioGroupEnabled
                                                    }
                                                />
                                            )}
                                        {axEntity.isCefTurnedOn !== null &&
                                            isD365Customer &&
                                            isDataTransferAllowed && (
                                                <Button
                                                    className={cn(
                                                        "ax-import-entities-table__action-button",
                                                        "ax-import-entities-table__transfer-all-btn"
                                                    )}
                                                    onClick={() => {
                                                        if (
                                                            handleTransferData
                                                        ) {
                                                            handleTransferData(
                                                                axEntity.importEntityName
                                                            );
                                                        }
                                                    }}
                                                    disabled={
                                                        !(
                                                            cefOriginalEntities.some(
                                                                (e) =>
                                                                    e.importEntityName ===
                                                                        axEntity.importEntityName &&
                                                                    e.isCefTurnedOn ===
                                                                        true
                                                            ) &&
                                                            axEntity.isCefTurnedOn
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        "tenantConfig.axImportControl.dataTransfer.buttonTitle"
                                                    )}
                                                </Button>
                                            )}
                                    </RadioGroup>
                                </List.Item>
                            );
                        })}
                    </List>
                </>
            )}
        </>
    );
};

export default AxImportEntitiesTable;
