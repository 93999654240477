import React, { useEffect, useState } from "react";
import { Container, Content, Header } from "rsuite";
import AppHeaderContainer from "components/AppHeader/AppHeaderContainer";
import PreloaderContainer from "components/Common/Preloader/PreloaderContainer";
import BreadCrumbContainer from "components/BreadCrumb/BreadCrumbContainer";
import AppRouterContainer from "components/App/AppRouter/AppRouterContainer";
import css from "./MainLayout.module.css";
import UnsavedDataModalContainer from "components/UnsavedDataModal/UnsavedDataModalContainer";
import useBeforeUnload from "hooks/useBeforeUnload";
import EnvironmentHistoryContainer from "components/Environments/EnvironmentHistory/EnvironmentHistoryContainer";
import TenantStatusContainer from "components/Environments/Tenants/TenantStatus/TenantStatusContainer";
import AppRegistrationInformationContainer from "components/Environments/AppRegistrationInformation/AppRegistrationInformationContainer";
import { Prompt } from "react-router-dom";

interface ComponentProps {
    isAppSettingsFetched: boolean;
    isLoaderVisible: boolean;
    isUnsavedChangeAvailable: boolean;
    fetchAppSettings: () => void;
    fetchUserPermissions: () => void;
}
const MainLayout: React.FC<ComponentProps> = ({
    isAppSettingsFetched,
    isLoaderVisible,
    isUnsavedChangeAvailable,
    fetchAppSettings,
    fetchUserPermissions,
}: ComponentProps) => {
    useBeforeUnload();

    const [isUserPermissionsLoaded, setUserPermissionsLoaded] = useState(false);

    useEffect(() => {
        if (!isAppSettingsFetched) {
            fetchAppSettings();
        }
    }, [isAppSettingsFetched, fetchAppSettings]);

    useEffect(() => {
        if (!isUserPermissionsLoaded) {
            fetchUserPermissions();
            setUserPermissionsLoaded(true);
        }
    }, [isUserPermissionsLoaded, fetchUserPermissions]);

    return (
        <Container>
            <Prompt
                when={isUnsavedChangeAvailable}
                message={(location: any) => {
                    return location?.pathname || "";
                }}
            />
            <Header>
                <AppHeaderContainer />
            </Header>
            <Content className={css.content}>
                <PreloaderContainer />
                <div
                    className={
                        isLoaderVisible ? css.hiddenContent : css.mainContent
                    }
                >
                    <BreadCrumbContainer />
                    <AppRouterContainer />
                    <EnvironmentHistoryContainer />
                    <AppRegistrationInformationContainer />
                    <TenantStatusContainer />
                </div>
            </Content>
            <UnsavedDataModalContainer />
        </Container>
    );
};

export default MainLayout;
