import React, { FC, useState } from "react";
import ErrorSection from "components/Common/ErrorSection/ErrorSection";
import css from "./withError.module.css";

interface IComponentProps {
    [key: string]: any;
}

const withError =
    (Component: FC<any>): FC<IComponentProps> =>
    // eslint-disable-next-line react/display-name
    ({ ...props }: IComponentProps) => {
        const [errorMessage, setErrorMessage] = useState("");

        const changeErrorMessage = (errorMessage: string) => {
            setErrorMessage(errorMessage);
        };

        return (
            <>
                {errorMessage && <ErrorSection errorMessage={errorMessage} />}
                <div className={errorMessage ? css.hidden : css.default}>
                    <Component {...props} showError={changeErrorMessage} />
                </div>
            </>
        );
    };

export default withError;
