import axios from "axios";
import { acquireToken } from "./auth-provider";

export const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

API.interceptors.request.use(
    async (config: any) => {
        const accessToken = await acquireToken();
        config.headers = new axios.AxiosHeaders({
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
        });
        return config;
    },
    async (error: any) => {
        return await error;
    }
);

API.interceptors.response.use(
    async (response: any) => {
        return response;
    },
    async (error: any) => {
        return await error.response;
    }
);
