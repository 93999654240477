import React, { SyntheticEvent } from "react";
import { Tooltip, Whisper } from "rsuite";
import ReloadIcon from "@rsuite/icons/Reload";
import cn from "classnames";

import css from "./RefreshButton.module.css";

interface ComponentProps {
    onClick: () => void;
    disabled: boolean;
    tooltip: string | undefined;
    tooltipPlacement?: "top" | "topStart";
}
const RefreshButton: React.FC<ComponentProps> = ({
    onClick,
    disabled,
    tooltip,
    tooltipPlacement = "top",
}: ComponentProps) => {
    const button = (
        <span
            onClick={(event: SyntheticEvent) => {
                event.stopPropagation();
                onClick();
            }}
            className={cn(css.refreshIconContainer, {
                [css.disabled]: disabled,
            })}
        >
            <ReloadIcon className={css.refreshButton} />
        </span>
    );

    return tooltip ? (
        <Whisper
            trigger={"hover"}
            placement={tooltipPlacement}
            speaker={<Tooltip>{tooltip}</Tooltip>}
        >
            {button}
        </Whisper>
    ) : (
        button
    );
};

export default RefreshButton;
