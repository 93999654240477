import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ButtonToolbar,
    Checkbox,
    CheckboxGroup,
    CheckPicker,
    Form,
    FormInstance,
    Message,
    Schema,
    SelectPicker,
    useToaster,
} from "rsuite";
import ModalContainer from "components/Common/Modal/ModalContainer";
import ConsentsContainer from "components/Customers/AddNewCustomer/Consents/ConsentsContainer";
import {
    ICleanAddNewCustomerData,
    ICustomerAadMasterTenant,
    ICustomerType,
    INewCustomerFormValue,
    ISetNewCustomerAdded,
    regionNames,
} from "redux/customers/add-new-customer/add-new-customer-types";
import constants from "utils/constants";
import { ICustomerNameInfo } from "redux/customers/customers-types";
import {
    loadPartners,
    loadUserLinkedPartners,
} from "api/partners/partners-api";
import { getErrorMessage } from "api/defaults";
import { addCustomer } from "api/customers/customers-api";
import { loadEmailTemplates } from "api/deliverables/email-templates-api";
import { IEmailTemplate } from "redux/deliverables/email-templates/email-templates-types";
import "./AddNewCustomer.less";
import EmailAddressesBox from "components/Common/EmailAddressesBox/EmailAddressesBox";
import { EmailRecipientType } from "redux/customers/customer-details/customer-details-types";
import cn from "classnames";
import { IAppSettings } from "redux/app-settings/app-settings-types";

interface ComponentProps {
    isModalBusy?: boolean;
    customerTypes: ICustomerType[];
    isAddNewCustomerModalOpen: boolean;
    newCustomerFormValue: INewCustomerFormValue;
    newCustomer: ICustomerAadMasterTenant;
    regions: string[];
    isNewCustomerAdded: boolean;
    customerNames: ICustomerNameInfo[];
    isPartner: boolean;
    appSettings: IAppSettings;
    fetchCustomerMetadata: () => void;
    fetchCustomers: () => void;
    setNewCustomerFormValue: (
        newCustomerFormValue: INewCustomerFormValue
    ) => void;
    cleanAddNewCustomerData: () => ICleanAddNewCustomerData;
    setNewCustomerAdded: (isNewCustomerAdded: boolean) => ISetNewCustomerAdded;
    setIsModalOpen: (isModalOpen: boolean) => void;
    setModalLoaderVisibility: (visible: boolean) => void;
}
const AddNewCustomer: React.FC<ComponentProps> = ({
    isModalBusy,
    customerTypes,
    newCustomerFormValue,
    newCustomer,
    isAddNewCustomerModalOpen,
    regions,
    isNewCustomerAdded,
    customerNames,
    // TODO: Should be used permission-based approach
    isPartner,
    appSettings,
    fetchCustomerMetadata,
    fetchCustomers,
    setNewCustomerAdded,
    cleanAddNewCustomerData,
    setNewCustomerFormValue,
    setIsModalOpen,
    setModalLoaderVisibility,
}: ComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const mainFormRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const copyFormRef = useRef<FormInstance<Record<string, any>> | null>(null);
    const formRef = useRef<FormInstance>(null);
    const [partners, setPartners] = useState<Record<string, any>[]>([]);
    const [isPartnersLoaded, setIsPartnersLoaded] = useState<boolean>(false);
    const [isPartnersDropdownDisabled, setPartnersDropdownDisabled] =
        useState<boolean>(false);
    const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([]);
    const [mainEmailAddress, setMainEmailAddress] = useState("");
    const [resultMainEmailAddresses, setResultMainEmailAddresses] = useState<
        string[]
    >([]);
    const [copyEmailAddress, setCopyEmailAddress] = useState("");
    const [resultCopyEmailAddresses, setResultCopyEmailAddresses] = useState<
        string[]
    >([]);
    const isMainResultArray = resultMainEmailAddresses.length > 0;
    const isCopyResultArray = resultCopyEmailAddresses.length > 0;
    const isLoadTemplatesAvailable =
        !isPartner && appSettings.isDeliverablesEnabled;

    const fetchPartners = () => {
        setModalLoaderVisibility(true);
        const promises: any[] = [];

        promises.push(
            loadPartners().then(([payload, status]) => {
                setIsPartnersLoaded(true);
                if (payload && status === constants.statusCode.OK) {
                    // setModalLoaderVisibility(false);
                    setPartners(
                        payload.map((partner) => ({
                            value: partner.id,
                            label: partner.name,
                        }))
                    );
                } else {
                    loadUserLinkedPartners().then(([data, status]) => {
                        if (
                            data?.length &&
                            status === constants.statusCode.OK
                        ) {
                            setPartners(
                                data.map((partner) => ({
                                    value: partner.id,
                                    label: partner.name,
                                }))
                            );
                        }
                    });
                }
            })
        );
        if (isLoadTemplatesAvailable) {
            promises.push(
                loadEmailTemplates().then(([payload, status]) => {
                    if (payload && status === constants.statusCode.OK) {
                        setEmailTemplates(payload);
                    }
                })
            );
        }

        Promise.all(promises).then(() => {
            setModalLoaderVisibility(false);
        });
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setIsPartnersLoaded(false);
    };

    const handleSubmit = () => {
        if (!formRef.current?.check()) {
            return;
        }
        if (mainEmailAddress) {
            resultMainEmailAddresses.push(mainEmailAddress);
            setMainEmailAddress("");
        }
        if (copyEmailAddress) {
            resultCopyEmailAddresses.push(copyEmailAddress);
            setCopyEmailAddress("");
        }
        const emailRecipients = [
            ...resultCopyEmailAddresses.map((address) => ({
                address,
                type: EmailRecipientType.Copy,
            })),
            ...resultMainEmailAddresses.map((address) => ({
                address,
                type: EmailRecipientType.Main,
            })),
        ];
        newCustomer.emailRecipients = emailRecipients;
        setModalLoaderVisibility(true);
        addCustomer(newCustomer).then(([result, status]) => {
            if (status === constants.statusCode.NoContent) {
                setNewCustomerAdded(true);
                cleanAddNewCustomerData();
            } else {
                const errorMessage = getErrorMessage(String(result), status);
                toaster.push(
                    <Message type="error" showIcon closable>
                        {errorMessage}
                    </Message>
                );
            }

            setModalLoaderVisibility(false);
        });
    };

    const handleFormChange = (formValue: any) => {
        setNewCustomerFormValue(formValue);
    };

    useEffect(() => {
        if (isAddNewCustomerModalOpen) {
            setModalLoaderVisibility(true);
            fetchCustomerMetadata();
            setModalLoaderVisibility(false);
        }
    }, [fetchCustomerMetadata, isAddNewCustomerModalOpen]);

    useEffect(() => {
        if (!isPartnersLoaded && isAddNewCustomerModalOpen) {
            fetchPartners();
        }
    }, [isPartnersLoaded, isAddNewCustomerModalOpen]);

    useEffect(() => {
        if (isPartner && isAddNewCustomerModalOpen) {
            setPartnersDropdownDisabled(true);
            setNewCustomerFormValue({
                ...newCustomerFormValue,
                partnerIds: partners.map((partner) => partner.value),
            });
        }
    }, [partners]);

    useEffect(() => {
        if (isNewCustomerAdded) {
            handleModalClose();
            toaster.push(
                <Message type="success" showIcon closable>
                    {t("addNewCustomer.successMessage")}
                </Message>,
                {
                    duration: constants.alertDurationMsec,
                }
            );
            fetchCustomers();
        }
    });

    const { StringType, ArrayType, ObjectType } = Schema.Types;
    const regionCheckboxes = regions.map((region, index) => (
        <Checkbox
            key={index.toString()}
            value={region}
            disabled={regions.length === 1}
        >
            {regionNames[region] ?? region}
        </Checkbox>
    ));
    const customerTypesSelectPickerData = customerTypes.map((customerType) => ({
        value: customerType,
        label: customerType.description,
    }));
    const emailTemplatesSelectPickerData = emailTemplates.map(
        (emailTemplate) => ({
            value: emailTemplate.id,
            label: emailTemplate.name,
        })
    );
    const formKey = {
        customerName: "customerName",
        regions: "regions",
        customerType: "customerType",
        partnerIds: "partnerIds",
        emailTemplateId: "emailTemplateId",
    };
    const newCustomerFormModel = Schema.Model({
        customerName: StringType()
            .isRequired(t("generalValidationMessages.isRequired"))
            .addRule((value) => {
                return customerNames.every(
                    (customer) => customer.name !== value
                );
            }, t("generalValidationMessages.isUnique"))
            .minLength(
                constants.minNameLengthLimit,
                t("generalValidationMessages.minLength")
            )
            .maxLength(
                constants.maxLengthLimit,
                t("generalValidationMessages.maxLength5000")
            ),
        regions: ArrayType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
        customerType: ObjectType().isRequired(
            t("generalValidationMessages.isRequired")
        ),
        emailTemplateId: StringType(),
    });

    const renderPartnerMenuItem = (label: ReactNode) => {
        return <div className="add-modal__selector">{label}</div>;
    };

    const header = <h1>{t("addNewCustomer.addNewCustomerHeader")}</h1>;
    const footer = (
        <ButtonToolbar className="custom-modal__button-toolbar">
            <Button
                type="submit"
                disabled={isModalBusy}
                appearance="primary"
                onClick={handleSubmit}
            >
                {t("addNewCustomer.createBtn")}
            </Button>
            <Button
                disabled={isModalBusy}
                appearance="ghost"
                onClick={handleModalClose}
            >
                {t("addNewCustomer.cancelBtn")}
            </Button>
        </ButtonToolbar>
    );

    return (
        <ModalContainer
            className="custom-modal__container"
            backdrop="static"
            open={isAddNewCustomerModalOpen}
            onClose={handleModalClose}
            header={header}
            footer={footer}
        >
            <>
                <Form
                    className="add-modal__form"
                    ref={formRef}
                    model={newCustomerFormModel}
                    formValue={newCustomerFormValue}
                    onChange={handleFormChange}
                >
                    <Form.Group className="add-modal__form-group">
                        <Form.ControlLabel>
                            {t("addNewCustomer.customerName.title")}
                            <sup className="Required__sup">*</sup>
                        </Form.ControlLabel>
                        <Form.Control
                            placeholder={t(
                                "addNewCustomer.customerName.placeholder"
                            )}
                            name={formKey.customerName}
                        />
                    </Form.Group>
                    <Form.Group className="add-modal__form-group">
                        <Form.ControlLabel>
                            {t("addNewCustomer.customerType.title")}
                            <sup className="Required__sup">*</sup>
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={SelectPicker}
                            placeholder={t(
                                "addNewCustomer.customerType.placeholder"
                            )}
                            block
                            searchable={false}
                            data={customerTypesSelectPickerData}
                            name={formKey.customerType}
                        />
                    </Form.Group>
                    <Form.Group className="add-modal__form-group">
                        <Form.ControlLabel>
                            {t("addNewCustomer.partners.title")}
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={CheckPicker}
                            placeholder={t(
                                "addNewCustomer.partners.placeholder"
                            )}
                            block
                            disabled={isPartnersDropdownDisabled}
                            data={partners}
                            name={formKey.partnerIds}
                            renderMenuItem={renderPartnerMenuItem}
                        />
                    </Form.Group>
                    {!isPartner && appSettings.isDeliverablesEnabled && (
                        <>
                            <Form.Group className="add-modal__form-group">
                                <Form.ControlLabel>
                                    {t("addNewCustomer.template.title")}
                                </Form.ControlLabel>
                                <Form.Control
                                    accepter={SelectPicker}
                                    placeholder={t(
                                        "addNewCustomer.template.placeholder"
                                    )}
                                    block
                                    data={emailTemplatesSelectPickerData}
                                    name={formKey.emailTemplateId}
                                />
                            </Form.Group>
                            <Form.Group
                                className={cn(
                                    "add-modal__form-group",
                                    "emails-section"
                                )}
                            >
                                <EmailAddressesBox
                                    formRef={mainFormRef}
                                    emailAddress={mainEmailAddress}
                                    resultEmailAddresses={
                                        resultMainEmailAddresses
                                    }
                                    isResultArray={isMainResultArray}
                                    boxLabel={t(
                                        "customerDetails.packageDelivery.mainRecipientTitle"
                                    )}
                                    tooltipText={t(
                                        "customerDetails.packageDelivery.emailBoxTooltip"
                                    )}
                                    setEmailAddress={setMainEmailAddress}
                                    setResultEmailAddresses={
                                        setResultMainEmailAddresses
                                    }
                                />
                                <EmailAddressesBox
                                    formRef={copyFormRef}
                                    emailAddress={copyEmailAddress}
                                    resultEmailAddresses={
                                        resultCopyEmailAddresses
                                    }
                                    isResultArray={isCopyResultArray}
                                    boxLabel={t(
                                        "customerDetails.packageDelivery.copyRecipientTitle"
                                    )}
                                    tooltipText={t(
                                        "customerDetails.packageDelivery.emailBoxTooltip"
                                    )}
                                    setEmailAddress={setCopyEmailAddress}
                                    setResultEmailAddresses={
                                        setResultCopyEmailAddresses
                                    }
                                />
                            </Form.Group>
                        </>
                    )}

                    <Form.Group className="AddNewCustomer__form-group">
                        <Form.ControlLabel>
                            {t("addNewCustomer.regions.title")}
                            <sup className="Required__sup">*</sup>
                        </Form.ControlLabel>
                        <Form.Control
                            accepter={CheckboxGroup}
                            className="add-modal__form-group"
                            name={formKey.regions}
                            inline
                        >
                            {regionCheckboxes}
                        </Form.Control>
                    </Form.Group>
                </Form>
                <ConsentsContainer />
                <div className="Required__comment">
                    <sup className="Required__sup">*</sup>
                    {t("addNewCustomer.requiredFields")}
                </div>
            </>
        </ModalContainer>
    );
};

export default AddNewCustomer;
