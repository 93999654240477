import {
    IWorkflowBase,
    WorkflowStatus,
    WorkflowStepStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { isEveryWorkflowStepCompleted } from "utils/tenant-workflows/tenant-workflows-helper";
import { Message } from "rsuite";
import parse from "html-react-parser";
import { template } from "lodash";
import { TypeAttributes } from "rsuite/esm/internals/types";

interface IComponentProps {
    workflow: IWorkflowBase;
}

const TenantDeleteProgressMessage: React.FC<IComponentProps> = ({
    workflow,
}: IComponentProps) => {
    const { t } = useTranslation();

    const failedStep = workflow.steps.find(
        (step) => step.status === WorkflowStepStatus.Failed
    );

    const getCompletionMessageProps = (
        workFlowStatus: WorkflowStatus
    ): {
        type: TypeAttributes.Status;
        description: string;
    } => {
        switch (workFlowStatus) {
            case WorkflowStatus.Failed:
                return {
                    type: "error",
                    description: t("tenantDelete.progressModal.errorMessage"),
                };
            default:
                return {
                    type: "info",
                    description: t("tenantDelete.progressModal.successMessage"),
                };
        }
    };

    const completionMessageProps = getCompletionMessageProps(workflow.status);
    return (
        <>
            {isEveryWorkflowStepCompleted(workflow.steps) ? (
                <Message showIcon {...completionMessageProps} />
            ) : (
                failedStep && (
                    <Message showIcon type="warning">
                        {parse(
                            template(
                                t(
                                    "tenantDelete.progressModal.warningSkipMessage"
                                )
                            )({
                                failedStepName: failedStep.actionDescription,
                            })
                        )}
                    </Message>
                )
            )}
        </>
    );
};

export default TenantDeleteProgressMessage;
