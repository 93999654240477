import React, { useEffect, useState } from "react";
import {
    IEnrollCache,
    IEnrollStep,
    IGuestTenantsData,
    StepStatus,
} from "redux/environments/tenants/enroll-tenant/enroll-tenant-types";
import { useTranslation } from "react-i18next";
import useUrlToPathConversion from "hooks/useUrlToPathConvertation";
import parse, { Element as HtmlParserElement } from "html-react-parser";
import { template } from "lodash";
import { useLocation } from "react-router";
import cn from "classnames";
import css from "./GuestTenantsStep.module.css";
import { NavLink } from "react-router-dom";
import { Checkbox, Message, useToaster } from "rsuite";
import { isDooapGuestTenantAllowed } from "api/environments/tenants/guest-tenants-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";

interface IComponentProps {
    activeStep: IEnrollStep | null;
    orchestratorInstanceId: string;
    activeEnvMoniker: string;
    fetchEnrollCache: (
        environmentMoniker: string,
        orchestratorInstanceId: string
    ) => Promise<[IEnrollCache | null, number]>;
    setActiveStepData: (data: string | null) => void;
    showLoader: (isVisible: boolean) => void;
}

const GuestTenantsStep: React.FC<IComponentProps> = ({
    activeStep,
    orchestratorInstanceId,
    activeEnvMoniker,
    fetchEnrollCache,
    setActiveStepData,
    showLoader,
}: IComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();

    const { pathname } = useLocation();
    const { params } = useUrlToPathConversion(pathname);
    const envName = params.environmentName;
    const guestTenantsPageLinkClassName = "guest-tenants-page-link";

    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [addDooapGuestTenant, setAddDooapGuestTenant] =
        useState<boolean>(false);
    const [isCheckboxEnabled, setIsCheckboxEnabled] = useState<boolean>(true);
    const [isGuestTenantAllowed, setIsGuestTenantAllowed] =
        useState<boolean>(true);

    const parser = (input: string) =>
        parse(input, {
            replace: (domNode) => {
                if (
                    domNode instanceof HtmlParserElement &&
                    domNode.attribs.class === guestTenantsPageLinkClassName
                ) {
                    return (
                        <NavLink
                            to={`/environments/${envName}/enroll-tenant/${orchestratorInstanceId}/guest-tenants`}
                        >
                            {t(
                                "enrollTenant.guestTenants.guestTenantsLinkText"
                            )}
                        </NavLink>
                    );
                }
            },
        });

    const fetchData = (environmentMoniker: string, enrollId: string) => {
        if (
            environmentMoniker &&
            enrollId &&
            activeStep?.status !== StepStatus.Success
        ) {
            showLoader(true);
            fetchEnrollCache(environmentMoniker, enrollId)
                .then(([payload, statusCode]) => {
                    if (payload && statusCode === constants.statusCode.OK) {
                        if (payload.tenantMoniker) {
                            return isDooapGuestTenantAllowed(
                                activeEnvMoniker,
                                payload.tenantMoniker
                            ).then(([payload, statusCode]) => {
                                if (statusCode === constants.statusCode.OK) {
                                    setIsGuestTenantAllowed(payload ?? false);
                                    setAddDooapGuestTenant(payload ?? false);
                                } else {
                                    const errorMessage = getErrorMessage(
                                        String(payload),
                                        statusCode
                                    );
                                    toaster.push(
                                        <Message type="error" showIcon>
                                            {errorMessage}
                                        </Message>,
                                        {
                                            duration:
                                                constants.errorAlertDurationMsec,
                                        }
                                    );
                                }
                            });
                        }
                    }
                    return Promise.resolve();
                })
                .then(() => {
                    setIsFetched(true);
                })
                .finally(() => {
                    showLoader(false);
                });
        }
    };

    useEffect(() => {
        if (activeStep?.status === StepStatus.Success) {
            try {
                const stepHistory: IGuestTenantsData =
                    JSON.parse(activeStep.data || "") || "";

                setIsCheckboxEnabled(false);

                if (stepHistory) {
                    setAddDooapGuestTenant(stepHistory.addDooapGuestTenant);
                }
            } catch {
                return;
            }
        }
    }, [activeStep]);

    useEffect(() => {
        if (activeEnvMoniker && orchestratorInstanceId && !isFetched) {
            fetchData(activeEnvMoniker, orchestratorInstanceId);
        }
    }, [activeEnvMoniker, orchestratorInstanceId, isFetched]);

    useEffect(() => {
        setActiveStepData(
            JSON.stringify({
                addDooapGuestTenant: addDooapGuestTenant,
            })
        );
    }, [addDooapGuestTenant]);

    const content = (
        <div className={cn(css.guestTenantLink, css.stepContent)}>
            {parser(
                template(t("enrollTenant.guestTenants.message"))({
                    guestTenantsPageLinkClassName:
                        guestTenantsPageLinkClassName,
                })
            )}
            <Checkbox
                checked={addDooapGuestTenant}
                onChange={(_, checked) => {
                    setAddDooapGuestTenant(checked);
                }}
                disabled={!isCheckboxEnabled || !isGuestTenantAllowed}
            >
                {t("enrollTenant.guestTenants.addDooapGuestTenantTitle")}
            </Checkbox>
            {!isGuestTenantAllowed && (
                <p className={css.warningMsg}>
                    {t("enrollTenant.guestTenants.warningMsg")}
                </p>
            )}
        </div>
    );

    return activeStep && content;
};

export default GuestTenantsStep;
