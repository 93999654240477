import React from "react";
import { IconButton, Table } from "rsuite";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
type TableCellProps = Parameters<typeof Table.Cell>[0];

interface ComponentProps extends TableCellProps {
    expandedRowKey: string;
    rowDataKey?: string | number | symbol;
    onChange: (rowData?: any) => void;
}

const ExpandableCell: React.FC<ComponentProps> = ({
    expandedRowKey,
    rowDataKey,
    onChange,
    ...props
}: ComponentProps) => {
    const isRowExpanded = () => {
        type RowDataObjectKey = keyof typeof props.rowData;
        const rowDataIdKey = rowDataKey as RowDataObjectKey;
        if (props.rowData)
            return expandedRowKey === props.rowData[rowDataIdKey];
    };

    return (
        <Table.Cell {...props}>
            <IconButton
                appearance="subtle"
                circle
                size="sm"
                onClick={() => {
                    onChange(props.rowData);
                }}
                icon={
                    isRowExpanded() ? (
                        <CollaspedOutlineIcon />
                    ) : (
                        <ExpandOutlineIcon />
                    )
                }
            />
        </Table.Cell>
    );
};

export default ExpandableCell;
