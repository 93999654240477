import React from "react";
import { useTranslation } from "react-i18next";
import { ITenantFeatureSettingWorkFlow } from "utils/tenant-workflows/tenant-workflows-types";
import { template } from "lodash";
import {
    isToggleValueEnabled,
    isWorkflowTerminated,
} from "utils/tenant-workflows/tenant-workflows-helper";
import { ToggleValueType } from "utils/tenant-workflows/tenant-workflows-types";
import parse from "html-react-parser";
import "../ProgressModal.less";

interface IComponentProps {
    workflow: ITenantFeatureSettingWorkFlow;
}

const TenantFeatureUsernameMessage: React.FC<IComponentProps> = ({
    workflow,
}: IComponentProps) => {
    const { t } = useTranslation();
    const actionText = isWorkflowTerminated(workflow)
        ? t("tenantFeatureSetting.progressModal.terminateAction")
        : isToggleValueEnabled(workflow.toggleValue, ToggleValueType.Setting)
          ? t("tenantFeatureSetting.progressModal.enableAction")
          : t("tenantFeatureSetting.progressModal.disableAction");
    return workflow.userName ? (
        <div className="progress-modal__username-section">
            {parse(
                template(t("tenantFeatureSetting.progressModal.turnedByUser"))({
                    settingName: workflow.featureName,
                    action: actionText,
                    username: workflow.userName,
                })
            )}
        </div>
    ) : (
        <></>
    );
};

export default TenantFeatureUsernameMessage;
