import { connect } from "react-redux";
import { RootState } from "redux/store";
import ConfigurationLayout from "./ConfigurationLayout";
import {
    setIsDeleteFetched,
    setTenantDeleteWorkflow,
} from "redux/environments/tenants/tenant-delete/tenant-delete-actions";
import {
    getActiveEnvironmentMoniker,
    getIsActiveEnvironmentSet,
} from "redux/environments/environment-selectors";
import { getActiveTenantMoniker } from "redux/environments/tenants/tenants-selectors";
import { setErrorTenantDeleteMessage } from "redux/common/initialization/initialization-actions";
import {
    getActiveTenantDeleteWorkflow,
    getIsDeleteFetched,
} from "redux/environments/tenants/tenant-delete/tenant-delete-selectors";
import { getErrorMessage } from "redux/common/initialization/initialization-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        envMoniker: getActiveEnvironmentMoniker(state),
        tenantMoniker: getActiveTenantMoniker(state),
        activeTenant: state.tenantsReducer.activeTenant,
        activeTenantDeleteWorkflow: getActiveTenantDeleteWorkflow(state),
        isActiveEnvironmentSet: getIsActiveEnvironmentSet(state),
        isDeleteFetched: getIsDeleteFetched(state),
        errorMessage: getErrorMessage(state),
    };
};
const ConfigurationLayoutContainer = connect(mapStateToProps, {
    setTenantDeleteWorkflow,
    setErrorTenantDeleteMessage,
    setIsDeleteFetched,
})(ConfigurationLayout) as React.FC;

export default ConfigurationLayoutContainer;
