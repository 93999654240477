import React, { ReactElement } from "react";
import { IconButton, Table } from "rsuite";
type TableCellProps = Parameters<typeof Table.Cell>[0];

interface ComponentProps extends TableCellProps {
    icon: ReactElement<any, any>;
    onChange: (rowData?: any) => void;
}

const ActionCell: React.FC<ComponentProps> = ({
    icon,
    onChange,
    ...props
}: ComponentProps) => {
    return (
        <Table.Cell>
            <IconButton
                appearance="subtle"
                circle
                size="sm"
                onClick={() => {
                    onChange(props.rowData);
                }}
                icon={icon}
            />
        </Table.Cell>
    );
};

export default ActionCell;
