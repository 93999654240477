import {
    State,
    Actions,
    SET_ENVIRONMENTS,
    SET_ACTIVE_ENVIRONMENT_BY_ID,
    SET_ACTIVE_ENVIRONMENT_TENANTS_MONIKERS,
    SET_ACTIVE_ENVIRONMENT_BY_NAME,
    SET_APP_REGISTRATIONS,
    SET_APP_REGISTRATION_INFO_OPENED,
} from "./environment-types";

const initialState = {
    environments: [],
    appRegistrations: [],
    activeEnvironment: {
        id: "",
        isEnrollAllowed: false,
        isDeleteAllowed: false,
        isTenantsViewAllowed: false,
        moniker: "",
        name: "",
        status: 0,
        tenantsStatusInfo: [],
    },
    activeEnvironmentTenantsMonikers: [],
    isAppRegistrationInfoOpened: false,
    isActiveEnvironmentSet: false,
};
const environmentReducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SET_ENVIRONMENTS:
            return {
                ...state,
                environments: [...action.environments],
            };

        case SET_APP_REGISTRATIONS:
            return {
                ...state,
                appRegistrations: [...action.appRegistrations],
            };

        case SET_ACTIVE_ENVIRONMENT_BY_ID:
            // eslint-disable-next-line no-case-declarations
            const activeEnvByID = state.environments.find(
                (env) => env.id === action.id
            );

            if (!activeEnvByID) {
                return {
                    ...state,
                    isActiveEnvironmentSet: false,
                };
            }

            return {
                ...state,
                activeEnvironment: {
                    ...activeEnvByID,
                    tenantsStatusInfo: [...activeEnvByID.tenantsStatusInfo],
                },
                isActiveEnvironmentSet: true,
            };

        case SET_ACTIVE_ENVIRONMENT_BY_NAME:
            // eslint-disable-next-line no-case-declarations
            const activeEnvByName =
                state.environments.find((env) => env.name === action.name) ||
                null;

            if (!activeEnvByName) {
                return {
                    ...state,
                    isActiveEnvironmentSet: false,
                };
            }

            return {
                ...state,
                activeEnvironment: {
                    ...activeEnvByName,
                    tenantsStatusInfo: [...activeEnvByName?.tenantsStatusInfo],
                },
                isActiveEnvironmentSet: true,
            };

        case SET_ACTIVE_ENVIRONMENT_TENANTS_MONIKERS:
            if (!state.isActiveEnvironmentSet) {
                break;
            }

            // eslint-disable-next-line no-case-declarations
            const monikers: string[] = [];

            state.activeEnvironment.tenantsStatusInfo.forEach((tenant) => {
                monikers.push(tenant.moniker);
            });

            return {
                ...state,
                activeEnvironmentTenantsMonikers: [...monikers],
            };
        case SET_APP_REGISTRATION_INFO_OPENED:
            return {
                ...state,
                isAppRegistrationInfoOpened: action.isOpened,
            };
    }

    return state;
};

export default environmentReducer;
