import {
    State,
    Actions,
    ITenantPingInfo,
    UPDATE_ENVIRONMENT_PING_INFO,
    UPDATE_TENANT_PING_INFO,
} from "./tenant-ping-types";
import constants from "utils/constants";

const initialState = {
    pingInfo: [],
};
const pingReducer = (state: State = initialState, action: Actions) => {
    const getEnvIndex = (envMoniker: string) => {
        return state.pingInfo.findIndex(
            (ping) => ping.envMoniker === envMoniker
        );
    };

    switch (action.type) {
        case UPDATE_ENVIRONMENT_PING_INFO:
            action.envList.forEach((env, envIndex) => {
                const isPingInfoAlreadyStored =
                    state.pingInfo.findIndex(
                        (ping) => env.moniker === ping.envMoniker
                    ) !== constants.defaultIndex;

                if (!isPingInfoAlreadyStored) {
                    const tenantListPingInfo: ITenantPingInfo[] = [];

                    env.tenantsStatusInfo.forEach((tenant, tenantIndex) => {
                        tenantListPingInfo.push({
                            tenantId: tenant.id,
                            tenantPingStatus: tenant.status,
                            errorMessage:
                                state.pingInfo[envIndex]?.tenantListPingInfo[
                                    tenantIndex
                                ].errorMessage || "",
                        });
                    });

                    state.pingInfo[envIndex] = {
                        envMoniker: env.moniker,
                        tenantListPingInfo: tenantListPingInfo,
                    };
                }
            });

            return { ...state };

        case UPDATE_TENANT_PING_INFO:
            // eslint-disable-next-line no-case-declarations
            const envIndex = getEnvIndex(action.envMoniker);

            //  Get All pingTenants without pingTenant with old Status
            // eslint-disable-next-line no-case-declarations
            const newTenantListPingInfo = state.pingInfo[
                envIndex
            ].tenantListPingInfo.filter(
                (ping) => ping.tenantId !== action.pingInfo.tenantId
            );

            newTenantListPingInfo.push(action.pingInfo);

            state.pingInfo[envIndex].tenantListPingInfo = [
                ...newTenantListPingInfo,
            ];

            return { ...state };

        default:
            return state;
    }
};

export default pingReducer;
