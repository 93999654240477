import {
    SET_CUSTOMER_DETAILS,
    SET_CONSENT_STATUS,
    IState,
    Actions,
    SET_CUSTOMER_NAME,
    SET_EMAIL_SENT,
} from "./customer-details-types";

const initialState: IState = {
    customerDetails: null,
    isEmailSent: false,
};

const customerDetailsReducer = (
    state: IState = initialState,
    action: Actions
): IState => {
    switch (action.type) {
        case SET_CUSTOMER_DETAILS:
            return {
                ...state,
                customerDetails: action.customerDetails,
            };

        case SET_CONSENT_STATUS:
            if (state.customerDetails === null) {
                return state;
            }

            return {
                ...state,
                customerDetails: {
                    ...state.customerDetails,
                    regionConsents: state.customerDetails.regionConsents.map(
                        (regionConsent) => {
                            if (regionConsent.id === action.instanceId) {
                                return {
                                    ...regionConsent,
                                    consents: regionConsent.consents.map(
                                        (consent) => {
                                            if (
                                                consent.id === action.consentId
                                            ) {
                                                return {
                                                    ...consent,
                                                    status: action.consentStatus,
                                                };
                                            }
                                            return consent;
                                        }
                                    ),
                                };
                            }
                            return regionConsent;
                        }
                    ),
                },
            };

        case SET_CUSTOMER_NAME:
            if (state.customerDetails === null) {
                return state;
            }

            return {
                ...state,
                customerDetails: {
                    ...state.customerDetails,
                    name: action.customerName,
                },
            };
        case SET_EMAIL_SENT:
            return {
                ...state,
                isEmailSent: action.isEmailSent,
            };

        default:
            return state;
    }
};

export default customerDetailsReducer;
