import React from "react";
import { Button } from "rsuite";
import ModalContainer from "../Modal/ModalContainer";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "./ConfirmModal.less";

interface ComponentProps {
    isVisible: boolean;
    message: string;
    confirmCallbacks: any[];
    handleCancelModal: () => void;
}

const ConfirmModal: React.FC<ComponentProps> = ({
    isVisible,
    message,
    confirmCallbacks,
    handleCancelModal,
}: ComponentProps) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        confirmCallbacks.forEach((item) => item());
    };

    const handleCancel = () => {
        handleCancelModal();
    };
    return (
        <ModalContainer
            title={""}
            closeButton
            enforceFocus={true}
            className="confirm-modal"
            open={isVisible}
            onClose={() => handleCancel()}
            footer={
                <>
                    <Button onClick={handleConfirm}>
                        {t("confirmModal.confirm")}
                    </Button>
                    <Button onClick={handleCancel}>
                        {t("confirmModal.cancel")}
                    </Button>
                </>
            }
        >
            <div className="confirm-modal__message">{parse(message)}</div>
        </ModalContainer>
    );
};

export default ConfirmModal;
