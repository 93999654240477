import App from "./App";
import { connect } from "react-redux";
import {
    setModalMessage,
    setModalTitle,
} from "../../redux/common/modal-window/modal-window-actions";
import { setUserName } from "../../redux/authentication/authentication-actions";
import { RootState } from "redux/store";
import { compose } from "redux";

const mapStateToProps = (state: RootState) => {
    return {
        isAppSettingsFetched: state.appSettingsReducer.isAppSettingsFetched,
    };
};

const AppContainer = compose(
    connect(mapStateToProps, {
        setModalTitle,
        setModalMessage,
        setUserName,
    })
)(App) as React.FC;

export default AppContainer;
