import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Scheduler.less";
import {
    IScheduler,
    ScheduleEvent,
    ScheduleEventSubtype,
    ScheduleEventType,
} from "redux/environments/tenants/tenant-scheduler/tenant-scheduler-types";
import useInactiveEnvironment from "hooks/useInactiveEnvironment";
import useInactiveTenant from "hooks/useInactiveTenant";
import SchedulerNavigation from "components/Environments/Tenants/LeftMenu/Scheduler/SchedulerNavigation/SchedulerNavigation";
import ScheduleTimesContainer from "components/Environments/Tenants/LeftMenu/Scheduler/ScheduleTimes/ScheduleTimesContainer";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import { loadFeatureConfigurations } from "api/environments/tenants/tenant-feature-configurations-api";
import { getErrorMessage } from "api/defaults";
import constants from "utils/constants";
import useRequestCancellation from "hooks/useRequestCancellation";
import Axios from "axios";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    errorMessage: string;
    isUnsavedChangeAvailable: boolean;
    getScheduler: (
        envMoniker: string,
        tenantMoniker: string,
        eventType: ScheduleEventType,
        subtype: ScheduleEventSubtype
    ) => Promise<[IScheduler | null, number]>;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    pushScheduler: (
        envMoniker: string,
        tenantMoniker: string,
        scheduler: IScheduler
    ) => Promise<number>;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}

const Scheduler: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    errorMessage,
    isUnsavedChangeAvailable,
    getScheduler,
    pushScheduler,
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
    setRefreshActivated,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [activeSchedulerTab, setActiveSchedulerTab] = useState("email");
    const [schedulerErrorMessage, setSchedulerErrorMessage] = useState("");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [isTenantConfigurationsFetched, setIsTenantConfigurationsFetched] =
        useState<boolean>(false);
    const [isMlSettingChecked, setIsMlSettingChecked] =
        useState<boolean>(false);
    const [isRecurringInvoicesEnabled, setIsRecurringInvoicesEnabled] =
        useState<boolean>(false);

    const cancelTokenSource = useRequestCancellation();

    const getSchedulerEvent = (): ScheduleEvent => {
        switch (activeSchedulerTab) {
            case "email":
                return {
                    type: ScheduleEventType.Email,
                    subtype: ScheduleEventSubtype.OpenInvoiceEmail,
                };
            case "vendorPrepaymentCheck":
                return {
                    type: ScheduleEventType.VendorPrepaymentRegisterCheck,
                    subtype: ScheduleEventSubtype.OpenInvoiceEmail,
                };
            case "invoiceWaitingForPr":
                return {
                    type: ScheduleEventType.InvoiceWaitingForPrCheck,
                    subtype: ScheduleEventSubtype.OpenInvoiceEmail,
                };
            case "mlModelReTraining":
                return {
                    type: ScheduleEventType.MachineLearningModelReTraining,
                    subtype: ScheduleEventSubtype.OpenInvoiceEmail,
                };
            case "poOnHoldMonthly":
                return {
                    type: ScheduleEventType.Email,
                    subtype: ScheduleEventSubtype.OnHoldMonthlyInvoiceEmail,
                };
            case "onHoldDueDate":
                return {
                    type: ScheduleEventType.Email,
                    subtype: ScheduleEventSubtype.OnHoldDueDateInvoiceEmail,
                };
            case "recurringInvoices":
                return {
                    type: ScheduleEventType.RecurringInvoices,
                    subtype: ScheduleEventSubtype.OpenInvoiceEmail,
                };
            default:
                return {
                    type: ScheduleEventType.Email,
                    subtype: ScheduleEventSubtype.OpenInvoiceEmail,
                };
        }
    };

    const getTenantConfigurations = (
        envMoniker: string,
        tenantMoniker: string
    ) => {
        setIsDataLoading(true);
        Promise.all([
            loadFeatureConfigurations(
                envMoniker,
                tenantMoniker,
                cancelTokenSource
            )
                .then(([payload, statusCode]) => {
                    if (statusCode === constants.statusCode.OK && payload) {
                        setIsRecurringInvoicesEnabled(
                            payload.recurringInvoicesEnabled
                        );
                        setIsMlSettingChecked(
                            payload.codingPredictionEnabled ||
                                payload.salesTaxPredictionEnabled ||
                                payload.workflowPredictionEnabled
                        );
                    } else {
                        const errorMessage = getErrorMessage(
                            String(payload),
                            statusCode
                        );
                        setSchedulerErrorMessage(errorMessage);
                    }
                    setIsUnsavedChangeAvailable(false);
                })
                .catch((error: Error) => {
                    const axiosError = error;
                    if (!Axios.isCancel(axiosError)) {
                        setSchedulerErrorMessage(error.message);
                    }
                }),
        ]).then(() => {
            setIsDataLoading(false);
            setIsTenantConfigurationsFetched(true);
            setActiveSchedulerTab("email");
        });
    };

    useEffect(() => {
        if (!isTenantConfigurationsFetched && envMoniker && tenantMoniker) {
            getTenantConfigurations(envMoniker, tenantMoniker);
        }
    }, [
        envMoniker,
        tenantMoniker,
        isTenantConfigurationsFetched,
        setIsTenantConfigurationsFetched,
    ]);

    useInactiveTenant();
    useInactiveEnvironment();

    return (
        <div className="Scheduler_container">
            {!(errorMessage.length > 0 || schedulerErrorMessage.length > 0) && (
                <>
                    <h1 className="Scheduler_pageHeader">
                        {t("tenantConfig.scheduler.header")}
                    </h1>
                    <SchedulerNavigation
                        active={activeSchedulerTab}
                        isUnsavedChangeAvailable={isUnsavedChangeAvailable}
                        isDataLoading={isDataLoading}
                        isRecurringInvoicesEnabled={isRecurringInvoicesEnabled}
                        setActiveSchedulerTab={setActiveSchedulerTab}
                        setModalConfirmCallback={setModalConfirmCallback}
                        setIsUnsavedChangeAvailable={
                            setIsUnsavedChangeAvailable
                        }
                        setRefreshActivated={setRefreshActivated}
                    />
                </>
            )}

            <ScheduleTimesContainer
                envMoniker={envMoniker}
                tenantMoniker={tenantMoniker}
                scheduleEvent={getSchedulerEvent()}
                isTenantConfigurationsFetched={isTenantConfigurationsFetched}
                errorMessage={errorMessage}
                isMlSettingChecked={isMlSettingChecked}
                schedulerErrorMessage={schedulerErrorMessage}
                setIsUnsavedChangeAvailable={setIsUnsavedChangeAvailable}
                getScheduler={getScheduler}
                pushScheduler={pushScheduler}
                setScheduleTimeErrorResponseMessage={setSchedulerErrorMessage}
                getTenantConfigurations={getTenantConfigurations}
                setIsDataLoading={setIsDataLoading}
            />
        </div>
    );
};

export default Scheduler;
