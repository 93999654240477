import * as React from "react";
import { Loader, Modal, ModalProps } from "rsuite";
import ErrorSectionContainer from "components/Common/ErrorSection/ErrorSectionContainer";
import { useTranslation } from "react-i18next";

interface ComponentProps extends ModalProps {
    errorMessage: string;
    isModalLoaderVisible: boolean;
    isModalOpen: boolean;
    header?: React.ReactNode;
    title?: string;
    children: React.ReactNode;
    footer: React.ReactNode;
    closeButton?: boolean;
}

const ModalPage: React.FC<ComponentProps> = ({
    errorMessage,
    isModalLoaderVisible,
    isModalOpen,
    header,
    title,
    footer,
    children,
    closeButton,
    ...rest
}: ComponentProps) => {
    const { t } = useTranslation();
    return (
        <Modal open={isModalOpen} {...rest}>
            <Modal.Header closeButton={closeButton}>
                {header ? header : <Modal.Title>{title}</Modal.Title>}
            </Modal.Header>
            <Modal.Body
                style={{
                    padding: isModalLoaderVisible ? `40px` : `0`,
                    maxHeight: "60vh",
                }}
            >
                {isModalLoaderVisible ? (
                    <Loader
                        center
                        size="md"
                        speed="slow"
                        content={t("loader.defaultContent")}
                    />
                ) : errorMessage.length ? (
                    <ErrorSectionContainer isTitleVisible={false} />
                ) : (
                    children
                )}
            </Modal.Body>
            <Modal.Footer>{errorMessage.length <= 0 && footer}</Modal.Footer>
        </Modal>
    );
};

export default ModalPage;
