import {
    Actions,
    SET_ENVIRONMENT_HISTORY_OPENED,
    State,
} from "./environment-history-types";

const initialState = {
    isOpened: false,
    isFetched: false,
    history: [],
};
const environmentHistoryReducer = (
    state: State = initialState,
    action: Actions
) => {
    switch (action.type) {
        case SET_ENVIRONMENT_HISTORY_OPENED:
            return {
                ...state,
                isOpened: action.isOpened,
            };
    }

    return state;
};
export default environmentHistoryReducer;
