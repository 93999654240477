import React, { useImperativeHandle, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Schema, Form, FormInstance } from "rsuite";
import constants from "utils/constants";

interface IpAddressProps {
    cidr: string;
    rowIndex: number;
    handleIpAddressChange: (index: number, value: string) => void;
}

// eslint-disable-next-line react/display-name
const IpAddressRow = React.forwardRef(
    ({ cidr, rowIndex, handleIpAddressChange }: IpAddressProps, ref) => {
        const { t } = useTranslation();
        const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
        useImperativeHandle(ref, () => ({ validate }));

        const { StringType } = Schema.Types;
        const formModel = Schema.Model({
            cidr: StringType()
                .isRequired(t("generalValidationMessages.isRequired"))
                .pattern(
                    constants.cidrRegex,
                    t("generalValidationMessages.ipAddressNotCorrect")
                ),
        });

        function validate(): boolean | undefined {
            if (formRef.current) {
                return formRef.current?.check();
            }
        }

        return (
            <Form ref={formRef} model={formModel} formValue={{ cidr: cidr }}>
                <Form.Control
                    name="cidr"
                    value={cidr}
                    onChange={(value: string) =>
                        handleIpAddressChange(rowIndex, value)
                    }
                />
            </Form>
        );
    }
);

export default IpAddressRow;
