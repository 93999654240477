import Environments from "./Environments";
import { connect } from "react-redux";
import { RootState } from "redux/store";
import {
    setActiveEnvironmentById,
    setActiveEnvironmentTenantsMonikers,
} from "redux/environments/environment-actions";
import { compose } from "redux";
import { fetchEnvironments } from "redux/environments/environment-thunks";
import { getEnvironmentList } from "redux/environments/environment-selectors";

const mapStateToProps = (state: RootState) => {
    return {
        environmentList: getEnvironmentList(state),
        errorMessage: state.initializationReducer.errorResponseMessage,
    };
};

const EnvironmentsContainer = compose(
    connect(mapStateToProps, {
        setActiveEnvironmentById,
        setActiveEnvironmentTenantsMonikers,
        fetchEnvironments,
    })
)(Environments) as React.FC;

export default EnvironmentsContainer;
