import {
    Actions,
    SET_ADD_NEW_GUEST_TENANT_OPENED,
    State,
} from "./tenant-guest-tenants-types";

const initialState = {
    isAddNewGuestTenantOpened: false,
};
const guestTenantsReducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SET_ADD_NEW_GUEST_TENANT_OPENED:
            return {
                ...state,
                isAddNewGuestTenantOpened: action.isAddNewGuestTenantOpened,
            };
    }

    return state;
};

export default guestTenantsReducer;
