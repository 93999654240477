import React from "react";
import { Button, Checkbox } from "rsuite";
import ModalContainer from "../Modal/ModalContainer";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "./DeleteConfirmModal.less";

interface ComponentProps {
    isVisible: boolean;
    message: string;
    confirmCallbacks: any[];
    storeInvoiceVolumeEnabled: boolean;
    handleCancelModal: () => void;
    setStoreInvoiceVolumeEnabled: (value: boolean) => void;
}

const DeleteConfirmModal: React.FC<ComponentProps> = ({
    isVisible,
    message,
    confirmCallbacks,
    storeInvoiceVolumeEnabled,
    handleCancelModal,
    setStoreInvoiceVolumeEnabled,
}: ComponentProps) => {
    const { t } = useTranslation();
    const handleConfirm = () => {
        confirmCallbacks.forEach((item) => item());
        handleCancelModal();
    };
    const handleCancel = () => {
        handleCancelModal();
    };
    return (
        <ModalContainer
            title={""}
            closeButton
            enforceFocus={true}
            className="confirm-modal"
            open={isVisible}
            onClose={() => handleCancel()}
            footer={
                <>
                    <Button onClick={handleConfirm}>
                        {t("confirmModal.confirm")}
                    </Button>
                    <Button onClick={handleCancel}>
                        {t("confirmModal.cancel")}
                    </Button>
                </>
            }
        >
            <div className="confirm-modal__message">{parse(message)}</div>
            <Checkbox
                className="confirm-modal__checkbox"
                checked={storeInvoiceVolumeEnabled}
                onChange={() =>
                    setStoreInvoiceVolumeEnabled(!storeInvoiceVolumeEnabled)
                }
            >
                {t("tenantDelete.storeInvoiceDailyVolumes")}
            </Checkbox>
        </ModalContainer>
    );
};

export default DeleteConfirmModal;
