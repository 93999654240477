import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    ButtonToolbar,
    Form,
    FormInstance,
    Message,
    Schema,
    useToaster,
} from "rsuite";
import ModalContainer from "components/Common/Modal/ModalContainer";
import "./GuestTenantConsents.less";
import {
    IGuestTenant,
    IGuestTenantInput,
    IUpdateConsentStatus,
} from "redux/environments/tenants/tenant-guest-tenants/tenant-guest-tenants-types";
import {
    ConsentStatusType,
    IConsent,
} from "redux/customers/customer-details/customer-details-types";
import { regionNames } from "redux/customers/add-new-customer/add-new-customer-types";
import {
    updateGuestTenant,
    updateGuestTenantConsentStatus,
} from "api/environments/tenants/guest-tenants-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";
import RefreshButton from "components/Common/RefreshButton/RefreshButton";
import { IModalCallback } from "redux/common/unsaved-data/unsaved-data-types";
import { v4 as uuid } from "uuid";
import { cloneDeep, isEqual } from "lodash";

interface ComponentProps {
    envMoniker: string;
    tenantMoniker: string;
    isOpened: boolean;
    isUnsavedChangeAvailable: boolean;
    setIsOpened: (isOpened: boolean) => void;
    tenant: IGuestTenant;
    allTenants: IGuestTenant[] | null | undefined;
    setModalLoaderVisibility: (isModalLoaderVisible: boolean) => void;
    refreshGuestTenants: () => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setModalConfirmCallback: (modalConfirmCallback: IModalCallback) => void;
    setRefreshActivated: (isRefreshActivated: boolean) => void;
}
const GuestTenantConsents: React.FC<ComponentProps> = ({
    envMoniker,
    tenantMoniker,
    isOpened,
    isUnsavedChangeAvailable,
    setIsOpened,
    tenant,
    allTenants,
    setModalLoaderVisibility,
    refreshGuestTenants,
    setIsUnsavedChangeAvailable,
    setModalConfirmCallback,
    setRefreshActivated,
}: ComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const formRef = useRef<FormInstance>(null);
    const defaultFormValue = {
        tenantName: "",
    };
    const [tenantName, setTenantName] = useState<string>("");
    const [allTenantNames, setAllTenantNames] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formValue, setFormValue] = useState(defaultFormValue);
    const [consents, setConsents] = useState<IConsent[]>([]);
    const [changedConsents, setChangedConsents] = useState<IConsent[]>([]);
    const [originalTenant, setOriginalTenant] = useState<IGuestTenant>();

    const formKey = {
        tenantName: "tenantName",
    };
    const model = Schema.Model({
        tenantName: Schema.Types.StringType()
            .isRequired(
                t("tenantConfig.guestTenants.newGuestTenant.isRequired")
            )
            .maxLength(
                constants.maxGuestTenantNameLengthLimit,
                t(
                    "tenantConfig.guestTenants.newGuestTenant.maxGuestTenantNameLengthError"
                )
            )
            .minLength(
                constants.minNameLengthLimit,
                t("tenantConfig.guestTenants.newGuestTenant.minLengthError")
            )
            .addRule((enteredValue: string) => {
                return (
                    allTenantNames.indexOf(enteredValue.trim()) < 0 ||
                    (originalTenant && enteredValue === originalTenant.name) ||
                    false
                );
            }, t("tenantConfig.guestTenants.newGuestTenant.nameAlreadyExist"))
            .addRule((enteredValue: string) => {
                const final = enteredValue.trim();
                return final.length >= constants.minNameLengthLimit;
            }, t("tenantConfig.guestTenants.newGuestTenant.minLengthError")),
    });

    const handleModalClose = () => {
        if (isUnsavedChangeAvailable) {
            setModalConfirmCallback({
                id: uuid(),
                isAutoDeleteResistant: false,
                isPageRedirect: true,
                callback: () => {
                    setIsUnsavedChangeAvailable(false);
                    setIsOpened(false);
                    resetData();
                },
            });
            setRefreshActivated(true);
            return;
        }

        setIsOpened(false);
    };

    const resetData = () => {
        if (originalTenant) {
            setTenantName(originalTenant.name);
            setFormValue({
                tenantName: originalTenant.name,
            });
        }

        setChangedConsents([]);
        if (tenant && tenant.regionConsents && tenant.regionConsents.consents) {
            setConsents(
                tenant.regionConsents.consents.map((value) => cloneDeep(value))
            );
        } else {
            setConsents([]);
        }
    };

    async function updateConsentStatus(
        tenantId: string,
        consentId: string,
        status: ConsentStatusType
    ) {
        const body: IUpdateConsentStatus = {
            type: status,
        };

        const [payload, statusCode] = await updateGuestTenantConsentStatus(
            envMoniker,
            tenantMoniker,
            tenantId,
            consentId,
            body
        );

        if (
            statusCode !== constants.statusCode.OK &&
            statusCode !== constants.statusCode.NoContent
        ) {
            const errorMessage = getErrorMessage(String(payload), statusCode);
            toaster.push(
                <Message showIcon type="error">
                    {errorMessage}
                </Message>,
                {
                    duration: constants.alertDurationMsec,
                }
            );
            return false;
        } else {
            return true;
        }
    }

    async function updateName() {
        const updateObject: IGuestTenantInput = {
            name: tenantName.trim(),
        };
        const [payload, status] = await updateGuestTenant(
            envMoniker,
            tenantMoniker,
            tenant.id,
            updateObject
        );

        if (status !== constants.statusCode.OK) {
            const errorMessage = getErrorMessage(String(payload), status);
            toaster.push(
                <Message showIcon type="error">
                    {errorMessage}
                </Message>,
                {
                    duration: constants.alertDurationMsec,
                }
            );
            return false;
        } else {
            return true;
        }
    }

    const checkUnsavedChanges = () => {
        if (tenant && originalTenant) {
            const changes =
                !isEqual(tenantName, originalTenant.name) ||
                changedConsents.length > 0;
            setIsUnsavedChangeAvailable(changes);
        }
    };

    const handleSubmit = async () => {
        if (!formRef.current?.check()) {
            return;
        }
        setIsLoading(true);
        setModalLoaderVisibility(true);

        const promises: any[] = [];
        if (originalTenant && tenantName != originalTenant.name) {
            promises.push(updateName());
        }

        changedConsents.forEach((consent) => {
            promises.push(
                updateConsentStatus(
                    tenant?.id,
                    consent.id,
                    ConsentStatusType.Pending
                )
            );
        });

        Promise.all(promises).then((res) => {
            if (res.every((value) => value)) {
                setIsUnsavedChangeAvailable(false);
                handleModalClose();
                setIsOpened(false);
            }
            setIsLoading(false);
            setModalLoaderVisibility(false);
            refreshGuestTenants();
        });
    };

    const resetConsentStatus = (consent: IConsent) => {
        let newList = [...consents];
        const index = newList.indexOf(consent);
        const newConsent = {
            ...cloneDeep(consent),
            status: ConsentStatusType.Pending,
        };

        newList[index] = newConsent;

        setConsents(newList);

        newList = [...changedConsents];
        newList.push(newConsent);

        setChangedConsents(newList);
    };

    useEffect(() => {
        checkUnsavedChanges();
    }, [changedConsents, tenantName]);

    useEffect(() => {
        if (tenant && tenant.regionConsents && tenant.regionConsents.consents) {
            setConsents(
                tenant.regionConsents.consents.map((value) => cloneDeep(value))
            );
        } else {
            setConsents([]);
        }

        setOriginalTenant(cloneDeep(tenant));
        setTenantName(tenant.name);
        setFormValue({
            tenantName: tenant.name,
        });
    }, [tenant]);

    const header = (
        <h1>{t("tenantConfig.guestTenants.consentDialog.title")}</h1>
    );

    const footer = (
        <ButtonToolbar
            className="custom-modal__button-toolbar"
            style={{ marginTop: "21px" }}
        >
            <Button
                type="submit"
                appearance="primary"
                disabled={isLoading || !isUnsavedChangeAvailable}
                onClick={handleSubmit}
            >
                {t("tenantConfig.guestTenants.consentDialog.save")}
            </Button>
            <Button
                appearance="ghost"
                disabled={isLoading}
                onClick={handleModalClose}
            >
                {t("tenantConfig.guestTenants.consentDialog.close")}
            </Button>
        </ButtonToolbar>
    );

    const getConsentStatus = (consents: IConsent): string => {
        if (consents.status === ConsentStatusType.Pending) {
            return t("tenantConfig.guestTenants.consentPending");
        }
        if (consents.status === ConsentStatusType.Failed) {
            return t("tenantConfig.guestTenants.consentFailed");
        }
        return t("tenantConfig.guestTenants.consentAccepted");
    };

    const tableRows = consents.map((consent, index) => {
        return (
            <tr className={"tableRowTenants"} key={String("rowIndex_" + index)}>
                <td className="cellTenants">
                    {consent.name} {consent.environmentName}
                </td>
                <td className="cellTenants">{getConsentStatus(consent)}</td>
                <td style={{ width: "30px", textAlign: "center" }}>
                    <RefreshButton
                        onClick={() => {
                            resetConsentStatus(consent);
                        }}
                        disabled={consent.status === ConsentStatusType.Pending}
                        tooltip={t(
                            "customerDetails.consentsTable.renewConsentButtonTooltipText"
                        )}
                    />
                </td>
            </tr>
        );
    });

    const allConsentsPending = tenant?.regionConsents.consents.every(
        (consent) =>
            consent.status === ConsentStatusType.Pending ||
            consent.status === ConsentStatusType.Failed
    );

    const adminUserName = tenant?.adminUserName
        ? tenant?.adminUserName
        : allConsentsPending &&
            !tenant?.adminUserName &&
            !tenant?.adminUserEmail
          ? t("consentWizardDetails.waitingForConsents")
          : t("consentWizardDetails.noAdminDataAvailable");

    const adminUserEmail = tenant?.adminUserEmail
        ? tenant?.adminUserEmail
        : allConsentsPending &&
            !tenant?.adminUserName &&
            !tenant?.adminUserEmail
          ? t("consentWizardDetails.waitingForConsents")
          : t("consentWizardDetails.noAdminDataAvailable");

    const region = tenant?.regionConsents.region ?? "";

    const handleFormChange = (v: any) => {
        setTenantName(v.tenantName);
        setFormValue(v);
        checkUnsavedChanges();
    };

    useEffect(() => {
        if (allTenants) {
            const names = allTenants.map((value) => value.name);
            setAllTenantNames(names);
        }
    }, [allTenants]);

    return (
        <ModalContainer
            open={isOpened}
            onClose={handleModalClose}
            header={header}
            footer={footer}
        >
            <Form
                className="Default__form"
                ref={formRef}
                model={model}
                formValue={formValue}
                onChange={handleFormChange}
            >
                <Form.Group className="Default__form-group">
                    <Form.ControlLabel>
                        {t("consentWizardDetails.guestTenantNameLabel")}
                    </Form.ControlLabel>
                    <Form.Control name={formKey.tenantName} />
                </Form.Group>
            </Form>
            <h4>
                {`${t("tenantConfig.guestTenants.consentDialog.description")} ${
                    regionNames[region] ?? region
                }`}
            </h4>
            <p></p>
            <>
                <table style={{ width: "100%" }}>
                    <thead>
                        <tr className="tableHeaderTenants">
                            <th className={"cellTenants"}>
                                {t(
                                    "tenantConfig.guestTenants.consentDialog.nameHeader"
                                )}
                            </th>
                            <th className={"cellTenants"}>
                                {t(
                                    "tenantConfig.guestTenants.consentDialog.statusHeader"
                                )}
                            </th>
                            <th style={{ width: "60px" }}>
                                {t(
                                    "tenantConfig.guestTenants.consentDialog.actionHeader"
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>{tableRows || []}</tbody>
                </table>
                <p></p>
                <Form className="Default__form">
                    <div>
                        <Form.Group className="Default__form-group">
                            <Form.ControlLabel>
                                {t(
                                    "consentWizardDetails.globalAdminNameInputLabel"
                                )}
                            </Form.ControlLabel>
                            <Form.Control
                                name={"adminUserName"}
                                value={adminUserName}
                                disabled
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group className="Default__form-group">
                            <Form.ControlLabel>
                                {t(
                                    "consentWizardDetails.globalAdminEmailInputLabel"
                                )}
                            </Form.ControlLabel>
                            <Form.Control
                                name={"adminUserEmail"}
                                value={adminUserEmail}
                                disabled
                            />
                        </Form.Group>
                    </div>
                </Form>
            </>
        </ModalContainer>
    );
};

export default GuestTenantConsents;
