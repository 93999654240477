import React, { useState } from "react";
import { Button, ButtonToolbar, Message, useToaster } from "rsuite";
import { useTranslation } from "react-i18next";
import {
    FailoverType,
    IFailedStepHandle,
    ITenantDeleteWorkflow,
    WorkflowStepStatus,
} from "utils/tenant-workflows/tenant-workflows-types";
import { handleFailedTenantDelete } from "api/environments/tenants/tenant-delete-api";
import constants from "utils/constants";
import { getErrorMessage } from "api/defaults";

interface IComponentProps {
    environmentMoniker: string;
    tenantMoniker: string;
    workflow: ITenantDeleteWorkflow;
    onClose?: () => void;
}

const TenantDeleteProgressModalFooter: React.FC<IComponentProps> = ({
    environmentMoniker,
    tenantMoniker,
    workflow,
    onClose,
}: IComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const [isRefreshLoading, seIsRefreshLoading] = useState(false);
    const [isSkipLoading, seIsSkipLoading] = useState(false);

    const isSomeStepFailed = workflow.steps.some(
        (step) => step.status === WorkflowStepStatus.Failed
    );

    const handleTenantDeleteFailed = (
        failedStepHandle: IFailedStepHandle,
        setLoading: (isLoading: boolean) => void
    ) => {
        setLoading(true);
        handleFailedTenantDelete(
            environmentMoniker,
            tenantMoniker,
            failedStepHandle
        ).then(([payload, status]) => {
            setLoading(false);
            if (status !== constants.statusCode.OK) {
                const errorMessage = getErrorMessage(String(payload), status);
                toaster.push(
                    <Message showIcon type="error" closable>
                        {errorMessage}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            }
        });
    };

    const handleRetryClick = () =>
        handleTenantDeleteFailed(
            { failoverType: FailoverType.Retry },
            seIsRefreshLoading
        );
    const handleSkipClick = () =>
        handleTenantDeleteFailed(
            { failoverType: FailoverType.Skip },
            seIsSkipLoading
        );

    return (
        <ButtonToolbar className="custom-modal__button-toolbar">
            {isSomeStepFailed && (
                <>
                    <Button
                        type="submit"
                        appearance="primary"
                        onClick={handleRetryClick}
                        loading={isRefreshLoading}
                    >
                        {t("tenantFeatureSetting.progressModal.button.retry")}
                    </Button>
                    <Button
                        type="submit"
                        appearance="primary"
                        onClick={handleSkipClick}
                        loading={isSkipLoading}
                    >
                        {t("tenantFeatureSetting.progressModal.button.skip")}
                    </Button>
                </>
            )}
            <Button
                appearance={isSomeStepFailed ? "ghost" : "primary"}
                onClick={onClose}
            >
                {t("tenantFeatureSetting.progressModal.button.close")}
            </Button>
        </ButtonToolbar>
    );
};

export default TenantDeleteProgressModalFooter;
