import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
    ICustomField,
    ICustomFieldInvoice,
    CustomFieldInvoiceType,
    ILineType,
} from "../custom-fields-types";
import {
    Button,
    Form,
    FormInstance,
    Message,
    Schema,
    useToaster,
} from "rsuite";
import { validateContent } from "utils/regex-helper";
import { useTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import InvoiceRowContainer from "../InvoiceRow/InvoiceRowContainer";
import constants from "utils/constants";
import SettingsFooterContainer from "components/Common/SettingsFooter/SettingsFooterContainer";

// this regular expression allows to use no more than 2 '/' in a row, and does not allow whitespaces
const xmlSelectorRegexExpr = /^(([\\/]{0,2})[^/\s])*$/;

interface IFormData {
    xmlPath: string;
    uiColumnName: string;
}

interface ComponentProps {
    customField: ICustomField;
    fieldIndex: number;
    isFetched: boolean;
    isTableInitialized: boolean;
    firstSetupCompleted: boolean;
    tablesPO: string[];
    tablesNonPO: string[];
    lineTypes: ILineType[];
    targetFieldsCache: any;
    customFieldsSaved: boolean;
    hasTenantErpCustomFieldSupport: boolean;
    setCustomFieldProperty: (
        name: string,
        value: any,
        rowIndex: number
    ) => void;
    handleSave: () => void;
}

export const FieldForm = React.forwardRef(
    (
        {
            customField,
            fieldIndex,
            isFetched,
            isTableInitialized,
            firstSetupCompleted,
            tablesPO,
            tablesNonPO,
            lineTypes,
            targetFieldsCache,
            customFieldsSaved,
            hasTenantErpCustomFieldSupport,
            setCustomFieldProperty,
            handleSave,
        }: ComponentProps,
        ref
    ) => {
        const invoicesPropertyName = "invoices";
        const deleteActionPropertyName = "deleteAction";
        const { t } = useTranslation();
        const toaster = useToaster();
        const formRef = useRef<FormInstance<Record<string, any>> | null>(null);
        const { StringType } = Schema.Types;

        useImperativeHandle(ref, () => ({ validate, cleanErrors }));

        const formModel = Schema.Model({
            xmlPath: StringType()
                .maxLength(
                    constants.maxCustomFieldLimit,
                    t("tenantConfig.customFieldsPage.validation.maxLength")
                )
                .addRule(
                    (value) => validateContent(value, xmlSelectorRegexExpr),
                    t("tenantConfig.customFieldsPage.validation.letters")
                ),
            uiColumnName: StringType()
                .isRequired(
                    t("tenantConfig.customFieldsPage.validation.required")
                )
                .maxLength(
                    constants.maxCustomFieldLimit,
                    t("tenantConfig.customFieldsPage.validation.maxLength")
                ),
        });

        const defaultFormData = {
            xmlPath: "",
            uiColumnName: "",
        };
        const [formData, setFormData] = useState<IFormData>({
            ...defaultFormData,
        });

        useEffect(() => {
            if (customField) {
                setFormData({
                    xmlPath: customField.xmlPath || "",
                    uiColumnName: customField.uiColumnName || "",
                });
            } else {
                setFormData(defaultFormData);
            }
        }, [customField]);

        useEffect(() => {
            if (!customField.uiColumnName && isFetched) {
                setCustomFieldProperty(
                    "uiColumnName",
                    `CustomData${fieldIndex + 1}`,
                    fieldIndex
                );
            }
        }, [isFetched]);

        const validate = (): boolean | undefined => {
            let rowsValid = true;
            rowRefs.current.forEach((value: any) => {
                if (value) {
                    rowsValid = value.validate() && rowsValid;
                }
            });
            return formRef.current?.check() && rowsValid;
        };

        const cleanErrors = () => {
            formRef.current?.cleanErrors();
            rowRefs.current.forEach((value: any) => {
                value?.cleanErrors();
            });
        };

        const handleFieldChange = (value: string, fieldName: string) => {
            const newForm = cloneDeep(formData);
            newForm.uiColumnName = value;
            setFormData({
                ...formData,
                [fieldName]: value,
            });

            setFormData(newForm);

            setCustomFieldProperty(fieldName, value, fieldIndex);
        };

        const setInvoiceRowProperty = (
            name: string,
            value: any,
            invoice: ICustomFieldInvoice
        ) => {
            if (customField.invoices) {
                const newInvoice = [...customField.invoices];
                const rowIndex = newInvoice.indexOf(invoice);
                newInvoice[rowIndex] = {
                    ...newInvoice[rowIndex],
                    [name]: value,
                };

                setCustomFieldProperty(
                    invoicesPropertyName,
                    newInvoice,
                    fieldIndex
                );
            } else {
                cleanErrors();
            }
        };

        const handleDeleteRow = (invoice: ICustomFieldInvoice) => {
            if (customField.invoices && !invoice.id) {
                const invoices = [...customField.invoices];
                invoices.splice(invoices.indexOf(invoice), 1);
                setCustomFieldProperty(
                    invoicesPropertyName,
                    invoices,
                    fieldIndex
                );
            } else if (customField.invoices && invoice.id) {
                setInvoiceRowProperty(deleteActionPropertyName, true, invoice);
            }

            cleanErrors();
        };

        function handleAddRow(type: CustomFieldInvoiceType) {
            let invoice: ICustomFieldInvoice[];

            if (!customField.invoices) {
                invoice = [];
            } else {
                invoice = [...customField.invoices];
            }

            const rowCount =
                type === CustomFieldInvoiceType.Po
                    ? invoice.filter(
                          (value) =>
                              !value.deleteAction &&
                              value.invoiceType === CustomFieldInvoiceType.Po
                      ).length
                    : invoice.filter(
                          (value) =>
                              !value.deleteAction &&
                              value.invoiceType === CustomFieldInvoiceType.NonPo
                      ).length;

            if (rowCount === constants.maxInvoiceRowCountInCustomField) {
                toaster.push(
                    <Message type="error" showIcon>
                        {t("tenantConfig.customFieldsPage.maxRowLimitReached")}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
                return;
            }

            invoice.push({
                targetTable: "",
                enabled: true,
                invoiceType: type,
                deleteAction: false,
                excludedLineTypes: [],
                id: null,
                targetField: "",
            });

            setCustomFieldProperty(invoicesPropertyName, invoice, fieldIndex);
        }

        const rowRefs = useRef([]);
        rowRefs.current.splice(0, rowRefs.current.length);
        const poRows: any = [];
        const nonPoRows: any = [];

        customField &&
            customField.invoices &&
            customField.invoices.length &&
            customField.invoices.forEach((item, index) => {
                if (item.deleteAction) {
                    return;
                }
                const getRef = (e: any) => {
                    if (e) {
                        rowRefs.current.push(e as never);
                    }
                };
                const row = (
                    <InvoiceRowContainer
                        tablesPO={tablesPO}
                        tablesNonPO={tablesNonPO}
                        lineTypes={lineTypes}
                        invoice={item}
                        key={`row${index}`}
                        deleteRow={handleDeleteRow}
                        fieldsCache={targetFieldsCache}
                        setInvoiceRowProperty={setInvoiceRowProperty}
                        ref={getRef}
                    />
                );

                if (item.invoiceType === CustomFieldInvoiceType.Po) {
                    poRows.push(row);
                } else {
                    nonPoRows.push(row);
                }
            });

        return (
            <>
                <div className={"columnName"}>
                    <Form.ControlLabel className={"label column-name-label"}>
                        {t("tenantConfig.customFieldsPage.table.dbColumnName")}:
                    </Form.ControlLabel>
                    <div>{customField.databaseColumnName}</div>
                </div>
                <Form ref={formRef} model={formModel} formValue={formData}>
                    <Form.Group className={"general-configurations__grid-item"}>
                        <Form.ControlLabel
                            className={"general-configurations__label"}
                        >
                            {t(
                                "tenantConfig.customFieldsPage.table.XMLElement"
                            )}
                            <Form.HelpText
                                className={"general-configurations__help"}
                                tooltip
                            >
                                {t(
                                    "tenantConfig.customFieldsPage.validation.xmlElement"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            className={`input ${
                                customField &&
                                customField.xmlPath &&
                                customField.xmlPath.length > 0
                                    ? "uppercase"
                                    : ""
                            }`}
                            readOnly={!isTableInitialized}
                            type="text"
                            name="xmlPath"
                            value={customField.xmlPath || ""}
                            placeholder={t(
                                "tenantConfig.customFieldsPage.placeholder"
                            )}
                            onChange={(value: any) => {
                                handleFieldChange(value, "xmlPath");
                            }}
                        />
                    </Form.Group>
                    <Form.Group className={"general-configurations__grid-item"}>
                        <Form.ControlLabel
                            className={"general-configurations__label"}
                        >
                            {t(
                                "tenantConfig.customFieldsPage.table.UIElementName"
                            )}
                            <sup className={"sup"}>*</sup>
                            <Form.HelpText
                                className={"general-configurations__help"}
                                tooltip
                            >
                                {t(
                                    "tenantConfig.customFieldsPage.validation.uiElement"
                                )}
                            </Form.HelpText>
                        </Form.ControlLabel>
                        <Form.Control
                            className={"general-configurations__form-control"}
                            readOnly={!isTableInitialized}
                            type="text"
                            name="uiColumnName"
                            value={customField.uiColumnName || ""}
                            placeholder={t(
                                "tenantConfig.customFieldsPage.placeholder"
                            )}
                            onChange={(value: any) => {
                                handleFieldChange(value, "uiColumnName");
                            }}
                        />
                    </Form.Group>
                </Form>

                {firstSetupCompleted &&
                    isTableInitialized &&
                    customFieldsSaved &&
                    hasTenantErpCustomFieldSupport && (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline",
                                }}
                            >
                                <span className={"tableCaption"}>PO</span>
                                <div style={{ flex: "1" }}></div>
                                <Button
                                    className={"back"}
                                    type={"submit"}
                                    appearance="ghost"
                                    onClick={() =>
                                        handleAddRow(CustomFieldInvoiceType.Po)
                                    }
                                    disabled={
                                        customField.invoices?.filter(
                                            (invoice) =>
                                                invoice.invoiceType ===
                                                CustomFieldInvoiceType.Po
                                        ) &&
                                        customField.invoices?.filter(
                                            (invoice) =>
                                                invoice.invoiceType ===
                                                CustomFieldInvoiceType.Po
                                        ).length >=
                                            constants.maxInvoiceRowCountInCustomField
                                    }
                                >
                                    {t("tenantConfig.customFieldsPage.addRow")}
                                </Button>
                            </div>

                            <div className={"fieldsTable poTable"}>
                                <div>
                                    <div className="tableHeaderFields">
                                        <div
                                            className={"cellFields headerCell"}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.active"
                                            )}
                                        </div>
                                        <div
                                            className={"cellFields headerCell"}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.targetTable"
                                            )}
                                            <sup className={"sup"}>*</sup>
                                        </div>
                                        <div
                                            className={"cellFields headerCell"}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.targetField"
                                            )}
                                            <sup className={"sup"}>*</sup>
                                            <Form.HelpText
                                                className={
                                                    "general-configurations__table-tooltip"
                                                }
                                                tooltip
                                            >
                                                {t(
                                                    "tenantConfig.customFieldsPage.validation.targetField"
                                                )}
                                            </Form.HelpText>
                                        </div>
                                        <div
                                            className={
                                                "cellFields headerCell action-cell"
                                            }
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.action"
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {poRows || []}
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline",
                                }}
                            >
                                <span className={"tableCaption"}>Non-PO</span>
                                <div style={{ flex: "1" }}></div>
                                <Button
                                    className={"back"}
                                    type={"submit"}
                                    appearance="ghost"
                                    onClick={() =>
                                        handleAddRow(
                                            CustomFieldInvoiceType.NonPo
                                        )
                                    }
                                    disabled={
                                        customField.invoices?.filter(
                                            (invoice) =>
                                                invoice.invoiceType ===
                                                CustomFieldInvoiceType.NonPo
                                        ) &&
                                        customField.invoices?.filter(
                                            (invoice) =>
                                                invoice.invoiceType ===
                                                CustomFieldInvoiceType.NonPo
                                        ).length >=
                                            constants.maxInvoiceRowCountInCustomField
                                    }
                                >
                                    {t("tenantConfig.customFieldsPage.addRow")}
                                </Button>
                            </div>

                            <div className={"fieldsTable nonPoTable"}>
                                <div>
                                    <div className="tableHeaderFields">
                                        <div
                                            className={"cellFields headerCell"}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.active"
                                            )}
                                        </div>
                                        <div
                                            className={"cellFields headerCell"}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.targetTable"
                                            )}
                                            <sup className={"sup"}>*</sup>
                                        </div>
                                        <div
                                            className={"cellFields headerCell"}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.targetField"
                                            )}
                                            <sup className={"sup"}>*</sup>
                                            <Form.HelpText
                                                className={
                                                    "general-configurations__table-tooltip"
                                                }
                                                tooltip
                                            >
                                                {t(
                                                    "tenantConfig.customFieldsPage.validation.targetField"
                                                )}
                                            </Form.HelpText>
                                        </div>
                                        <div
                                            className={"cellFields headerCell"}
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.excludedLineTypes"
                                            )}
                                        </div>
                                        <div
                                            className={
                                                "cellFields headerCell action-cell"
                                            }
                                        >
                                            {t(
                                                "tenantConfig.customFieldsPage.table.action"
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {nonPoRows || []}
                            </div>

                            <div className={"comment customFieldsComment"}>
                                <sup className={"sup"}>*</sup>
                                {t(
                                    "tenantConfig.customFieldsPage.requiredFields"
                                )}
                            </div>
                        </>
                    )}
                {firstSetupCompleted &&
                    isTableInitialized &&
                    customFieldsSaved && (
                        <SettingsFooterContainer
                            handleFormSubmit={handleSave}
                        />
                    )}
            </>
        );
    }
);

FieldForm.displayName = "FieldForm";

export default FieldForm;
