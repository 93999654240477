import { Drawer, Loader } from "rsuite";
import React, { useState } from "react";
import { ITenantVersionInfo } from "redux/environments/tenants/tenant-status/tenant-status-types";
import { ITenantPingInfo } from "redux/environments/tenants/tenants-types";
import { TenantStatus as TStatus } from "redux/environments/tenants/tenants-types";
import { useTranslation } from "react-i18next";
import RefreshButton from "components/Common/RefreshButton/RefreshButton";
import "./TenantStatus.less";
import { CancelTokenSource } from "axios";
import useRequestCancellation from "hooks/useRequestCancellation";

interface ComponentProps {
    activeEnvMoniker: string;
    tenantsPingInfo: ITenantPingInfo[];
    tenantVersionInfo: ITenantVersionInfo;
    isTenantStatusOpened: boolean;
    setTenantStatusOpened: (isOpened: boolean) => void;
    pingTenantInD365: (
        envMoniker: string,
        tenantId: string,
        cancelTokenSource: CancelTokenSource
    ) => Promise<void>;
}

const TenantStatus: React.FC<ComponentProps> = ({
    activeEnvMoniker,
    tenantsPingInfo,
    tenantVersionInfo,
    isTenantStatusOpened,
    setTenantStatusOpened,
    pingTenantInD365,
}: ComponentProps) => {
    const { t } = useTranslation();
    const pingInfo = tenantsPingInfo.find(
        (item) => item.tenantId === tenantVersionInfo.id
    );
    const [pingLoadingForTenantIds, setPingLoadingForTenantIds] = useState<
        string[]
    >([]);

    const cancelTokenSource = useRequestCancellation();

    const ping = async (id: string) => {
        setPingLoadingForTenantIds([...pingLoadingForTenantIds, id]);
        pingTenantInD365(activeEnvMoniker, id, cancelTokenSource).then(() => {
            setPingLoadingForTenantIds(
                pingLoadingForTenantIds.filter((id) => id !== id)
            );
        });
    };

    const getFetchStatusText = (fetchStatus: TStatus) => {
        switch (fetchStatus) {
            case TStatus.Error:
                return t("status.error");

            case TStatus.Enroll:
                return t("status.enroll");

            case TStatus.Deleting:
                return t("status.deleting");

            default:
                return t("status.normal");
        }
    };

    const getPingStatusText = (pingInfo: ITenantPingInfo | undefined) => {
        if (pingInfo?.errorMessage) {
            return t("status.error");
        } else {
            return t("status.normal");
        }
    };

    return (
        <Drawer
            open={isTenantStatusOpened}
            onExit={() => setTenantStatusOpened(false)}
            onClose={() => setTenantStatusOpened(false)}
            size="sm"
            className="sidebar"
        >
            <Drawer.Header>
                <Drawer.Title>
                    <h3>{t("tenantStatus.menuTitle")}</h3>
                    <div className="sidebar__tenant-name">
                        {tenantVersionInfo.name}
                    </div>
                </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body className="sidebar__body">
                <div>
                    <h4>{t("tenantStatus.fetch")}</h4>
                    <div
                        className={
                            tenantVersionInfo.fetchStatus === TStatus.Error
                                ? "sidebar__error"
                                : "sidebar__status"
                        }
                    >
                        {getFetchStatusText(tenantVersionInfo.fetchStatus)}
                    </div>
                    {tenantVersionInfo.fetchErrorMessage && (
                        <div className="sidebar__error-message">
                            {tenantVersionInfo.fetchErrorMessage}
                        </div>
                    )}
                </div>
                <div>
                    <h4>{t("tenantStatus.ping")}</h4>
                    <div>
                        <div className="sidebar__ping-status">
                            {pingLoadingForTenantIds.includes(
                                tenantVersionInfo.id
                            ) ? (
                                <Loader size="xs" />
                            ) : (
                                <span
                                    className={
                                        pingInfo?.errorMessage
                                            ? "sidebar__error"
                                            : "sidebar__status"
                                    }
                                >
                                    {getPingStatusText(pingInfo)}
                                </span>
                            )}
                            <RefreshButton
                                onClick={() => ping(tenantVersionInfo.id)}
                                disabled={pingLoadingForTenantIds.includes(
                                    tenantVersionInfo.id
                                )}
                                tooltip={t(
                                    "tenantDashboard.tenantCard.pingButtonTitle"
                                )}
                            />
                        </div>
                        {pingInfo?.errorMessage && (
                            <div className="sidebar__error-message">
                                {pingInfo?.errorMessage}
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <h4>{t("tenantStatus.responseTime")}</h4>
                    <div>
                        {tenantVersionInfo.d365ResponseTime
                            ? tenantVersionInfo.d365ResponseTime
                            : "-"}
                        {" ms"}
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
    );
};

export default TenantStatus;
