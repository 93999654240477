import * as React from "react";
import classNames from "classnames";
import { Panel, PanelProps } from "rsuite";

import "./Card.less";

interface ICard extends PanelProps {
    className?: string;
    status?: "normal" | "warning" | "error" | "enroll" | "deleting";
    onClick: (event: any) => void;
}

const Card: React.FC<ICard> = ({
    className,
    children,
    status,
    header,
    onClick,
    ...rest
}: ICard) => {
    return (
        <Panel
            {...rest}
            onClick={onClick}
            header={header}
            className={"card" + className}
        >
            <div className="card_body">
                <div
                    className={classNames(
                        {
                            "card__status-rectangle--normal":
                                status === "normal",
                        },
                        {
                            "card__status-rectangle--warning":
                                status === "warning",
                        },
                        {
                            "card__status-rectangle--enroll":
                                status === "enroll",
                        },
                        {
                            "card__status-rectangle--deleting":
                                status === "deleting",
                        },
                        {
                            "card__status-rectangle--error": status === "error",
                        }
                    )}
                />
                {children}
            </div>
        </Panel>
    );
};

export default Card;
