import React from "react";
import { Input, InputProps, InputGroup } from "rsuite";
import { useTranslation } from "react-i18next";
import "./SearchBar.less";
import SearchIcon from "@rsuite/icons/Search";
import CloseIcon from "@rsuite/icons/Close";

interface ComponentProps extends InputProps {
    searchText: string;
    setSearchText: (searchText: string, id: string) => void;
    activeEnvironmentId: string;
}
const SearchBar: React.FC<ComponentProps> = ({
    searchText,
    setSearchText,
    activeEnvironmentId,
}: ComponentProps) => {
    const { t } = useTranslation();

    const onChangeHandler = (value: string) => {
        setSearchText(value, activeEnvironmentId);
    };

    const onResetFilter = () => {
        setSearchText("", activeEnvironmentId);
    };

    return (
        <InputGroup className="search-bar__container">
            <InputGroup.Button className="search-bar__button">
                <SearchIcon />
            </InputGroup.Button>
            <Input
                className="search-bar__input-field"
                value={searchText}
                type="text"
                onChange={onChangeHandler}
                placeholder={t("tenantDashboard.searchBarPlaceholder")}
            />
            {searchText.length !== 0 && (
                <InputGroup.Button
                    onClick={onResetFilter}
                    className="search-bar__button"
                >
                    <CloseIcon />
                </InputGroup.Button>
            )}
        </InputGroup>
    );
};

export default SearchBar;
