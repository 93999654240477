import React, { useEffect, useState } from "react";
import { FlexboxGrid, IconButton } from "rsuite";
import ArrowUpLineIcon from "@rsuite/icons/ArrowUpLine";
import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import cn from "classnames";

import css from "./Collapsible.module.css";

interface ComponentProps {
    className?: string;
    open?: boolean;
    title: string | React.ReactElement;
    children: React.ReactNode;
    handleClick?: () => void;
}

const Collapsible: React.FC<ComponentProps> = ({
    className,
    open = false,
    title,
    children,
    handleClick,
}: ComponentProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(open);

    const handleCollapse = () => {
        setIsOpen(!isOpen);
        if (handleClick) {
            handleClick();
        }
    };

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    return (
        <div className={cn(className)}>
            <FlexboxGrid
                className={css.collapsibleHeaderContainer}
                align="middle"
                justify="start"
            >
                <FlexboxGrid.Item>{title}</FlexboxGrid.Item>
                <FlexboxGrid.Item>
                    <IconButton
                        appearance="subtle"
                        circle
                        size="sm"
                        onClick={() => {
                            handleCollapse();
                        }}
                        icon={
                            isOpen ? <ArrowUpLineIcon /> : <ArrowDownLineIcon />
                        }
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <div className={isOpen ? "" : css.hidden}>{children}</div>
        </div>
    );
};

export default Collapsible;
