import React, { FC, useState } from "react";
import Preloader from "../../components/Common/Preloader/Preloader";
import css from "./withLoader.module.css";

interface IComponentProps {
    [key: string]: any;
}

const withLoader =
    (Component: FC<any>): FC<IComponentProps> =>
    // eslint-disable-next-line react/display-name
    ({ ...props }: IComponentProps) => {
        const [isLoaderVisible, setIsLoaderVisible] = useState(false);

        const changeLoaderVisibility = (loaderVisibility: boolean) => {
            setIsLoaderVisible(loaderVisibility);
        };

        return (
            <>
                {isLoaderVisible && (
                    <Preloader
                        isLoaderVisible={isLoaderVisible}
                        className={css.loader}
                    />
                )}
                <div className={isLoaderVisible ? css.hidden : css.default}>
                    <Component {...props} showLoader={changeLoaderVisibility} />
                </div>
            </>
        );
    };

export default withLoader;
