export interface IConvertedItem {
    name: string;
    url: string;
}
//  Converts ["/url/:id/page"] to "/url/d2345f234/page" using attrs from paramsArr
const urlConvertor = (pathArr: string[], params: Record<string, string>) => {
    if (!pathArr || !pathArr.length) {
        return [];
    }

    let url: string;
    let name: string;
    let convertedItem: IConvertedItem;
    const convertedArr: IConvertedItem[] = [];
    const keysArr = [...Object.keys(params)];
    const valuesArr = [...Object.values(params)];

    pathArr.forEach((path: string) => {
        url = path;

        keysArr.forEach((_item, index) => {
            url = url.replace(":" + keysArr[index], valuesArr[index]);
        });
        valuesArr.forEach((item) => {
            if (url.includes(item)) {
                name = item;
            }
        });

        convertedItem = {
            name: name,
            url: url,
        };

        convertedArr.push(convertedItem);
    });

    return convertedArr;
};

export default urlConvertor;
