import { getErrorMessage } from "api/defaults";
import {
    clearKeyVaultParametersExceptDefaultCompany,
    resetDooapUrl,
} from "api/environments/tenants/tenant-erp-configurations-api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Message, useToaster } from "rsuite";
import constants from "utils/constants";

interface IComponentProps {
    envMoniker: string;
    tenantId: string;
}

const ResetD365Parameters: React.FC<IComponentProps> = ({
    envMoniker,
    tenantId,
}: IComponentProps) => {
    const { t } = useTranslation();
    const toaster = useToaster();
    const [disabledButtons, setDisabledButtons] = useState({
        resetParameters: false,
        resetDooapUrl: false,
    });

    const handleResetParameters = () => {
        setDisabledButtons({ ...disabledButtons, resetParameters: true });

        clearKeyVaultParametersExceptDefaultCompany(envMoniker, tenantId).then(
            ([result, status]) => {
                if (status === constants.statusCode.NoContent) {
                    toaster.push(
                        <Message type="success" showIcon>
                            {t(
                                "tenantConfig.d365InformationPage.resetParameters.successMessage"
                            )}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                } else {
                    toaster.push(
                        <Message type="error" showIcon>
                            {getErrorMessage(String(result), status)}
                        </Message>,
                        {
                            duration: constants.alertDurationMsec,
                        }
                    );
                }
                setDisabledButtons({
                    ...disabledButtons,
                    resetParameters: false,
                });
            }
        );
    };

    const handleResetDooapUrl = () => {
        setDisabledButtons({ ...disabledButtons, resetDooapUrl: true });

        resetDooapUrl(envMoniker, tenantId).then(([result, status]) => {
            if (status === constants.statusCode.NoContent) {
                toaster.push(
                    <Message type="success" showIcon>
                        {t(
                            "tenantConfig.d365InformationPage.resetDooapUrl.successMessage"
                        )}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            } else {
                toaster.push(
                    <Message type="error" showIcon>
                        {getErrorMessage(String(result), status)}
                    </Message>,
                    {
                        duration: constants.alertDurationMsec,
                    }
                );
            }
            setDisabledButtons({ ...disabledButtons, resetDooapUrl: false });
        });
    };

    return (
        <div className={"general-configurations__dooap-refresh"}>
            <p className={"general-configurations__label"}>
                {t("tenantConfig.d365InformationPage.resetParametersLabel")}
            </p>
            <div>
                <Button
                    className={"general-configurations__button"}
                    onClick={handleResetParameters}
                    disabled={disabledButtons.resetParameters}
                >
                    {t(
                        "tenantConfig.d365InformationPage.resetParameters.buttonTitle"
                    )}
                </Button>
                <Form.HelpText
                    className={"general-configurations__help"}
                    tooltip
                >
                    {t(
                        "tenantConfig.d365InformationPage.resetParameters.tooltip"
                    )}
                </Form.HelpText>
            </div>
            <div>
                <Button
                    className={"general-configurations__button"}
                    onClick={handleResetDooapUrl}
                    disabled={disabledButtons.resetDooapUrl}
                >
                    {t(
                        "tenantConfig.d365InformationPage.resetDooapUrl.buttonTitle"
                    )}
                </Button>
                <Form.HelpText
                    className={"general-configurations__help"}
                    tooltip
                >
                    {t(
                        "tenantConfig.d365InformationPage.resetDooapUrl.tooltip"
                    )}
                </Form.HelpText>
            </div>
        </div>
    );
};

export default ResetD365Parameters;
