import React, { Dispatch, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Uploader, Popover, Button } from "rsuite";
import cssLogo from "./LogoCustomization.module.css";
import { template } from "lodash";
import parse from "html-react-parser";
import constants from "utils/constants";
import useRefreshPage from "hooks/useRefreshPage";
import cn from "classnames";
import { FileType } from "rsuite/esm/Uploader";
import ImportStorageIcon from "@rsuite/icons/ImportStorage";

interface ComponentProps {
    envMoniker: string;
    tenantName: string;
    currentlogoUrl: string;
    isTenantLogoValid: boolean;
    logoName: string;
    isPageLoading: boolean;
    setIsTenantLogoValid: Dispatch<React.SetStateAction<boolean>>;
    setIsLogoChanged: (isLogoChanged: boolean) => void;
    setIsUnsavedChangeAvailable: (isUnsavedChangeAvailable: boolean) => void;
    setLogoFileToUpdate: (file: number[] | null) => void;
    setLogoName: (fileName: string) => void;
}

const LogoCustomization: React.FC<ComponentProps> = ({
    envMoniker,
    tenantName,
    currentlogoUrl,
    isTenantLogoValid,
    logoName,
    isPageLoading,
    setIsTenantLogoValid,
    setIsLogoChanged,
    setIsUnsavedChangeAvailable,
    setLogoFileToUpdate,
    setLogoName,
}: ComponentProps) => {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [logoFileUrl, setLogoFileUrl] = useState<string>(currentlogoUrl);
    const [logoFile, setLogoFile] = useState<string>("");
    const [tenantLogoName, setTenantLogoName] = useState<string>("");

    function previewFile(file: any, callback: any) {
        const reader = new FileReader();
        reader.onloadend = () => {
            callback(reader.result);
        };
        reader.readAsDataURL(file);
    }

    const validateImage = (file: FileType) => {
        const fileFormat = file.name?.split(".").pop();
        if (
            fileFormat !== constants.imageFormats.png &&
            fileFormat !== constants.imageFormats.jpg
        ) {
            setIsTenantLogoValid(false);
        }
    };

    const convertImageToBytes = async (blobFile: File) => {
        const blobArray = new Uint8Array(await blobFile.arrayBuffer());
        setLogoFileToUpdate(Array.from(blobArray));
    };

    const handleImageChange = async (fileList: FileType[]) => {
        setIsUploading(true);
        setIsUnsavedChangeAvailable(true);
        setIsTenantLogoValid(true);
        const currentFile = fileList[fileList.length - 1];
        previewFile(currentFile.blobFile, (value: any) => {
            setLogoFile(value);
        });
        setLogoName(tenantLogoName + "." + currentFile.name?.split(".").pop());
        validateImage(currentFile);
        setIsLogoChanged(true);
        if (currentFile.blobFile) {
            convertImageToBytes(currentFile.blobFile);
        }
        setIsUploading(false);
    };

    const setLogoInfo = (logoUrl: string) => {
        if (logoUrl) {
            setLogoFileUrl(logoUrl);
            setLogoName(logoUrl.split("/").pop() || "");
        }
    };

    useEffect(() => {
        setLogoInfo(currentlogoUrl);
    }, [currentlogoUrl]);

    useEffect(() => {
        if (tenantName && envMoniker) {
            setTenantLogoName(
                tenantName
                    .replace(constants.fileNameInvalidCharsRegex, "")
                    .toLowerCase() +
                    "_" +
                    envMoniker
            );
        }
    }, [tenantName, envMoniker]);

    useRefreshPage(() => {
        setLogoInfo(currentlogoUrl);
        setLogoFile("");
    });

    return (
        <>
            <h2 className={"general-configurations__page-subheader"}>
                {t("tenantConfig.featureConfigurations.logoSection.title")}
            </h2>
            <h3
                className={cn(
                    "general-configurations__header-description",
                    cssLogo.additionalInformation
                )}
            >
                {parse(
                    template(
                        t(
                            "tenantConfig.featureConfigurations.logoSection.additionalInformation"
                        )
                    )({
                        tenantLogoName: tenantLogoName,
                    })
                )}
            </h3>
            {!isPageLoading && (
                <Uploader
                    fileListVisible={false}
                    listType="picture-text"
                    draggable
                    autoUpload={false}
                    className={cssLogo.logoSection}
                    onChange={handleImageChange}
                    action=""
                >
                    <Button>
                        {isUploading && <Loader backdrop center />}
                        <div className={cssLogo.logoImage}>
                            <img src={logoFile ? logoFile : logoFileUrl} />
                            <span>{logoName}</span>
                        </div>
                        <ImportStorageIcon />
                    </Button>
                </Uploader>
            )}
            <div className="general-configurations__popover-container">
                {!isTenantLogoValid && (
                    <Popover
                        className={"general-configurations__popover"}
                        visible
                    >
                        <span>
                            {t(
                                "tenantConfig.featureConfigurations.logoSection.validationMessage"
                            )}
                        </span>
                    </Popover>
                )}
            </div>
        </>
    );
};

export default LogoCustomization;
