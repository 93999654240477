import React, { FC } from "react";
import css from "./withScroll.module.css";

interface IComponentProps {
    [key: string]: any;
}
const withScroll =
    (Component: FC<any>): FC<IComponentProps> =>
    // eslint-disable-next-line react/display-name
    ({ ...props }: IComponentProps) => {
        return (
            <>
                <div className={css.scrollableContainer}>
                    <Component {...props} />
                </div>
            </>
        );
    };

export default withScroll;
